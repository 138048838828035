import React, { FC, forwardRef, useState, useEffect } from 'react';
import { Card, Form, Grid, Icon } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';

import {
    ProgramFormStepOneType,
    ProgramStepOneFormDataType,
} from '../../../../../types/program.types';
import { programStepOneFormDataSchema } from '../../../../../schemas/program.schemas';
import { formatPhoneNumber } from '../../../../common/CommonUI/atoms/ATMInput/ATMInput.component';
import useCustomResolver from '../../../../../hooks/useCustomResolver';
import Language from '../../../../../libraries/language';
import styles from '../../Private.module.scss';
import Lang from '../../../../../libraries/language';
import { validateAlphabets } from 'src/utils/common.utils';
import { useAuthenticationState } from 'src/contexts/authentication.context';

const CompanyInformation: FC<ProgramFormStepOneType> = forwardRef(
    (
        {
            formRef,
            onSubmitNextHandler,
            formValues,
            zipCodeOptions,
            loadingZipCodes,
        },
        _
    ) => {
        const [, setPhoneNumberValue] = useState<string>('');
        const [zipCodeSearchQuery, setZipCodeSearchQuery] =
            useState<string>('');
        const { authenticatedUserEmail } = useAuthenticationState();

        const {
            handleSubmit,
            control,
            formState: { errors },
            setValue,
            getValues
        } = useForm<ProgramStepOneFormDataType>({
            resolver: useCustomResolver(programStepOneFormDataSchema),
            defaultValues: {
                ...formValues,
                phoneNumber: formatPhoneNumber(
                    formValues.phoneNumber,
                    '',
                    false
                ),
            },
        });

        useEffect(() => {
            if (!getValues().email) {
                setValue('email', authenticatedUserEmail);
            }
        }, []);

        const onSubmitFormHandler = (data: ProgramStepOneFormDataType) => {
            onSubmitNextHandler(data);
        };

        useEffect(() => {
            formRef.current = handleSubmit(onSubmitFormHandler);
        }, [formRef]);

        const validateAlphabetsWithSomeSpecialChars = (val) => {
            if (!val) return val;
            const pattern = /^[a-zA-Z\s._-]+$/;
            return pattern.test(val)
                ? val
                : validateAlphabetsWithSomeSpecialChars(
                      val.substring(0, val.length - 1)
                  );
        };

        return (
            <Card fluid className={styles.sectionCard}>
                <Card.Content className={styles.sectionFormContent}>
                    <h2>{Language.TTL_PF_CI}</h2>

                    <Form className={styles.privateProgramForm}>
                        <Grid>
                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column computer={6} mobile={16}>
                                    <Form.Field
                                        required
                                        {...(errors.legalBusinessName && {
                                            error: errors.legalBusinessName,
                                        })}
                                    >
                                        <label
                                            htmlFor="legalBusinessName"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_LBN}
                                        </label>
                                        <Controller
                                            name="legalBusinessName"
                                            control={control}
                                            render={({ field }) => {
                                                field.value =
                                                    validateAlphabetsWithSomeSpecialChars(
                                                        field.value
                                                    );
                                                return (
                                                    <Form.Input
                                                        {...field}
                                                        maxLength="300"
                                                        {...(errors.legalBusinessName && {
                                                            error: errors.legalBusinessName,
                                                        })}
                                                        type="text"
                                                        placeholder={
                                                            Language.LBL_PLC_ENTER
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.contactName && {
                                            error: errors.contactName,
                                        })}
                                    >
                                        <label
                                            htmlFor="contactName"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_CONTACT_NAME}
                                        </label>
                                        <Controller
                                            name="contactName"
                                            control={control}
                                            render={({ field }) => {
                                                field.value = validateAlphabets(
                                                    field.value
                                                );

                                                return (
                                                    <Form.Input
                                                        {...field}
                                                        maxLength="100"
                                                        {...(errors.contactName && {
                                                            error: errors.contactName,
                                                        })}
                                                        type="text"
                                                        placeholder={
                                                            Language.LBL_PLC_ENTER
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.email && {
                                            error: errors.email,
                                        })}
                                    >
                                        <label
                                            htmlFor="email"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_EMAIL_ADDRESS}
                                        </label>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Input
                                                    className={styles['readOnly']}
                                                    {...field}
                                                    {...(errors.email && {
                                                        error: errors.email,
                                                    })}
                                                    type="email"
                                                    placeholder={
                                                        Language.LBL_PLC_EMAIL_ADDRESS
                                                    }
                                                    disabled
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={4} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.phoneNumber && {
                                            error: errors.phoneNumber,
                                        })}
                                    >
                                        <label
                                            htmlFor="phoneNumber"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_PHONE_NUMBER}
                                        </label>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Input
                                                    {...field}
                                                    {...(errors.phoneNumber && {
                                                        error: errors.phoneNumber,
                                                    })}
                                                    onChange={(_, data) => {
                                                        setPhoneNumberValue(
                                                            (prevState) => {
                                                                const result =
                                                                    formatPhoneNumber(
                                                                        data.value ??
                                                                            '',
                                                                        prevState ??
                                                                            '',
                                                                        false
                                                                    );

                                                                field.onChange(
                                                                    result
                                                                );

                                                                return result;
                                                            }
                                                        );
                                                    }}
                                                    placeholder={
                                                        Language.LBL_PLC_PHONE_NUMBER
                                                    }
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column>
                                    <Form.Field
                                        required
                                        {...(errors.address && {
                                            error: errors.address,
                                        })}
                                    >
                                        <label
                                            htmlFor="address"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_ADDRESS}
                                        </label>
                                        <Controller
                                            name="address"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.TextArea
                                                    {...field}
                                                    {...(errors.address && {
                                                        error: errors.address,
                                                    })}
                                                    placeholder={
                                                        Language.LBL_PLC_ENTER
                                                    }
                                                    maxLength="100"
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.zipCode && {
                                            error: errors.zipCode,
                                        })}
                                    >
                                        <label
                                            htmlFor="zipCode"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_ZIP_CODE}
                                        </label>
                                        <Controller
                                            name="zipCode"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Form.Select
                                                        {...field}
                                                        {...(errors.zipCode && {
                                                            error: errors.zipCode,
                                                        })}
                                                        className={
                                                            styles.privateZipCodeField
                                                        }
                                                        search
                                                        placeholder={
                                                            Language.LBL_PLC_ENTER_ZIP_TO_SEARCH
                                                        }
                                                        onSearchChange={(
                                                            _,
                                                            data
                                                        ) => {
                                                            setZipCodeSearchQuery(
                                                                data.searchQuery
                                                            );
                                                        }}
                                                        noResultsMessage={
                                                            zipCodeSearchQuery
                                                                ? Lang.MSG_NO_RESULTS_FOUND
                                                                : ''
                                                        }
                                                        loading={
                                                            loadingZipCodes
                                                        }
                                                        options={
                                                            zipCodeSearchQuery ||
                                                            field.value
                                                                ? zipCodeOptions
                                                                : []
                                                        }
                                                        onChange={(_, data) => {
                                                            field.onChange(
                                                                data.value
                                                            );

                                                            const selected =
                                                                data.options &&
                                                                data.options.find(
                                                                    (zip) =>
                                                                        zip.value &&
                                                                        zip.value ===
                                                                            data.value
                                                                );

                                                            setValue(
                                                                'city',
                                                                selected
                                                                    ?.correspondingValues
                                                                    ?.city as string
                                                            );
                                                            setValue(
                                                                'state',
                                                                selected
                                                                    ?.correspondingValues
                                                                    ?.state as string
                                                            );
                                                        }}
                                                        value={
                                                            field.value || ''
                                                        }
                                                    />
                                                    <small
                                                        className={
                                                            styles.privateFormHelper
                                                        }
                                                    >
                                                        <Icon name="info circle" />
                                                        {
                                                            Language.LBL_ZIP_CODE_HELPER
                                                        }
                                                    </small>
                                                </>
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field required>
                                        <label
                                            htmlFor="city"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_CITY}
                                        </label>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Input
                                                    {...field}
                                                    className={
                                                        styles.privateFieldOpacity
                                                    }
                                                    type="text"
                                                    disabled
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={4} mobile={8}>
                                    <Form.Field required>
                                        <label
                                            htmlFor="state"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_STATE}
                                        </label>
                                        <Controller
                                            name="state"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Input
                                                    {...field}
                                                    className={
                                                        styles.privateFieldOpacity
                                                    }
                                                    type="text"
                                                    disabled
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Card.Content>
            </Card>
        );
    }
);

export default CompanyInformation;
