import React, { FC } from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { ATMGrid } from 'src/components/common/CommonUI/atoms/ATMGrid/ATMGrid.component';
import { ATMHeader } from 'src/components/common/CommonUI/atoms/ATMHeader/ATMHeader.component';
import { CreatePDFPropsType } from 'src/types/editApplication.types';
import styles from './ProgramPDF.module.scss';
import moment from 'moment';

const ProgramPDF: FC<CreatePDFPropsType> = ({
    application,
    isEdit = false,
}) => {
    const renderRNGTrucksCount = () => {
        if (isEdit && application.numberOfRngTrucks) {
            return (
                <ATMGrid.Column computer={8}>
                    <Form.Field>
                        <div className={styles.bottomBorderOnly}>
                            {application.numberOfRngTrucks}
                        </div>
                        <label className={styles.marginTop3px}>
                            Natural Gas (RNG)
                        </label>
                    </Form.Field>
                </ATMGrid.Column>
            );
        } else if (!isEdit) {
            return (
                <ATMGrid.Column computer={8} id="rngTrucks">
                    <Form.Field>
                        <div className={styles.bottomBorderOnly}>
                            {application.numberOfRngTrucks}
                        </div>
                        <label className={styles.marginTop3px}>
                            Natural Gas (RNG)
                        </label>
                    </Form.Field>
                </ATMGrid.Column>
            );
        } else {
            return null;
        }
    };

    const renderHydrogenTrucksCount = () => {
        if (isEdit && application.numberOfHydrogenTrucks) {
            return (
                <ATMGrid.Column computer={8}>
                    <Form.Field>
                        <div className={styles.bottomBorderOnly}>
                            {application.numberOfHydrogenTrucks}
                        </div>
                        <label className={styles.marginTop3px}>Hydrogen</label>
                    </Form.Field>
                </ATMGrid.Column>
            );
        } else if (!isEdit) {
            return (
                <ATMGrid.Column computer={8} id="hydrogenTrucks">
                    <Form.Field>
                        <div className={styles.bottomBorderOnly}>
                            {application.numberOfHydrogenTrucks}
                        </div>
                        <label className={styles.marginTop3px}>Hydrogen</label>
                    </Form.Field>
                </ATMGrid.Column>
            );
        } else {
            return null;
        }
    };
    return (
        <div>
            <ATMHeader
                as="h3"
                className={styles.headerColor + ' ' + styles.paddingTop}
            >
                Company Information
            </ATMHeader>
            <Form size="tiny">
                <ATMGrid>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={9}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.businessName}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Legal Business Name
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={6}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.contactPersonName}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Name
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={6}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.contactPersonEmail}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Work Email
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={4}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.phoneNumber}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Phone Number
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={6}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.address}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Address
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={5}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.city}
                                </div>
                                <label className={styles.marginTop3px}>
                                    City
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={2}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.state}
                                </div>
                                <label className={styles.marginTop3px}>
                                    State
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={3}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.zip}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Zip
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column className={styles.lineHeight}>
                            <span className={styles.fontSize10}>
                                This SoCalGas® LCFS Fuel Card Incentive Program
                                Application and Agreement (“Agreement”) is
                                hereby entered into between Southern California
                                Gas Company (“SoCalGas”) and the entity
                                executing this Agreement (“Applicant”). Subject
                                to the terms and conditions of this Agreement
                                and the terms, conditions, and rules (“Program
                                Rules”) of the SoCalGas LCFS Fuel Card Incentive
                                Program (“Program”), SoCalGas agrees to provide
                                (1) Pre-funded SoCalGas LCFS Fuel Card to be
                                used at SoCalGas public-access utility-owned
                                natural gas and/or Hydrogen stations (SCG
                                stations) for every new purchased and delivered
                                “near zero emission”<sup>1</sup> Class 8<sup>2</sup> heavy-duty
                                renewable natural gas truck (RNG truck) and/or
                                new Class 8 heavy-duty “zero-emission” fuel cell
                                electric hydrogen truck (Hydrogen truck) to
                                operate within the SoCalGas service territory.
                            </span>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>

            <ATMHeader as="h3" className={styles.headerColor}>
                Class 8 Trucks Planned Purchase Information
            </ATMHeader>
            <Form size="tiny">
                <ATMGrid>
                    <ATMGrid.Row
                        className={styles.rowPadding + ' sempraSmallMarginTop'}
                    >
                        <ATMGrid.Column computer={8}>
                            <ATMHeader as="h5">
                                Number of Trucks to be Purchased:
                            </ATMHeader>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={8}>
                            <ATMHeader as="h5">Time frame:</ATMHeader>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        {renderRNGTrucksCount()}
                        {renderHydrogenTrucksCount()}
                        <ATMGrid.Column computer={4}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {moment(
                                        application.estimatedDateOfPurchase
                                    ).format('MM-DD-YYYY')}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Estimated Date of Purchase
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={4}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {moment(
                                        application.estimatedDateOfDelivery
                                    ).format('MM-DD-YYYY')}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Estimated Date of Delivery
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={16}>
                            <Form.Field className={styles['displayFlex']}>
                                <label>
                                    Number of Trucks Currently in Fleet:
                                </label>
                                <Checkbox
                                    radio
                                    label="9 or Less"
                                    name="checkboxRadioGroup"
                                    value="9"
                                    className={
                                        styles['marginLeft'] +
                                        ' ' +
                                        styles.labelBlack
                                    }
                                    checked={
                                        application.currentNumberOfTrucksInFleet ==
                                        9
                                    }
                                />
                                <Checkbox
                                    radio
                                    label=" 10 or More"
                                    name="checkboxRadioGroup"
                                    value="10"
                                    className={
                                        styles['marginLeft'] +
                                        ' ' +
                                        styles['labelBlack']
                                    }
                                    checked={
                                        application.currentNumberOfTrucksInFleet ==
                                        10
                                    }
                                />
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>

            <br />
            <ATMHeader
                as="h3"
                className={styles.headerColor + ' ' + styles.marginTop10px}
            >
                Program Rules
            </ATMHeader>
            <Form size="tiny">
                <ATMGrid className={styles.fontSize10}>
                    <ATMGrid.Row
                        className={styles.rowPadding + ' ' + styles.lineHeight}
                    >
                        <ATMGrid.Column
                            computer={8}
                            className={styles.paddingLeftRight0px}
                        >
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>1.</div>
                                <span className={styles.list}>
                                The SoCalGas Low Carbon Fuel Standard (LCFS) Incentive Program’s period 
                                of accepting applications (Solicitation Period) will end 90 days from the 
                                Program Start Date Monday, September 9th, 2024.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>2.</div>
                                <span className={styles.list}>
                                To participate in the SoCalGas LCFS Fuel Card Incentive Program (Program), 
                                applicants MUST purchase a New Class 8 heavy-duty RNG/CNG Truck after the 
                                Program Launch Date and no more than 30 days after the Solicitation Period ends Sunday, 
                                December 8th, 2024.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>3.</div>
                                <span className={styles.list}>
                                    Customer must have a physical location
                                    located within the SoCalGas service
                                    territory. (Confirmed by Zip Code)
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>4.</div>
                                <span className={styles.list}>
                                    Applications received prior to the end of the Solicitation Period 
                                    will be evaluated for qualification as set forth within these 
                                    Program Rules, then either awarded (Funding Queue) or placed 
                                    on the waiting list based on available funds.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>5.</div>
                                <span className={styles.list}>
                                    Applicants in the Funding Queue will be notified within 10 business 
                                    days of the end of the Solicitation Period. Applicants placed on the 
                                    waiting list will be notified within thirty (30) days after the end of 
                                    the Solicitation Period.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>6.</div>
                                <span className={styles.list}>
                                    During the Solicitation Period, changes to applications will be 
                                    limited to decreases in the number of SCG LCFS Fuel Cards applied 
                                    for and/or application cancellations only.
                                </span>
                            </div>
                        </ATMGrid.Column>
                        <ATMGrid.Column
                            computer={8}
                            className={styles.paddingLeft0px}
                        >
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>7.</div>
                                <span className={styles.list}>
                                    Applicants must provide proof of purchase after Program Launch Date 
                                    AND proof of Truck delivery before SoCalGas will provide an SCG LCFS Fuel Card.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>8.</div>
                                <span className={styles.list}>
                                    Cards will be provided on a first-come, first-served basis per the 
                                    priorities set forth in these rules  and until accumulated inventory 
                                    funding is exhausted.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>9.</div>
                                <span className={styles.list}>
                                    Applicants are allowed to use the SCG LCFS Fuel Card only at 
                                    SoCalGas Heavy-Duty Public-Access Stations (SCG stations).
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>10.</div>
                                <span className={styles.list}>
                                    SoCalGas reserves the right not to renew the assigned 
                                    SCG LCFS Fuel Card for any reason SoCalGas deems necessary, 
                                    including lack of use and/or prohibited third-party sell or 
                                    card reassignment. Any remaining funds not used at that point will 
                                    go back to the Program for use by another qualified applicant.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>11.</div>
                                <span className={styles.list}>
                                    If an SCG LCFS Fuel Card is lost or stolen, the Applicant must 
                                    contact their SoCalGas  representative immediately or email 
                                    <a
                                        href="mailto:infolcfsip@socalgas.com"
                                    >
                                        {`infolcfsip@socalgas.com`}
                                    </a>.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>12.</div>
                                <span className={styles.list}>
                                    SoCalGas will not be responsible for refunding any prepaid 
                                    funds used on cards prior to the reported-lost-or-stolen date.
                                </span>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>13.</div>
                                <span className={styles.list}>
                                    This Program may be modified or terminated at any time without prior notification.
                                </span>
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column>
                            <div>
                                <sup>1 </sup>
                                Near zero emission Class 8 heavy-duty trucks qualifying under this 
                                program are defined as those trucks that meet the lowest tier of the 
                                CARB Optional Low NOX standard (0.02 g/bhp-hr NOX). The lowest tier of 
                                the CARB Optional Low NOX standard reduces NOX by 90% compared to 
                                current heavy-duty truck NOX emission standards.
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column>
                            <div>
                                <sup>2 </sup>
                                Class 8 vehicles have a gross vehicle weight
                                rating of 33,001 pounds and greater
                                <a
                                    href="https://www.energy.ca.gov/data-reports/energy-almanac/zero-emission-vehicle-and-infrastructure-statistics/
                            medium-and-heavy#:~:text=Heavy%2DDuty%20Weight%20Class,of%2033%2C001%20pounds%20and%20greater"
                                >
                                    {` (https://www.energy.ca.gov/data-reports/energy-almanac/zero-emission-vehicle-and-infrastructure-statistics/
                                medium-and-heavy#:~:text=Heavy%2DDuty%20Weight%20Class,of%2033%2C001%20pounds%20and%20greater)`}
                                </a>
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>

            <div className={styles.dummyDiv}></div>
            <br />
            <br />
            <br />
            <br />
            <br />
            {/**Page 2*/}
            <ATMHeader as="h3" className={styles.headerColor}>
                Terms and Conditions
            </ATMHeader>
            <Form size="tiny">
                <ATMGrid className={styles.fontSize10}>
                    <ATMGrid.Row
                        className={styles.rowPadding + ' ' + styles.lineHeight}
                    >
                        <ATMGrid.Column
                            computer={8}
                            className={styles.paddingLeftRight0px}
                        >
                            <div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.bullets}>1.</div>
                                    <div className={styles.list}>
                                        <div
                                            className={
                                                styles.headerColor +
                                                ' ' +
                                                styles.bold
                                            }
                                        >
                                            Customer Representations And
                                            Additional Covenants
                                        </div>
                                        <span>
                                            Customer represents that all the
                                            information (whether communicated in
                                            writing, electronically, or orally)
                                            that Customer has provided to
                                            SoCalGas in connection with this
                                            Program, including information
                                            provided by Customer as part of it
                                            applying to participate in the
                                            Program, is true and correct as of
                                            the date of this Agreement. Without
                                            limiting the generality of the
                                            foregoing, Customer represents and
                                            covenants as follows: (a) Customer’s
                                            principal place of business is
                                            located in SoCalGas’ service
                                            territory, (b) as part of its
                                            business, Customer regularly
                                            operates Class 8 vehicles, (c)
                                            Customer has been provided with a
                                            copy of the Program Rules, and has
                                            read, understands and agrees to
                                            comply with each of the terms,
                                            conditions and restrictions set
                                            forth therein; (d) at least 50% of
                                            the Truck’s operational mileage
                                            shall be within the State of
                                            California; (e) Customer shall
                                            operate the Truck solely in
                                            connection with the operation of its
                                            business; (f) Customer shall operate
                                            the Truck in accordance with all
                                            applicable laws; (g) Customer has
                                            been provided with a copy of the
                                            SoCalGas LCFS Fuel Card terms and
                                            conditions for use of the Fuel Card,
                                            and has read, understands, and
                                            agrees to comply with all such terms
                                            and conditions.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.bullets}>2.</div>
                                    <div className={styles.list}>
                                        <div
                                            className={
                                                styles.headerColor +
                                                ' ' +
                                                styles.bold
                                            }
                                        >
                                            Breach of This Agreement
                                        </div>
                                        <span>
                                            In the event Customer breaches or is
                                            in default of any of its obligations
                                            under this Agreement, or does not
                                            fully comply with the Program Rules,
                                            or if any information Customer
                                            provides to SoCalGas is false or
                                            misleading, in each case as
                                            determined by SoCalGas in its sole
                                            discretion, SoCalGas may, without
                                            prior notice or demand to Customer,
                                            immediately terminate this Agreement
                                            without prejudice to any of
                                            SoCalGas’ rights or other remedies
                                            available under this Agreement or
                                            any law. SoCalGas will be entitled
                                            to recover from Customer all
                                            reasonable costs, expenses, and
                                            attorneys’ fees (including fees and
                                            disbursements of in-house and
                                            outside counsel) incurred by
                                            SoCalGas in enforcing the terms of
                                            this Agreement.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.bullets}>3.</div>
                                    <div className={styles.list}>
                                        <div
                                            className={
                                                styles.headerColor +
                                                ' ' +
                                                styles.bold
                                            }
                                        >
                                            Program Rules
                                        </div>
                                        <span>
                                            This Agreement is at all times
                                            subject to the Program Rules, as may
                                            be modified by SoCalGas from time to
                                            time in its sole discretion. If the
                                            terms and conditions of this
                                            Agreement conflict with the Program
                                            Rules, the Program Rules shall
                                            govern (only to the extent of such
                                            conflict).
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.bullets}>4.</div>
                                    <div className={styles.list}>
                                        <div
                                            className={
                                                styles.headerColor +
                                                ' ' +
                                                styles.bold
                                            }
                                        >
                                            Term
                                        </div>
                                        <span>
                                            This Agreement shall be effective
                                            upon confirmation by SoCalGas that
                                            Customer’s application has been
                                            accepted for participation in the
                                            Program and, unless earlier
                                            terminated, will continue until each
                                            party’s respective obligations are
                                            fully performed and discharged.
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.displayFlex}>
                                    <div className={styles.bullets}>5.</div>
                                    <div className={styles.list}>
                                        <div
                                            className={
                                                styles.headerColor +
                                                ' ' +
                                                styles.bold
                                            }
                                        >
                                            Breach of Agreement
                                        </div>
                                        <span>
                                            In the event Customer breaches or is
                                            in default of any of its obligations
                                            under this Agreement, or does not
                                            fully comply with the Program Rules,
                                            or if any information Customer
                                            provides to SoCalGas is false or
                                            misleading, in each case as
                                            determined by SoCalGas in its sole
                                            discretion, SoCalGas may, without
                                            prior notice or demand to Customer,
                                            immediately terminate this Agreement
                                            without prejudice to any of
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ATMGrid.Column>
                        <ATMGrid.Column
                            computer={8}
                            className={styles.paddingLeft0px}
                        >
                            <div
                                className={
                                    styles.paddingTop +
                                    ' ' +
                                    styles.paddingLet +
                                    ' ' +
                                    styles.lineHeight
                                }
                            >
                                SoCalGas’ rights or other remedies available
                                under this Agreement or any law. SoCalGas will
                                be entitled to recover from Customer all
                                reasonable costs, expenses, and attorneys’ fees
                                (including fees and disbursements of in-house
                                and outside counsel) incurred by SoCalGas in
                                enforcing the terms of this Agreement.
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>6.</div>
                                <div className={styles.list}>
                                    <div
                                        className={
                                            styles.headerColor +
                                            ' ' +
                                            styles.bold
                                        }
                                    >
                                        Indemnity
                                    </div>
                                    <span>
                                        Customer shall be solely responsible for
                                        and Customer shall indemnify, defend and
                                        hold SoCalGas, and its affiliates and
                                        their respective directors, employees,
                                        and representatives (collectively, the
                                        “Indemnitees”) harmless for, from and
                                        against any and all claims, actions,
                                        suits, proceedings (collectively,
                                        “Claims”), and any and all losses,
                                        liabilities, penalties, fines, damages,
                                        costs or expenses including without
                                        limitation, reasonable attorneys’ fees
                                        (including fees and disbursements of
                                        in-house and outside counsel)
                                        (collectively, “Liabilities”) resulting
                                        from: (a) injuries to or death of any
                                        and all individuals arising out of or
                                        connected in any manner with Customer’s
                                        use of the Fuel Card, (b) damage to,
                                        loss, and/or destruction of property
                                        (including the Truck), arising out of or
                                        connected in any manner with Customer’s
                                        use of the Fuel Card, (c) The selection
                                        of a Hydrogen and/ or RNG Class 8 Truck
                                        is the sole responsibility of the
                                        customer. SoCalGas makes no warranty,
                                        whether express or implied, including
                                        the warranty of the merchantability or
                                        fitness for a particular purpose, of the
                                        Hydrogen and/or RNG truck selected by
                                        customer.
                                    </span>
                                </div>
                            </div>

                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>7.</div>
                                <div className={styles.list}>
                                    <div
                                        className={
                                            styles.headerColor +
                                            ' ' +
                                            styles.bold
                                        }
                                    >
                                        Fuel Card
                                    </div>
                                    <span>
                                        If a Fuel Card is lost or stolen,
                                        Customer must contact their SoCalGas
                                        representative immediately. SoCalGas
                                        will unauthorize a lost or stolen card
                                        for use but will not be responsible to
                                        refund any prepaid funds used on cards
                                        prior to the date the lost or stolen
                                        card is reported to SoCalGas
                                        representative. Cards are to be used
                                        only by customer to which card is
                                        issued.
                                    </span>
                                </div>
                            </div>
                            <div className={styles.displayFlex}>
                                <div className={styles.bullets}>8.</div>
                                <div className={styles.list}>
                                    <div
                                        className={
                                            styles.headerColor +
                                            ' ' +
                                            styles.bold
                                        }
                                    >
                                        Miscellaneous
                                    </div>
                                    <span>
                                        The formation, interpretation,
                                        performance, and enforcement of this
                                        Agreement will be governed by the laws
                                        of the State of California, without
                                        reference to principles of conflicts of
                                        laws. In the event of any litigation to
                                        enforce or interpret any terms of this
                                        Agreement, such action will be brought
                                        in a Superior Court of the State of
                                        California located in the County of Los
                                        Angeles (or, if the federal courts have
                                        exclusive jurisdiction over the subject
                                        matter of the dispute, in the U.S.
                                        District Court for the Central District
                                        of California). This Agreement will not
                                        be construed against any party as a
                                        result of the preparation, substitution,
                                        submission, or other event of
                                        negotiation, drafting or execution
                                        hereof. Customer may not transfer or
                                        assign this Agreement or its rights or
                                        obligations under this Agreement without
                                        the prior written consent of SoCalGas.
                                        Waiver by SoCalGas of any default by
                                        Customer will not be construed as a
                                        waiver of any other default. Any
                                        provision of this Agreement that
                                        contemplates or governs performance or
                                        observance subsequent to termination or
                                        expiration of this Agreement will
                                        survive the expiration or termination of
                                        this Agreement.
                                    </span>
                                </div>
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>

            {/* <div className={styles.dummyDiv}></div> */}

            <ATMHeader as="h3" className={styles.headerColor}>
                Customer Signature
            </ATMHeader>

            <Form size="tiny">
                <ATMGrid>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={16}>
                            <div>
                                I agree with and will abide by the terms and
                                conditions listed above.
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.signatoryName}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Name
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div className={styles.bottomBorderOnly}>
                                    {application.title}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Title
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div
                                    className={
                                        styles.bottomBorderOnly +
                                        ' ' +
                                        styles.height20px
                                    }
                                >
                                    {application.applicationEsignatureDate
                                        ? moment(
                                              application.applicationEsignatureDate
                                          ).format('MM-DD-YYYY')
                                        : ''}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Date
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>

            <ATMHeader as="h3">Approved and Agreed to by SoCalGas:</ATMHeader>
            <Form size="tiny">
                <ATMGrid>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div
                                    className={
                                        styles.bottomBorderOnly +
                                        ' ' +
                                        styles.height20px
                                    }
                                >
                                    {/* {" -"} */}
                                </div>
                                <label className={styles.marginTop3px}>
                                    Name
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div
                                    className={
                                        styles.bottomBorderOnly +
                                        ' ' +
                                        styles.height20px
                                    }
                                ></div>
                                <label className={styles.marginTop3px}>
                                    Title
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div
                                    className={
                                        styles.bottomBorderOnly +
                                        ' ' +
                                        styles.height20px
                                    }
                                ></div>
                                <label className={styles.marginTop3px}>
                                    Signature
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={8}>
                            <Form.Field>
                                <div
                                    className={
                                        styles.bottomBorderOnly +
                                        ' ' +
                                        styles.height20px
                                    }
                                ></div>
                                <label className={styles.marginTop3px}>
                                    Date
                                </label>
                            </Form.Field>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                    <ATMGrid.Row className={styles.rowPadding}>
                        <ATMGrid.Column computer={16}>
                            <div>
                                © 2023 Southern California Gas Company.
                                Trademarks are property of their respective
                                owners. All rights reserved. N23D072A 0623
                            </div>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </Form>
        </div>
    );
};

export default ProgramPDF;
