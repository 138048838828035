import { EmailPasswordDataType, SendEmailOnForgotPasswordRBType, SignUpFormDataType, VerificationFormDataType } from '../types/signUp.types';
import { ConfirmPasswordDataType } from '../types/forgotPassword.types';
import { cognitoUserPool } from 'src/utils/awsCognitoConfig';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { serviceInstance } from 'src/libraries/service.library';
const service = serviceInstance();

const BASE_URL = process.env.REACT_APP_EXTERNAL_BASE_URL;

export const forgotPassword = async (
    userData: VerificationFormDataType,
    onSuccessHandler,
    onErrorHandler
) => {
    const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: cognitoUserPool,
    });
    cognitoUser.forgotPassword({
        onSuccess: onSuccessHandler,
        onFailure: onErrorHandler,
    });
};

export const confirmPassword = async (
    userData: ConfirmPasswordDataType,
    onSuccessHandler,
    onErrorHandler
) => {
    const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: cognitoUserPool,
    });
    cognitoUser.confirmPassword(
        userData.verificationCode,
        userData.newPassword,
        {
            onSuccess: onSuccessHandler,
            onFailure: onErrorHandler,
        }
    );
};

export const signIn = async (
    userData: EmailPasswordDataType,
    onSuccessHandler,
    onErrorHandler
) => {
    const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: cognitoUserPool,
    });
    const authenticationDetails = new AuthenticationDetails({
        Username: userData.email,
        Password: userData.password,
    });
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: onSuccessHandler,
        onFailure: onErrorHandler,
    });
};

export const signUp = async (userData: SignUpFormDataType, signUpHandler) => {
    const attributeList: CognitoUserAttribute[] = [
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: userData.firstName,
        }),
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: userData.lastName,
        }),
        new CognitoUserAttribute({ Name: 'email', Value: userData.email }),
    ];

    cognitoUserPool.signUp(
        userData.email,
        userData.password,
        attributeList,
        [],
        signUpHandler
    );
};

export const sendSignUpVerificationLinkOnEmail = async (
    userData: VerificationFormDataType,
    calbackHandler
) => {
    const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: cognitoUserPool,
    });
    cognitoUser.resendConfirmationCode(calbackHandler);
};

export const sendEmailOnForgotPasswordSuceess = async (
    data: SendEmailOnForgotPasswordRBType
) => {
    try {
        const response = await service.post(`${BASE_URL}/clientoperations?forgotPasswordUpdated=true`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};