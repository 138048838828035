import React, { FC, useState, useEffect } from 'react';
import {
    Header,
    Form,
    Button,
    Checkbox,
    Input,
    Message,
    Grid,
} from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import PasswordStrengthAnalyzer from '../../../common/PasswordStrengthAnalyzer/PasswordStrengthAnalyzer.component';
import useCustomResolver from '../../../../hooks/useCustomResolver';
import { unwantedPasswordErrors } from '../../../../constants/password.constants';
import { SignUpFormDataType } from '../../../../types/signUp.types';
import { signUpFormDataSchema } from '../../../../schemas/signUp.schemas';
import Language from '../../../../libraries/language';
import styles from '../Public.module.scss';
import { sendSignUpVerificationLinkOnEmail, signUp } from 'src/services/authentication.services';
import { validateAlphanumerics } from 'src/utils/common.utils';
import Lang from '../../../../libraries/language';

const SignUp: FC = () => {
    const [isVerifyEmailPage, setIsVerifyEmailPage] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<
        SignUpFormDataType | Partial<SignUpFormDataType>
    >({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(30);
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [displaySuccess, setDisplaySuccess] = useState<string>('');
    const [displayError, setDisplayError] = useState<string>('');

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<SignUpFormDataType>({
        resolver: useCustomResolver(signUpFormDataSchema),
    });

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };


    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && isEmailSent) {
            setIsTimerRunning(false);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer, isEmailSent]);

    const switchPageHandler = () => {
        setIsVerifyEmailPage((prev) => !prev);
    };

    const sendVerificationLinkOnEmailHandler = (err, result) => {
        if (err) {
            console.log(err);
            setDisplayError(err.message);
            scrollToTop();

            setTimeout(() => {
                setDisplayError('');
            }, 5000);
            setIsTimerRunning(false);
            setTimer(0);
        }
        if (result) {
            setIsEmailSent(true);
            setDisplaySuccess(Language.MSG_VERIFICATION_SUCCESS);

            setTimeout(() => {
                setDisplaySuccess('');
            }, 5000);
        }
        setIsLoading(false);
    };

    const sendEmailHandler = (action) => {
        setIsTimerRunning(true);
        setIsLoading(true);

        if (action === 'resend') {
            setTimer(30);
            sendSignUpVerificationLinkOnEmail(
                { email: formValues.email as string },
                sendVerificationLinkOnEmailHandler
            );
        } else {
            const formData = {
                firstName: formValues.firstName as string,
                lastName: formValues.lastName as string,
                email: formValues.email as string,
                password: formValues.password as string,
            };
            signUp(formData, signUpHandler);
        }
    };

    const signUpHandler = (err, result) => {
        if (err) {
            console.log(err);
            if (err.message == 'User already exists') {
                setDisplayError(Lang.MSG_USER_ALREADY_EXISTS);
                setIsEmailSent(true);
            } else {
                setDisplayError(err.message);
            }
            scrollToTop();
            setIsTimerRunning(false);
            setTimer(0);
            
            setTimeout(() => {
                setDisplayError('');
            }, 5000);
            setIsLoading(false);
        }
        if (result) {
            setIsLoading(false);
            setIsEmailSent(true);
            setDisplaySuccess(Language.MSG_VERIFICATION_SUCCESS);

            setTimeout(() => {
                setDisplaySuccess('');
            }, 5000);
        }
    };

    const onSubmitFormHandler = async (data: SignUpFormDataType) => {
        setFormValues(data);
        switchPageHandler();
        setIsEmailSent(false);
    };

    return !isVerifyEmailPage ? (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_GET_STARTED}
                </Header>
                <Header as="h5" className={styles.publicSubTitle}>
                    {Language.TTL_APPLICATION}
                </Header>

                <Form onSubmit={handleSubmit(onSubmitFormHandler)}>
                    <Grid>
                        {!_.isEmpty(errors) && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Message negative>
                                        <Message.Header>
                                            {Language.TTL_FORM_ERROR}
                                        </Message.Header>
                                        <Message.List>
                                            {Object.values(errors).map(
                                                (err, idx) => {
                                                    if (
                                                        !unwantedPasswordErrors.includes(
                                                            err as any
                                                        )
                                                    ) {
                                                        return (
                                                            <Message.Item
                                                                key={idx}
                                                            >
                                                                {err as any}
                                                            </Message.Item>
                                                        );
                                                    }
                                                    return null;
                                                }
                                            )}
                                        </Message.List>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        )}

                        {!!displayError && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Message negative>
                                        <Message.Content>
                                            {displayError}
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        )}

                        <Grid.Row columns={2} className={styles.publicFormRow}>
                            <Grid.Column
                                className={styles.publicFormFirstNameColumn}
                            >
                                <Form.Field
                                    required
                                    {...(errors.firstName && { error: true })}
                                >
                                    <label
                                        htmlFor="firstName"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_FIRST_NAME}
                                    </label>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field }) => {
                                            field.value = validateAlphanumerics(
                                                field.value
                                            );
                                            return (
                                                <Input
                                                    {...field}
                                                    placeholder={
                                                        Language.LBL_PLC_FIRST_NAME
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column
                                className={styles.publicFormLastNameColumn}
                            >
                                <Form.Field
                                    required
                                    {...(errors.lastName && { error: true })}
                                >
                                    <label
                                        htmlFor="lastName"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_LAST_NAME}
                                    </label>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field }) => {
                                            field.value = validateAlphanumerics(
                                                field.value
                                            );
                                            return (
                                                <Input
                                                    {...field}
                                                    placeholder={
                                                        Language.LBL_PLC_LAST_NAME
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.email && { error: true })}
                                >
                                    <label
                                        htmlFor="email"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_EMAIL_ADDRESS}
                                    </label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="email"
                                                placeholder={
                                                    Language.LBL_PLC_EMAIL_ADDRESS
                                                }
                                            />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.password && { error: true })}
                                >
                                    <label
                                        htmlFor="password"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_PASSWORD}
                                    </label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Input
                                                    {...field}
                                                    type="password"
                                                    data-testid="password"
                                                />

                                                <PasswordStrengthAnalyzer
                                                    password={field.value}
                                                />
                                            </>
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.confirmPassword && {
                                        error: true,
                                    })}
                                >
                                    <label
                                        htmlFor="confirmPassword"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_CONFIRM_PASSWORD}
                                    </label>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="password"
                                                data-testid="confirmPassword"
                                            />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.acceptTerms && { error: true })}
                                >
                                    <Controller
                                        name="acceptTerms"
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                checked={field.value}
                                                onChange={(_, data) =>
                                                    field.onChange(data.checked)
                                                }
                                                label={
                                                    <label>
                                                        {
                                                            Language
                                                                .LBL_TERMS_AND_CONDITION[0]
                                                        }
                                                        <Link
                                                            to="https://www.socalgas.com/terms-and-conditions"
                                                            target="_blank"
                                                        >
                                                            {
                                                                Language
                                                                    .LBL_TERMS_AND_CONDITION[1]
                                                            }
                                                        </Link>
                                                        {
                                                            Language
                                                                .LBL_TERMS_AND_CONDITION[2]
                                                        }
                                                        <Link
                                                            to="https://www.socalgas.com/privacy-center"
                                                            target="_blank"
                                                        >
                                                            {
                                                                Language
                                                                    .LBL_TERMS_AND_CONDITION[3]
                                                            }
                                                        </Link>
                                                    </label>
                                                }
                                            />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Button
                                    fluid
                                    type="submit"
                                    className={styles.sempraButton}
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    {Language.LBL_BTN_SIGNUP}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <p>
                                    {Language.LBL_SIGNIN_HERE[0]}
                                    <Link to="/">
                                        {Language.LBL_SIGNIN_HERE[1]}
                                    </Link>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Grid.Column>
        </Grid>
    ) : (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_VERIFY_EMAIL}
                </Header>

                {!!displaySuccess && (
                    <Grid.Row>
                        <Grid.Column>
                            <Message positive>
                                <Message.Content>
                                    {displaySuccess}
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                )}

                {!!displayError && (
                    <Grid.Row>
                        <Grid.Column>
                            <Message negative>
                                <Message.Content>
                                    {displayError}
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                )}

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <p>
                            {Language.NT_VERIFY_EMAIL}
                            <span className={styles.publicBold}>
                                {formValues.email}
                            </span>
                        </p>
                        <p>
                            <span className={styles.publicBold}>
                                {Language.NT_VERIFY_EMAIL_NOTE[0]}
                            </span>
                            {Language.NT_VERIFY_EMAIL_NOTE[1]}
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column width={12}>
                        <div className={styles.publicVerificationTimer}>
                            <p className={styles.publicRemoveMarginBottom}>
                                {Language.NT_RESEND_EMAIL}
                            </p>
                            <p>{`00:${timer < 10 ? `0${timer}` : timer}`}</p>
                        </div>

                        {timer === 0 && isEmailSent ? (
                            <Button
                                fluid
                                className={styles.sempraButton}
                                disabled={isTimerRunning || isLoading}
                                loading={isLoading}
                                onClick={() => sendEmailHandler('resend')}
                            >
                                {Language.LBL_BTN_RESEND}
                            </Button>
                        ) : (
                            <Button
                                fluid
                                className={styles.sempraButton}
                                disabled={isEmailSent || isLoading}
                                loading={isLoading}
                                onClick={() => sendEmailHandler('send')}
                            >
                                {Language.LBL_BTN_VERIFY}
                            </Button>
                        )}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        {/* <Button
                            fluid
                            className={styles.sempraOutlineButton}
                            onClick={() => {
                                switchPageHandler();
                                setIsTimerRunning(false);
                                setTimer(0);
                            }}
                        >
                            <Icon name="arrow left" />
                            <span className={styles.publicBold}>
                                {Language.LBL_BTN_BACK_TO_SIGNUP}
                            </span>
                        </Button> */}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <p>
                            {Language.LBL_SIGNIN_AFTER_EMAIL_VERIFICATION[0]}
                            <Link to="/">
                                {
                                    Language
                                        .LBL_SIGNIN_AFTER_EMAIL_VERIFICATION[1]
                                }
                            </Link>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};

export default SignUp;
