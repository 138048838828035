import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IORGDataTableQueryState,
  IORGDataTableOnUpdate,
} from '../ORGDataTable.component';
import styles from './MOLTable-filter.module.scss';
import { ATMGrid } from '../../../atoms/ATMGrid/ATMGrid.component';
import { ATMResponsive } from '../../../atoms/ATMResponsive/ATMResponsive.component';
import { ATMButton } from '../../../atoms/ATMButton/ATMButton.component';
import { TableAction } from '../../../constants/table.constant';

export type IMOLTableFilterSetState = (
  data: Partial<IORGDataTableQueryState>
) => void;

export type IMOLTableFilterSetValue = (name: string, value: any) => void;

type IMOLTableFilterFunction = (props: {
  values: Record<string, any>;
  operators: Record<string, string>;
  errors: Record<string, any>;
  setValue: IMOLTableFilterSetValue;
  setError: (name: string, error: any) => void;
  setOperator: (name: string, op: string) => void;
  state: IORGDataTableQueryState;
  setState: IMOLTableFilterSetState;
}) => React.ReactNode;

export type IMOLTableFilterItemProps = (props: {
  value: any;
  operator: string | undefined;
  error: any;
  setValue: (value: any) => void;
  setOperator: (op: string) => void;
  setError: (error: any) => void;
  state: IORGDataTableQueryState;
  setState: IMOLTableFilterSetState;
}) => React.ReactNode;

export type IMOLTableFilter =
  | IMOLTableFilterFunction
  | Record<string, IMOLTableFilterItemProps>;

export type IMOLTableFilterProps = {
  filterCollapsed?: boolean;
  state: IORGDataTableQueryState;
  errors: Record<string, any>;
  removeCancelApplyinfilters?: boolean;
  filters: IMOLTableFilter;
  handleToggle: () => void;
  handleChange: IORGDataTableOnUpdate;
  handleError: (name: string, error: any) => void;
};

export type ITableFilter = {
  name: string;
  op?: string;
  value: any;
};

export type ITableFilterItem = Omit<ITableFilter, 'name'>;

type IFilterItemProps = {
  name: string;
  value: any;
  operator: string | undefined;
  errors: Record<string, any>;
  state: IORGDataTableQueryState;
  filter: IMOLTableFilterItemProps;
  handleChange: IMOLTableFilterSetValue;
  handleOperator: IMOLTableFilterSetValue;
  handleError: (name: string, error: any) => void;
  setState: IORGDataTableOnUpdate;
};

export const FilterItem: React.FC<IFilterItemProps> = ({
  name,
  value,
  operator,
  errors,
  state,
  filter,
  handleChange,
  handleOperator,
  handleError,
  setState,
}) => {
  // The purpose for this is to make update of value in realtime
  const [cacheValue, setCacheValue] = useState(value);
  const [cacheOperator, setCacheOperator] = useState(operator);

  useEffect(() => {
    setCacheValue((val) => {
      if (value !== val) {
        return value;
      }

      return val;
    });
  }, [value, setCacheValue]);

  return (
    <>
      {filter({
        value: cacheValue,
        // eslint-disable-next-line security/detect-object-injection
        error: errors[name],
        setValue: (val) => {
          setCacheValue(val);

          setImmediate(() => handleChange(name, val));
        },
        operator: cacheOperator,
        setOperator: (val) => {
          setCacheOperator(val);

          setImmediate(() => handleOperator(name, val));
        },
        setError: (e: any) => handleError(name, e),
        state,
        setState: (values) =>
          setState(values, {
            action: TableAction.FILTER,
          }),
      })}
    </>
  );
};

const MOLTableFilter: React.FC<IMOLTableFilterProps> = ({
  state,
  filterCollapsed,
  filters,
  handleToggle,
  handleChange,
  errors,
  handleError,
  removeCancelApplyinfilters = false,
}) => {
  // We will generate filter object based on state filters initial values
  const [filterValues, setFilterValues] = useState<
    Record<string, ITableFilterItem>
  >(
    (state.filters ?? []).reduce((list, { name, ...value }: ITableFilter) => {
      return {
        ...list,
        [name]: value,
      };
    }, {})
  );

  const handleFilterChange = useCallback<IMOLTableFilterSetValue>(
    (name, value) => {
      setFilterValues((values) => {
        // eslint-disable-next-line security/detect-object-injection
        const props = values[name] || {};

        return {
          ...values,
          [name]: {
            ...props,
            value,
          },
        };
      });
    },
    [setFilterValues]
  );

  const handleOperatorChange = useCallback<IMOLTableFilterSetValue>(
    (name, op) => {
      setFilterValues((values) => {
        // eslint-disable-next-line security/detect-object-injection
        const props = values[name];

        return {
          ...values,
          [name]: {
            value: props?.value,
            op,
          },
        };
      });
    },
    [setFilterValues]
  );

  const handleClear = useCallback(() => {
    const filterList =
      typeof filters === 'function'
        ? (state.filters || []).map((v) => v.name)
        : Object.keys(filters || {});

    filterList.forEach((v) => handleError(v, undefined));

    const resetFilters = Object.keys(filterValues).reduce(
      (accumulator, key) => {
        return { ...accumulator, [key]: '' };
      },
      {}
    );

    setFilterValues(resetFilters);

    handleChange(
      {
        ...state,
        filters: (state.filters || []).filter(
          (value) => !filterList.includes(value.name)
        ),
        page: 1, // Redirect to page 1
      },
      {
        action: TableAction.CLEAR,
      }
    );
  }, [
    handleError,
    state,
    filters,
    setFilterValues,
    handleChange,
    handleToggle,
    filterCollapsed,
  ]);

  const handleApply = useCallback(() => {
    if (!filterCollapsed) {
      handleToggle();
    }
    const keys = Object.keys(filterValues);
    const list = Object.entries(filterValues).map(([key, value]) => ({
      name: key,
      ...value,
    }));

    handleChange(
      {
        ...state,
        filters: [
          ...(state.filters || []).filter((v) => !keys.includes(v.name)),
          ...list,
        ].filter(({ value }) => {
          // Filter out empty values
          if (
            (typeof value === 'string' && !value.trim().length) ||
            (Array.isArray(value) && !value.length) ||
            value === null ||
            value === undefined
          ) {
            return false;
          }

          return true;
        }),
        page: 1, // Redirect to page 1
      },
      {
        action: TableAction.FILTER,
      }
    );
  }, [filterValues, state, handleToggle, handleChange, filterCollapsed]);

  const generateFilterColumns = useMemo(() => {
    const filterList = Object.entries(filters).map(
      ([key, filter]: [string, IMOLTableFilterItemProps]) => ({
        name: key,
        filter,
      })
    );

    return (
      <div className="ui form">
        <ATMGrid doubling stackable columns={5}>
          {filterList.map((item) => (
            <ATMGrid.Column key={item.name}>
              <FilterItem
                {...item}
                value={filterValues[item.name]?.value}
                operator={filterValues[item.name]?.op}
                handleChange={handleFilterChange}
                handleOperator={handleOperatorChange}
                errors={errors}
                handleError={handleError}
                state={state}
                setState={handleChange}
              />
            </ATMGrid.Column>
          ))}
        </ATMGrid>
      </div>
    );
  }, [filters, filterValues, errors, handleError]);

  const content = (
    <div className={styles.container}>
      {typeof filters === 'function'
        ? filters({
            values: Object.entries(filterValues).reduce(
              (items, [name, { value }]) => ({
                ...items,
                [name]: value,
              }),
              {}
            ),
            operators: Object.entries(filterValues).reduce(
              (items, [name, { op }]) => ({
                ...items,
                [name]: op,
              }),
              {}
            ),
            errors,
            state,
            setValue: handleFilterChange,
            setOperator: handleOperatorChange,
            setError: handleError,
            setState: (values) =>
              handleChange(values, {
                action: TableAction.FILTER,
              }),
          })
        : generateFilterColumns}
    </div>
  );

  return (
    <>
      <ATMResponsive media="mobile">
        <div>
          <h3 className={styles.mobileFilterHeading}>Filters</h3>
        </div>
        {content}

        {!removeCancelApplyinfilters && (
          <div className={styles.mobileButtonView}>
            <ATMButton secondary onClick={handleClear}>
              Clear
            </ATMButton>
            <ATMButton
              primary
              onClick={handleApply}
              disabled={Object.values(errors).length > 0}
            >
              Apply
            </ATMButton>
          </div>
        )}
      </ATMResponsive>

      <ATMResponsive greaterThan="mobile">
        <div className={styles.wrapper}>
          {content}

          {!removeCancelApplyinfilters && (
            <div className={styles.actions}>
              <ATMButton secondary onClick={handleClear}>
                Clear
              </ATMButton>
              <ATMButton
                primary
                onClick={handleApply}
                disabled={
                  Object.keys(errors).filter((v) =>
                    filters ? Object.keys(filters).includes(v) : true
                  ).length > 0
                }
              >
                Apply
              </ATMButton>
            </div>
          )}
        </div>
      </ATMResponsive>
    </>
  );
};

export default MOLTableFilter;
