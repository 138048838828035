import React, { FC, useState, useEffect } from 'react';
import { Form, List, Icon, Modal } from 'semantic-ui-react';
import Lang from 'src/libraries/language';
import styles from './Application.module.scss';
import { ATMGrid } from 'src/components/common/CommonUI/atoms/ATMGrid/ATMGrid.component';
import { ATMButton } from 'src/components/common/CommonUI/atoms/ATMButton/ATMButton.component';
import { ATMModal } from 'src/components/common/CommonUI/atoms/ATMModal/ATMModal.component';
import { ATMMessage } from 'src/components/common/CommonUI/atoms/ATMMessage/ATMMessage.component';
import { EditApplicationType } from '../../../../../src/types/ViewOrEdit.types';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import { useAuthenticationState } from 'src/contexts/authentication.context';

import { useEditApplication, useModifyAcceptFundingApplication } from '../../../../hooks/useApplicationHooks';
import { EditApplicationDataType } from 'src/types/editApplication.types';
import jsPDF from 'jspdf';
import { getHeaderBase64Image, getSoCalGasBase64Logo } from 'src/utils/common.utils';

const EditApplication: FC<EditApplicationType> = ({
    application,
    closeEditApplicationPopup,
    willAccept,
    acceptHandler,
    renderPDFHandler
}) => {
    const [hydrogenTrucks, setHydrogenTrucks] = useState(
        Number(application.hydrogenTrucks)
    );
    const [rngTrucks, setRngtrucks] = useState(Number(application.rngTrucks));
    const [errorMessage, setErrorMessage] = useState('');
    const notificationDispatch = useNotificationDispatch();
    const { authenticatedUserEmail } = useAuthenticationState();
    const [loading, setLoading] = useState(false);

    const {
        mutate: editApplicationService,
        isError,
        error,
        isSuccess,
    } = useEditApplication();

    const {
        mutate: modifyAcceptApplicationService,
        isError: modifyAcceptError,
        isSuccess: modifyAcceptSuccess,
        error:Error,
    } = useModifyAcceptFundingApplication();

    useEffect(() => {
        if (modifyAcceptSuccess) {
            notificationDispatch({
                type: 'SUCCESS',
                payload: `${Lang.MSG_MODIFY_AND_ACCEPT_APPLICATION[0]}: ${application.id} ${Lang.MSG_MODIFY_AND_ACCEPT_APPLICATION[1]}`,
            });
            closeEditApplicationPopup();
            acceptHandler && acceptHandler()
        }
    }, [modifyAcceptSuccess])
    
    useEffect(() => {
        if (modifyAcceptError || error) {
            setLoading(false);
        }
    }, [modifyAcceptError, error]);
  
    useEffect(() => {
        if (isSuccess) {
            notificationDispatch({
                type: 'SUCCESS',
                payload: Lang.MSG_APP_SAVE_SUCESS.replace(
                    '${appId}',
                    application.id
                ),
            });
            closeEditApplicationPopup();
        }
    }, [isSuccess]);

    const validateNumberField = (val) => {
        if (!val) return val;
        const pattern = /^\d+\.?\d*$/;
        return pattern.test(val) ? Number(val) : Number(val.substring(0, val.length - 1));
    };
    const onChangeHandler = (_, data) => {
        const val = validateNumberField(data.value);
        setHydrogenTrucks(val);
    };
    const onChangeHandlerTwo = (_, data) => {
        const val = validateNumberField(data.value);
        console.log('val', val);
        setRngtrucks(val);
    };

    const handleSaveApplication = async () => {
        if (
            (!hydrogenTrucks && hydrogenTrucks !== 0) ||
            hydrogenTrucks > Number(application.hydrogenTrucks)
        ) {
            return;
        }

        if (
            (!rngTrucks && rngTrucks !== 0) ||
            rngTrucks > Number(application.rngTrucks)
        ) {
            return;
        }
        if (!rngTrucks && !hydrogenTrucks) {
            setErrorMessage(Lang.MSG_RNG_AND_HYDROGEN_CANNOT_EMPTY);
            setTimeout(() => setErrorMessage(''), 3000);
            return;
        }
        const formData = {
            applicationNumber: application.id,
            numberOfRngTrucks: Number(rngTrucks),
            numberOfHydrogenTrucks: Number(hydrogenTrucks),
            updatedBy: authenticatedUserEmail,
        } as EditApplicationDataType;
        setLoading(true);
        renderPDFHandler(formData);
        //2 seconds delay to render pdf Component (ProgramPDF.Component)
        setTimeout(async() => {
          const doc = new jsPDF('p', 'pt', 'a3') as any;
          doc.addImage(getSoCalGasBase64Logo(), 'jpg', 50, 20, 120, 70);
          doc.addImage(getHeaderBase64Image(), 'jpg', 470, 10, 340, 75);
          const html = document.querySelector('#content-id');

          await doc.html(html, {
              callback: function (doc) {
                  //Do nothing
              },
              x: 0, // x position on the PDF document in jsPDF units.
              y: 80,
          });

        let str = doc.output('datauri');
        str = str.replace(
            'data:application/pdf;filename=generated.pdf;base64,',
            ''
        );
        formData.base64PDF = str;
        if (willAccept && acceptHandler) {
            modifyAcceptApplicationService(formData);
        } else {
            editApplicationService(formData);
        }

      }, 2000);
        
    };

    return (
        <Modal
            onClose={() => closeEditApplicationPopup()}
            open={true}
            size="small"
        >
            <ATMModal.Header>
                {willAccept ? Lang.LBL_MODIFY_ACCEPT : Lang.LBL_MODIFY}
            </ATMModal.Header>

            <Modal.Content>
                {modifyAcceptError|| isError || errorMessage ? (
                    <ATMMessage
                        className="sempraTinyPaddingAround sempraNoMarginTop"
                        error
                        content={errorMessage || error?.message||Error?.message}
                    />
                ) : null}
                <Modal.Description>
                    <h4>
                        {Lang.LBL_APP}: {application.id}
                    </h4>

                    <Form>
                        <Form.Group widths="three">
                            {application.hydrogenTrucks>0 ?(<Form.Input
                                required
                                fluid
                                label="Hydrogen Trucks"
                                value={hydrogenTrucks}
                                onChange={onChangeHandler}
                                error={
                                    !hydrogenTrucks && hydrogenTrucks !== 0
                                        ? Lang.MSG_ERROR_REQUIRED
                                        : hydrogenTrucks >
                                            application.hydrogenTrucks
                                            ? Lang.MSG_TRUCKS_QUANTITY_ERROR
                                            : false
                                }
                            />):null}
                            {application.rngTrucks>0 ?( <Form.Input
                                required
                                fluid
                                data-testid="rngInput"
                                label="Natural Gas(RNG)"
                                value={rngTrucks}
                                onChange={onChangeHandlerTwo}
                                error={
                                    (!rngTrucks && rngTrucks !== 0)
                                        ? Lang.MSG_ERROR_REQUIRED
                                        : rngTrucks > application.rngTrucks
                                            ? Lang.MSG_TRUCKS_QUANTITY_ERROR
                                            : false
                                }
                            />):null}
                        </Form.Group>
                    </Form>
                    {willAccept && (
                        <p>
                            By clicking <strong>{"'Save'"}</strong>, you
                            acknowledge to accept the Fuel Card for the updated
                            number of trucks.
                        </p>
                    )}
                </Modal.Description>
            </Modal.Content>

            <ATMModal.Actions>
                <ATMGrid>
                    <ATMGrid.Row>
                        <ATMGrid.Column textAlign="left" computer={11} mobile={16}>
                            <div className={styles['displayFlexAndCenter']}>
                                <span
                                    className={`${styles['marginBottomAuto']} sempraTinyMarginRight`}
                                >
                                    <Icon name="info circle" />
                                </span>
                                <span>
                                    <List bulleted>
                                        <List.Item>
                                            {Lang.LBL_INFO_MSG}{' '}
                                        </List.Item>
                                        <List.Item>
                                            {Lang.LBL_INFO_MSG_SECOND}
                                        </List.Item>
                                    </List>
                                </span>
                            </div>
                        </ATMGrid.Column>

                        <ATMGrid.Column textAlign="right" computer={5} mobile={16}>
                            <ATMButton
                                secondary
                                onClick={() => closeEditApplicationPopup()}
                                size="tiny"
                                className={styles['marginRight']}
                                disabled={loading }
                            >
                                {Lang.TTL_CANCEL}
                            </ATMButton>
                            <ATMButton
                                primary
                                onClick={() => handleSaveApplication()}
                                size="tiny"
                                loading={loading }
                                disabled={loading }
                            >
                                {Lang.LBL_SAVE}
                            </ATMButton>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </ATMModal.Actions>
        </Modal>
    );
};

export default EditApplication;
