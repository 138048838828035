import React, { useEffect } from 'react';

import { ATMToastMessage } from '../CommonUI/atoms/ATMToastMessage/ATMToastMessage.component';
import {
    useNotificationState,
    useNotificationDispatch,
} from 'src/contexts/notification.context';
import styles from './Notification.module.scss';

export const Notification: React.FC = () => {
    const { message, showMessage, messageType, iconValue, listValue } =
        useNotificationState();
    const dispatch = useNotificationDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: 'REMOVE_MESSAGE' });
        }, 5000);
    }, [message]);

    const handleDismiss = () => {
        dispatch({ type: 'REMOVE_MESSAGE' });
    };

    return showMessage ? (
        <ATMToastMessage
            className={styles.toastMessage}
            icon={iconValue}
            positive={messageType === 'positive'}
            error={messageType === 'error' || messageType === 'error_list'}
            onDismiss={handleDismiss}
            position="center"
            header={message}
            list={messageType === 'error_list' ? listValue : null}
        />
    ) : null;
};
