/* istanbul ignore file */
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ATMModal } from '../../atoms/ATMModal/ATMModal.component';
import { ATMButton } from '../../atoms/ATMButton/ATMButton.component';
import styles from './MOLIdleTime.module.scss';
import { MOLCountDown } from './MOLCountDown.component';

export type IMOLIdleProps = {
  onLogout: () => void;
  timeout: number;
  onContinue?: () => void;
};

const MOLIdleTime: React.FC<IMOLIdleProps> = ({ ...props }) => {
  const [idle, setIdle] = useState(false);
  const handleOnIdle = () => {
    setIdle(true);
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a complete state
      props.onLogout();
    //   return null;
    }
    // Render a countdown
    return (
      <div>
        <div className={styles.timerText}>
          Do you wish to continue your current session?
        </div>
        <div>
          You will be automatically logged out in
          <span className={styles.timeDisplay}>
            {minutes}:{seconds <= 9 ? 0 : null}
            {seconds}
          </span>
        </div>
      </div>
    );
  };

  useIdleTimer({
    timeout: props.timeout * 60 * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const handleonContinue = () => {
    setIdle(false);
    if (props.onContinue) {
      props.onContinue();
    }
  };

  const time = new Date();

  return idle ? (
    <ATMModal
      closeOnDimmerClick={false}
      onClose={() => setIdle(false)}
      open={idle}
      size="tiny"
    >
      <ATMModal.Header>Idle Session Warning</ATMModal.Header>
      <ATMModal.Content>
        <MOLCountDown
          date={time.setSeconds(time.getSeconds() + 300)}
          renderer={renderer}
        />
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton secondary onClick={props.onLogout}>
          Logout
        </ATMButton>
        <ATMButton primary onClick={() => handleonContinue()}>
          Yes, Continue
        </ATMButton>
      </ATMModal.Actions>
    </ATMModal>
  ) : null;
};

export { MOLIdleTime };
