import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { Notification } from './components/common/Notification/Notification.component';
import { AuthenticationProvider } from './contexts/authentication.context';
import { NotificationProvider } from './contexts/notification.context';
import './assets/styles/main.scss';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
                <NotificationProvider>
                    <App />
                    <Notification />
                </NotificationProvider>
            </AuthenticationProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
