import React, { FC, useState, useEffect } from 'react';
import {
    Grid,
    Header,
    Input,
    Form,
    Button,
    Icon,
    Message,
} from 'semantic-ui-react';
import OTPInput from 'react-otp-input';

import { FPOTPFormType } from '../../../../../types/forgotPassword.types';
import Language from '../../../../../libraries/language';
import styles from '../../Public.module.scss';
import { forgotPassword } from 'src/services/authentication.services';

const FPOTPForm: FC<FPOTPFormType> = ({
    onSubmitOTPHandler,
    navigatePageHandler,
    formData,
}) => {
    const [OTP, setOTP] = useState<string>('');
    const [timer, setTimer] = useState<number>(30);
    const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<string>('');

    const submitEmailSuccess = () => {
        //do Nothing
    };

    const submitEmailError = (err) => {
        console.log(err);
        setTimer(0);
        setIsTimerRunning(false);
        setIsEmailSent(false);
        setDisplayError(err.message);
        setTimeout(() => {
            setDisplayError('');
        }, 3000);
    };

    const resendEmailHandler = async () => {
        setTimer(30);
        setIsTimerRunning(true);
        setIsEmailSent(true);
        forgotPassword({ email: formData.email as string}, submitEmailSuccess, submitEmailError);
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && isEmailSent) {
            setIsTimerRunning(false);
        }

        return () => clearInterval(interval);
    }, [isTimerRunning, timer, isEmailSent]);

    return (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_FORGOT_PASSWORD}
                </Header>
                <Header as="h5" className={styles.publicSubTitle}>
                    {Language.TTL_FP_SUB_TITLE}
                </Header>

                {!!displayError && (
                    <Grid.Row>
                        <Grid.Column>
                            <Message negative>
                                <Message.Content>
                                    {displayError}
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                )}

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <Form>
                            <Form.Field required>
                                <label
                                    htmlFor="email"
                                    className={styles.publicLabel}
                                >
                                    {Language.LBL_EMAIL_ADDRESS}
                                </label>
                                <Input
                                    type="email"
                                    placeholder={Language.LBL_PLC_EMAIL_ADDRESS}
                                    className={styles.publicFormField}
                                    icon={<Icon name="check" size="small" />}
                                    value={formData.email}
                                    disabled
                                />
                                <p className={styles.publicFormFieldNote}>
                                    {Language.LBL_OTP_MESSAGE}
                                </p>
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <p>
                            <span className={styles.publicBold}>
                                {Language.NT_VERIFY_EMAIL_NOTE[0]}
                            </span>
                            {Language.NT_VERIFY_EMAIL_NOTE[1]}
                        </p>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <OTPInput
                            shouldAutoFocus
                            numInputs={6}
                            value={OTP}
                            onChange={setOTP}
                            renderInput={(props) => (
                                <input
                                    data-testid="otpInput"
                                    {...props}
                                    type="text"
                                    className={styles.publicOTPInput}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <div className={styles.publicVerificationTimer}>
                            <p className={styles.publicRemoveMarginBottom}>
                                {Language.NT_RESEND_EMAIL}
                            </p>
                            {isTimerRunning && timer > 0 ? (
                                <p>{`00:${
                                    timer < 10 ? `0${timer}` : timer
                                }`}</p>
                            ) : (
                                <p
                                    className={styles.publicResendLink}
                                    onClick={resendEmailHandler}
                                >
                                    {Language.LBL_LNK_RESEND}
                                </p>
                            )}
                        </div>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <Button
                            fluid
                            className={styles.sempraButton}
                            onClick={() => onSubmitOTPHandler(OTP)}
                            disabled={OTP.length !== 6}
                        >
                            {Language.LBL_BTN_NEXT}
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <Button
                            fluid
                            className={styles.sempraOutlineButton}
                            onClick={() => navigatePageHandler('previous')}
                        >
                            <Icon name="arrow left" />
                            <span className={styles.publicBold}>
                                {Language.LBL_BTN_BACK}
                            </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};

export default FPOTPForm;
