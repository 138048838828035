import * as yup from 'yup';

import Language from '../libraries/language';
import Lang from '../libraries/language';
// import Lang from '../libraries/language';

export const programStepOneFormDataSchema = yup.object().shape({
    legalBusinessName: yup.string().required(Language.LBL_ERR_LBN),
    contactName: yup.string().required(Language.LBL_ERR_CONTACT_NAME),
    email: yup
        .string()
        .email(Language.LBL_ERR_EMAIL_INVALID)
        .required(Language.LBL_ERR_EMAIL),
    phoneNumber: yup
        .string()
        .transform((value) => value.replace(/[+\-() ]/g, ''))
        .test('phone-number', Language.LBL_ERR_PHONE_NUMBER_MATCH, (value) => {
            if (!value) return true;
            if (/^\d{10}$/.test(value)) return true;

            return false;
        })
        .required(Language.LBL_ERR_PHONE_NUMBER),
    address: yup.string().required(Language.LBL_ERR_ADDRESS),
    zipCode: yup.string().required(Language.LBL_ERR_ZIP_CODE),
});

export const programStepTwoFormDataSchema = yup.object().shape({
    numberOfTrucksCurrentlyInFleet: yup
        .string()
        .required(Language.LBL_ERR_NOTCINF),
    // naturalGas: yup.string().required(Language.LBL_ERR_NATURAL_GAS),
    naturalGas: yup
        .number()
        .max(100)
        .label(Lang.LBL_NATURAL_GAS)
        .when('fuelType', (fuelType, schema) => {
            if (fuelType[0] === 'rng')
                return schema.min(1).typeError(Lang.LBL_ERR_NATURAL_GAS);
            return schema.required().typeError(Lang.LBL_ERR_NATURAL_GAS);
        }),
    hydrogen: yup
        .number()
        .max(100)
        .label(Lang.LBL_HYDROGEN)
        .when('fuelType', (fuelType, schema) => {
            if (fuelType[0] === 'hydrogen')
                return schema.min(1).typeError(Lang.LBL_ERR_HYDROGEN);
            return schema.required().typeError(Lang.LBL_ERR_HYDROGEN);
        }),
    estimatedDateOfPurchase: yup
        .date()
        .required(Language.LBL_ERR_ESTIMATED_DATE_OF_PURCHASE),
    estimatedDateOfTruckDelivery: yup
        .date()
        .when('estimatedDateOfPurchase', (estimatedDate, schema) => {
            return estimatedDate[0]
                ? schema.required(
                      Language.LBL_ERR_ESTIMATED_DATE_OF_TRUCK_DELIVERY
                  )
                : schema;
        }),
});

export const programStepThreeFormDataSchema = yup.object().shape({
    acceptTerms: yup
        .mixed()
        .test(
            'accepted',
            Language.LBL_ERR_ACCEPT_TERMS,
            (value) => value === true
        ),
});

export const programStepFourFormDataSchema = yup.object().shape({
    name: yup.string().required(Language.LBL_ERR_NAME),
    title: yup.string().required(Language.LBL_ERR_TITLE),
});
