import React, { FC } from 'react';
import {
    Grid,
    Header,
    Message,
    Form,
    Input,
    Button,
    Icon,
} from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import useCustomResolver from '../../../../../hooks/useCustomResolver';
import { ForgotPasswordFormDataType } from '../../../../../types/forgotPassword.types';
import { forgotPasswordFormDataSchema } from '../../../../../schemas/forgotPassword.schemas';
import { FPFormType } from '../../../../../types/forgotPassword.types';
import Language from '../../../../../libraries/language';
import styles from '../../Public.module.scss';

const FPForm: FC<FPFormType> = ({
    onSubmitEmailHandler,
    isLoading,
    hasError,
}) => {
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ForgotPasswordFormDataType>({
        resolver: useCustomResolver(forgotPasswordFormDataSchema),
    });

    const onSubmitFormHandler = (data: ForgotPasswordFormDataType) => {
        onSubmitEmailHandler(data.email);
    };

    return (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_FORGOT_PASSWORD}
                </Header>
                <Header as="h5" className={styles.publicSubTitle}>
                    {Language.TTL_FP_SUB_TITLE}
                </Header>

                <Form onSubmit={handleSubmit(onSubmitFormHandler)}>
                    {!_.isEmpty(errors) && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message negative>
                                    <Message.Header>
                                        {Language.TTL_FORM_ERROR}
                                    </Message.Header>
                                    <Message.List>
                                        {Object.values(errors).map(
                                            (err, idx) => (
                                                <Message.Item key={idx}>
                                                    {err as any}
                                                </Message.Item>
                                            )
                                        )}
                                    </Message.List>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {!!hasError && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message negative>
                                    <Message.Content>
                                        {hasError}
                                    </Message.Content>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    <Grid.Row className={styles.publicFormRow}>
                        <Grid.Column>
                            <Form.Field
                                required
                                {...(errors.email && { error: true })}
                            >
                                <label
                                    htmlFor="email"
                                    className={styles.publicLabel}
                                >
                                    {Language.LBL_EMAIL_ADDRESS}
                                </label>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="email"
                                            placeholder={
                                                Language.LBL_PLC_EMAIL_ADDRESS
                                            }
                                            className={styles.publicFormField}
                                        />
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className={styles.publicFormRow}>
                        <Grid.Column>
                            <Button
                                fluid
                                type="submit"
                                className={styles.sempraButton}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {Language.LBL_BTN_CONTINUE}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Form>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <Button
                            fluid
                            className={styles.sempraOutlineButton}
                            onClick={() => {
                                navigate('/');
                            }}
                        >
                            <Icon name="arrow left" />
                            <span className={styles.publicBold}>
                                {Language.LBL_BTN_BACK_TO_SIGNIN}
                            </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};

export default FPForm;
