import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

import { getActiveProgramSummary } from '../services/program.services';

export const useFetchActiveProgramSummary = (enabled: boolean) => {
    return useQuery<AxiosResponse, AxiosError>({
        queryKey: ['activeProgram'],
        queryFn: getActiveProgramSummary,
        enabled: enabled,
        retry: 1,
        refetchOnWindowFocus: false,
    });
};
