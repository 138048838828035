import React, { forwardRef, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { FormFieldProps, Form, Ref } from 'semantic-ui-react';
import ClassNames from 'classnames';
import styles from './ATMField.module.scss';
import { ATMCheckbox } from '../ATMCheckbox/ATMCheckbox.component';
import { ATMDropdown } from '../ATMDropdown/ATMDropdown.component';

export type IATMFieldProps<T extends FieldValues> = Omit<
  UseControllerProps<T>,
  'defaultValue' | 'control' | 'name'
> &
  Omit<FormFieldProps, 'control' | 'name'> & {
    control?: Control<T>;
    name: string;
    defaultChecked?: boolean;
    onChange?:
      | ((event: React.ChangeEvent<HTMLFormElement>) => any)
      | ((data: [React.ChangeEvent<HTMLFormElement>, any]) => any)
      | ((data: [React.ChangeEvent<HTMLFormElement>, any, any]) => any)
      | ((event: React.ChangeEvent<HTMLFormElement>, data: any) => any);
    onBlur?: (event: React.FocusEvent<HTMLFormElement>, data: any) => void;
  };
type IError = {
  message: string;
};
const createRef =
  (Element: any, ref: any): React.FC =>
  ({ children, ...props }: any) =>
    (
      <Ref innerRef={ref}>
        <Element {...props}>{children}</Element>
      </Ref>
    );
const SField = <T extends FieldValues>(props: IATMFieldProps<T>, inputRef) => {
  const { error, as: Element, control, name, ...fieldProps } = props;
  if (error) {
    fieldProps.error = (error as IError).message
      ? { content: (error as IError).message }
      : error;
  }
  const element = useMemo(
    () => (inputRef ? createRef(Element, inputRef) : Element),
    [Element, inputRef]
  );
  const { rules, defaultValue, ...newProps } = fieldProps;
  if (!control) {
    return (
      <Form.Field
        {...newProps}
        defaultValue={defaultValue || fieldProps.defaultChecked}
        className={ClassNames(styles.wrapper, fieldProps.className)}
        control={element}
      />
    );
  }
  return (
    <Controller
      name={name as any}
      control={control}
      defaultValue={defaultValue || fieldProps.defaultChecked}
      rules={rules}
      render={({ field: { onChange, onBlur, ref, ...childProps } }) => {
        const newFieldProps: any = {
          ...newProps,
          ...childProps,
          onChange,
        };
        if (newProps.onChange) {
          newFieldProps.onChange = (...args: any[]) =>
            newProps.onChange && onChange((newProps as any).onChange(args));
        }
        if (Element === ATMDropdown && newFieldProps.multiple) {
          newFieldProps.value = newFieldProps.value || [];
        }
        if (Element === ATMCheckbox) {
          newFieldProps.checked =
            newFieldProps.checked === undefined
              ? !!newFieldProps.value
              : newFieldProps.checked;
          newFieldProps.value = newFieldProps.checked ? 1 : 0;
          if (newFieldProps.inline) {
            return (
              <Form.Field error={newFieldProps.error}>
                <ATMCheckbox {...newFieldProps} />
              </Form.Field>
            );
          }
        }
        return (
          <Form.Field
            {...newFieldProps}
            className={ClassNames(styles.wrapper, newProps.className)}
            control={element}
            onBlur={(event: React.FocusEvent<HTMLFormElement>) => {
              if (newProps.onBlur) {
                const newValue = newProps.onBlur(event, childProps.value);
                if (newValue !== undefined) {
                  onChange(childProps.value);
                }
              }
              onBlur();
            }}
          />
        );
      }}
    />
  );
};
type IATMField = <T extends FieldValues, R>(
  props: IATMFieldProps<T> & { ref?: React.RefObject<R> }
) => ReturnType<typeof SField>;
export const ATMField = forwardRef(SField) as unknown as IATMField;
