import React, { FC, useState, useEffect } from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';

import { ModalType } from '../../../types/common.types';
import Language from '../../../libraries/language';

const ConfirmModal: FC<ModalType> = ({
    open,
    setOpen,
    onConfirm,
    hasError,
    isLoading,
}) => {
    const [displayError, setDisplayError] = useState<string>('');

    useEffect(() => {
        let errorTimer;
        if (hasError) {
            setDisplayError(Language.MSG_ERROR_500);

            errorTimer = setTimeout(() => {
                setDisplayError('');
            }, 3000);
        }

        return () => {
            clearTimeout(errorTimer);
        };
    }, [hasError]);

    return (
        <Modal
            size="tiny"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>{Language.TTL_CONFIRM}</Modal.Header>
            <Modal.Content>
                {!!displayError && (
                    <Message negative>
                        <Message.Content>{displayError}</Message.Content>
                    </Message>
                )}
                <Modal.Description>
                    <p>
                        By clicking <b>{"'Yes'"}</b>, you acknowledge that your
                        action serves as an{' '}
                        <b>electronic signature equivalent</b>, confirming your
                        agreement to submit the application for the incentive
                        program.
                    </p>
                </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    className="sempraOutlineButton"
                    content={Language.LBL_NO}
                    onClick={() => setOpen(false)}
                    disabled={isLoading}
                />

                <Button
                    positive
                    content={Language.LBL_YES}
                    onClick={onConfirm}
                    loading={isLoading}
                    disabled={isLoading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmModal;
