import React, { FC, useEffect, useState } from 'react';
import { ATMButton } from 'src/components/common/CommonUI/atoms/ATMButton/ATMButton.component';
import Lang from 'src/libraries/language';
import { ATMModal } from 'src/components/common/CommonUI/atoms/ATMModal/ATMModal.component';
import { ATMMessage } from 'src/components/common/CommonUI/atoms/ATMMessage/ATMMessage.component';
import { ATMGrid } from 'src/components/common/CommonUI/atoms/ATMGrid/ATMGrid.component';
import { ATMField } from 'src/components/common/CommonUI/atoms/ATMField/ATMField.component';
import { ATMTextArea } from 'src/components/common/CommonUI/atoms/ATMTextArea/ATMTextArea.component';
import { ATMIcon } from 'src/components/common/CommonUI/atoms/ATMIcon/ATMIcon.component';
import { CancelApplicationModelType } from 'src/types/editApplication.types';
import styles from './Application.module.scss';
import { useCancelApplication } from 'src/hooks/useApplicationHooks';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import { useAuthenticationState } from 'src/contexts/authentication.context';

const CancelApplicationModel: FC<CancelApplicationModelType> = ({ handleClosePopup, application }) => {
  const { authenticatedUserEmail } = useAuthenticationState();
  const [reason, setReason] = useState("");
  const notificationDispatch = useNotificationDispatch();
  const { mutate: cancelApplicationService, isLoading, isError, isSuccess, error } = useCancelApplication();

  useEffect(() => {
    if (isSuccess) {
      notificationDispatch({
        type: 'SUCCESS',
        payload: Lang.MSG_APP_Cancel_SUCESS.replace("${appId}", application.id),
      });
      handleClosePopup();
    }
  }, [isSuccess])

  const handleCancelClick = () => {
    const requestBody = {
      applicationNumber: application.id,
      resonForCancellation: reason,
      updatedBy: authenticatedUserEmail
    };

    cancelApplicationService(requestBody)
  };

  const renderCancelAppFields = () => {
    return (
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column computer={16}>
            <ATMModal.Description>
              {Lang.MSG_CANCEL_APPLICATION_MESSAGE_TWO.split("${appId}")[0]}
              <strong>{application.id}</strong>
              {Lang.MSG_CANCEL_APPLICATION_MESSAGE_TWO.split("${appId}")[1]}
            </ATMModal.Description>
          </ATMGrid.Column>
        </ATMGrid.Row>
        <ATMGrid.Row className='noTopPadding'>
          <ATMGrid.Column computer={16}>
            <ATMField
              name="reason"
              as={ATMTextArea}
              className={styles["textArea"]}
              onChange={(_, data) => { setReason(data.value) }}
              label={Lang.LBL_REASON_FOR_CANCELLATION}
              placeholder={Lang.LBL_ENTER}
              charCount
              charLimit={100}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    )
  }

  return (
    <ATMModal
      closeOnDimmerClick={false}
      open={true}
      onClose={handleClosePopup}
      size="small"
    >
      <ATMModal.Header>{Lang.LBL_CANCEL} {Lang.LBL_APPLICATION}</ATMModal.Header>
      <ATMModal.Content>
        {isError ?
          <ATMMessage className='sempraTinyPaddingAround sempraNoMarginTop' error content={error.message} /> : null
        }
        {renderCancelAppFields()}
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column textAlign="left" computer={11} mobile={16}>
              <ATMIcon
                name='warning circle'
              />
              <span>{Lang.MSG_CANCEL_APPLICATION_WARNING}</span>
            </ATMGrid.Column>
            <ATMGrid.Column textAlign="right" computer={5} mobile={16}>
              <ATMButton
                secondary
                onClick={handleClosePopup}
                size='tiny'
                className={styles['marginRight']}
                disabled={isLoading}
              >
                {Lang.LBL_NO}
              </ATMButton>
              <ATMButton
                onClick={handleCancelClick}
                size='tiny'
                color='red'
                loading={isLoading}
                disabled={isLoading}
              >
                {Lang.LBL_YES_CANCEL}
              </ATMButton>
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default CancelApplicationModel;
