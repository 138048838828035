/* eslint-disable max-len */
export default {
    NT_VERIFY_EMAIL:
        'To verify your email address, please click on the verify button: ',
    NT_VERIFY_EMAIL_NOTE: [
        'Note: ',
        "Check your spam/other folders if you couldn't find the mail in your inbox.",
    ],
    NT_RESEND_EMAIL: "Didn't receive the email? ",
    NT_LANDING_PROV_POLICY_PART1: `This SoCalGas® LCFS Fuel Card Incentive Program Application and Agreement (“Agreement”) is hereby entered into between Southern California Gas Company (“SoCalGas”) and the entity executing this Agreement (“Applicant”). Subject to the terms and conditions of this Agreement and the terms, conditions, and rules (“Program Rules”) of the SoCalGas LCFS Fuel Card Incentive Program (“Program”), SoCalGas agrees to provide (1) Pre-funded SoCalGas LCFS Fuel Card to be used at SoCalGas public-access utility-owned natural gas (SCG stations) for every newly purchased and delivered “near zero emission”<sup>1</sup>`,
    NT_LANDING_PROV_POLICY_PART2: `Class 8<sup>2</sup> heavy-duty renewable natural gas truck (RNG truck) to operate within the SoCalGas service territory. Hydrogen fuel cards will only be available once SoCalGas receives appropriate regulatory approvals to own and operate hydrogen fueling stations and such stations are constructed and begin operation.`,
    NT_PROGRAM_RULES: [
        `The SoCalGas Low Carbon Fuel Standard (LCFS) Incentive Program’s period of accepting applications (Solicitation Period) will end 90 days from the Program Start Date Monday, September 9th, 2024.`,
        `To participate in the SoCalGas LCFS Fuel Card Incentive Program (Program), applicants MUST purchase a New Class 8 heavy-duty RNG/CNG Truck after the Program Launch Date and no more than 30 days after the Solicitation Period ends Sunday, December 8th, 2024.`,
        `Customer must have a physical location located within the SoCalGas service territory. (Confirmed by Zip Code)`,
        `Applications received prior to the end of the Solicitation Period will be evaluated for qualification as set forth within these Program Rules, then either awarded (Funding Queue) or placed on the waiting list based on available funds.`,
        `Applicants in the Funding Queue will be notified within 10 business days of the end of the Solicitation Period. Applicants placed on the waiting list will be notified within thirty (30) days after the end of the Solicitation Period.`,
        `During the Solicitation Period, changes to applications will be limited to decreases in the number of SCG LCFS Fuel Cards applied for and/or application cancellations only.`,
        `Applicants must provide proof of purchase after Program Launch Date AND proof of Truck delivery before SoCalGas will provide an SCG LCFS Fuel Card.`,
        `Cards will be provided on a first-come, first-served basis per the priorities set forth in these rules  and until accumulated inventory funding is exhausted.`,
        `Applicants are allowed to use the SCG LCFS Fuel Card only at SoCalGas Heavy-Duty Public-Access Stations (SCG stations).`,
        `SoCalGas reserves the right not to renew the assigned SCG LCFS Fuel Card for any reason SoCalGas deems necessary, including lack of use and/or prohibited third-party sell or card reassignment. Any remaining funds not used at that point will go back to the Program for use by another qualified applicant.`,
        `If an SCG LCFS Fuel Card is lost or stolen, the Applicant must contact their SoCalGas  representative immediately or email infolcfsip@socalgas.com.`,
        `SoCalGas will not be responsible for refunding any prepaid funds used on cards prior to the reported-lost-or-stolen date.`,
        `This Program may be modified or terminated at any time without prior notification.`,
    ],
    NT_TAC: `This SoCalGas® Truck Loan Program Application and Agreement (“Agreement”)
    is hereby entered into between Southern California Gas Company (“SoCalGas”)
    and the entity executing this Agreement (“Customer”). Subject to the terms and
    conditions of this Agreement and the terms, conditions and rules (“Program
    Rules”) of the SoCalGas Truck Loan Program (“Program”), SoCalGas agrees to
    pay the rental charges and fuel costs associated with Customer’s rental of one
    (1) medium or heavy duty truck (“Truck”).`,
    NT_TAC_POINTS: [
        [
            `Payment of Cost`,
            `SoCalGas shall pay for the Costs of Customer’s rental of one (1) Truck for a
        period of no more than fourteen (14) calendar days (“Rental Period”). For
        purposes of this Agreement, “Costs” means the rental charges (i.e., the
        daily cost or weekly cost, as applicable, the mileage cost, the fuel cost, and
        the cleaning fee), as determined by the truck rental company subject to
        the terms of its agreement with SoCalGas (“Rental Company Agreement”),
        associated with Customer’s rental of the Truck during the Rental Period.`,
        ],
        [
            `Fuel Card`,
            [
                [
                    `a`,
                    `On or before the commencement of the Rental Period, SoCalGas
        shall deliver (or have delivered) to Customer a fuel card (“Fuel Card”),
        which Customer may use, solely during the Rental Period, to purchase
        compressed natural gas (“CNG”) fuel in connection with its rental
        and use of the Truck. The Fuel Card will be issued for the person
        (“Authorized User”). SoCalGas shall pay for (and only for) the CNG fuel
        costs incurred by the Authorized User on the Fuel Card to fuel the Truck.
        Customer shall not permit an Authorized User to use the Fuel Card for
        any purpose other than to purchase CNG fuel for the Truck
        during the Rental Period. Notwithstanding the foregoing, Customer
        acknowledges that the Fuel Card remains the sole property of
        SoCalGas, and SoCalGas may, at any time, require the return of the
        Fuel Card.`,
                ],
                [
                    `b`,
                    `Customer agrees to return the Fuel Card at the end of the Rental Period.
        If Customer does not return the Fuel Card upon returning the Truck
        to the rental company, Customer shall be liable for all transactions
        recorded against the Fuel Card after the end of the Rental Period.`,
                ],
                [
                    `c`,
                    `The purchase of CNG fuel on the Fuel Card is between Customer and
        the participating retailer. Customer acknowledges that SoCalGas is not
        liable for (a) CNG fuel purchased by Customer using the Fuel Card, (b)
        the actions of any participating Fuel Card retailer, (c) any fault with the
        Fuel Card or with the electronic system that processes the Fuel Card
        transaction, and/or (d) any loss or damage suffered by Customer arising
        out of the supply or use of CNG fuel.`,
                ],
                [
                    `d`,
                    `If a Fuel Card is lost, stolen or misused, Customer shall notify SoCalGas
        immediately at 213-618-7600, requesting cancellation of the Fuel
        Card. Customer shall confirm that notification via e-mail at JSosa@
        semprautilities.com as soon as possible thereafter but, in any event,
        within twelve (12) hours after the Fuel Card is lost, stolen or misused.
        Customer will not be liable for any unauthorized transaction on the
        Fuel Card that is made after SoCalGas receives such email notification.
        SoCalGas will not be liable for any transaction on the Fuel Card effected
        before the day (being a usual working day) that SoCalGas receives from
        Customer such email notification.`,
                ],
            ],
        ],
        [
            `Feedback`,
            `Before and/or after the end of the Rental Period, SoCalGas shall contact
            Customer in order to obtain its feedback and impressions regarding the
            Truck and its use and usefulness in Customer’s business. Among other
            methods of obtaining such feedback, Customer may be asked to complete
            a survey and/or have one or more telephone conversations with a SoCalGas
            representative. Customer agrees to provide timely, truthful and detailed
            feedback in response to all such requests by SoCalGas.`,
        ],
        [
            `Customer Representations and Additional Covenants`,
            `Customer represents that all of the information (whether communicated in
                writing, electronically, or orally) that Customer has provided to SoCalGas in
                connection with this Program, including information provided by Customer
                as part of it applying to participate in the Program, is true and correct as of
                the date of this Agreement. Without limiting the generality of the foregoing,
                Customer represents and covenants as follows: (a) Customer’s principal
                place of business is located in SoCalGas’ service territory and/or San Diego
                Gas & Electric Company’s service territory; (b) as part of its business,
                Customer regularly operates Class 6, 7, and/or 8 vehicles, (c) Customer has
                been provided with a copy of the Program Rules, and has read, understands
                and agrees to comply with each of the terms, conditions and restrictions
                set forth therein; (d) at least 50% of the Truck’s operational mileage shall be
                within the State of California; (e) Customer shall operate the Truck solely in
                connection with the operation of its business; (f) Customer shall operate the
                Truck in accordance with all applicable laws; (g) Customer shall comply with
                all of the rental company’s rules and policies associated with the rental of
                the Truck, as well as the terms and conditions of Customer’s agreement(s)
                with the truck rental company; and (h) Customer has been provided with
                a copy of the Fuel Card issuer’s terms and conditions for use of the Fuel
                Card, and has read, understands, and agrees to comply with all such terms
                and conditions.`,
        ],
        [
            `Breach of Agreement`,
            `In the event Customer breaches or is in default of any of its obligations
            under this Agreement, or does not fully comply with the Program Rules, or
            if any information Customer provides to SoCalGas is false or misleading, in
            each case as determined by SoCalGas in its sole discretion, SoCalGas may,
            without prior notice or demand to Customer, immediately terminate this
            Agreement without prejudice to any of SoCalGas’ rights or other remedies
            available under this Agreement or any law. SoCalGas will be entitled to
            recover from Customer all reasonable costs, expenses, and attorneys’
            fees (including fees and disbursements of in-house and outside counsel)
            incurred by SoCalGas in enforcing the terms of this Agreement.`,
        ],
        [
            `Limitation of Liability`,
            `Customer acknowledges and agrees that the rental and use of the Truck,
            and any liability or right of Customer or the truck rental company arising
            out of or connected in any manner with respect thereto (other than as
            described in the following sentence), is solely between Customer and the
            truck rental company. Customer further acknowledges and agrees that
            SoCalGas’ sole obligation under this Agreement is (a) to pay the rental
            company for the Costs in accordance with and subject to the terms of this
            Agreement and the rental company Agreement, and (b) to pay for CNG
            fuel purchases incurred by Customer on the Fuel Card in accordance with
            and subject to the terms of this Agreement (collectively, “Total Costs”).
            Therefore, Customers agree that (x) SoCalGas’ total liability arising out of
            or in connection with this Agreement shall in no event exceed the Total
            Costs, and (y) in no event will SoCalGas be liable to Customer for any lost
            profits, loss of business, or any consequential, special, incidental, punitive,
            or indirect damages.`,
        ],
        [
            `Indemnity`,
            `Customer shall be solely responsible for and Customer shall indemnify,
            defend and hold SoCalGas, and its affiliates and their respective directors,
            employees, and representatives (collectively, the “Indemnitees”) harmless
            for, from and against any and all claims, actions, suits, proceedings
            (collectively, “Claims”), and any and all losses, liabilities, penalties, fines,
            damages, costs or expenses including without limitation, reasonable
            attorneys’ fees (including fees and disbursements of in-house and outside
            counsel) (collectively, “Liabilities”) resulting from: (a) injuries to or death
            of any and all individuals arising out of or connected in any manner
            with Customer’s rental or use of the Truck, (b) damage to, loss, and/or
            destruction of property (including the Truck), arising out of or connected in
            any manner with Customer’s rental or use of the Truck, (c) Customer’s failure
            to comply with all applicable laws, (d) Customer’s failure to comply with the
            terms and conditions of (x) all of the rental company’s rules and policies
            associated with the rental of the Truck, and/or (y) customer’s agreement(s)
            with the rental company, and/or (e) other third party claims (including,
            without limitation, a rental company claim) of any kind, whether based upon
            negligence, strict liability or otherwise, arising out of or connected in
            any manner to Customer’s rental or use of the Truck.`,
        ],
        [
            `Program Rules`,
            `This Agreement is at all times subject to the Program Rules, as may be
            modified by SoCalGas from time to time in its sole discretion. If the terms
            and conditions of this Agreement conflict with the Program Rules, the
            Program Rules shall govern (only to the extent of such conflict).`,
        ],
        [
            `Term`,
            `This Agreement shall be effective upon SoCalGas’ confirmation that
            Customer’s application has been accepted for participation in the Program
            and, unless earlier terminated, will continue until each party’s respective
            obligations are fully performed and discharged.`,
        ],
        [
            `Miscellaneous`,
            `The formation, interpretation, performance and enforcement of this
            Agreement will be governed by the laws of the State of California, without
            reference to principles of conflicts of laws. In the event of any litigation
            to enforce or interpret any terms of this Agreement, such action will be
            brought in a Superior Court of the State of California located in the County
            of Los Angeles (or, if the federal courts have exclusive jurisdiction over
            the subject matter of the dispute, in the U.S. District Court for the Central
            District of California). This Agreement will not be construed against any
            party as a result of the preparation, substitution, submission or other event
            of negotiation, drafting or execution hereof. Customer may not transfer
            or assign this Agreement or its rights or obligations under this Agreement
            without the prior written consent of SoCalGas. Waiver by SoCalGas of any
            default by Customer will not be construed as a waiver of any other default.
            Any provision of this Agreement that contemplates or governs performance
            or observance subsequent to termination or expiration of this Agreement
            will survive the expiration or termination of this Agreement.`,
        ],
    ],
    NT_PR_FOOTER: [
        `Near zero emission Class 8 heavy-duty trucks qualifying under this program are defined as those trucks that meet the lowest tier of the CARB Optional Low NOX standard (0.02 g/bhp-hr NOX).
    The lowest tier of the CARB Optional Low NOX standard reduces NOX by 90% compared to current heavy-duty truck NOX emission standards.`,
        `Class 8 vehicles have a gross vehicle weight rating of 33,001 pounds and greater `, `https://www.energy.ca.gov/data-reports/energy-almanac/zero-emission-vehicle-and-infrastructure-statistics/medium-and-heavy#:~:text=Heavy%2DDuty%20Weight%20Class,of%2033%2C001%20pounds%20and%20greater`,
    ],
    NT_APPROVE_FUNDING:
        'Acknowledge and accept/decline the offered funding for your LCFS Fuel Card Program application.',
    NT_AF_APPROVE: 'Option 1: Accept Funding',
    NT_AF_DECLINE: 'Option 2: Decline Funding',
    NT_AF_MODIFY: 'Option 3: Modify Application and Accept Funding',
    NT_NO_NOTE_FOUND: 'No Note Found.',
    NT_NOTE: 'Note:',
    NT_BOTH_FUEL_TYPES_NOTE1: 'Submitting applications for both RNG and Hydrogen Fuel types results in separate entries in the system.',
    NT_BOTH_FUEL_TYPES_NOTE2: 'Notifications, updates, and priority assignments are handled independently for each selected fuel type.'
};
