import React from 'react';

type State = {
    message: string;
    showMessage: boolean;
    messageType: string;
    iconValue: string;
    listValue: any;
};

type Action =
    | { type: 'SUCCESS'; payload: string }
    | { type: 'REMOVE_MESSAGE' }
    | { type: 'ERROR'; payload: string }
    | { type: 'ERROR_LIST'; payload: string; listPayload: any };

type Dispatch = (action: Action) => void;

const NotificationStateContext = React.createContext<State | undefined>(
    undefined
);
const NotificationDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const INITIAL_STATE = {
    message: '',
    showMessage: false,
    messageType: '',
    iconValue: '',
    listValue: [],
};

function notificationReducer(state, action) {
    switch (action.type) {
        case 'SUCCESS': {
            return {
                showMessage: true,
                message: action.payload,
                messageType: 'positive',
                iconValue: 'check circle',
            };
        }
        case 'ERROR': {
            return {
                showMessage: true,
                message: action.payload,
                messageType: 'error',
                iconValue: 'warning circle',
            };
        }
        case 'ERROR_LIST': {
            return {
                showMessage: true,
                message: action.payload,
                messageType: 'error_list',
                iconValue: 'warning circle',
                listValue: action.listPayload,
            };
        }
        case 'REMOVE_MESSAGE': {
            return {
                showMessage: false,
                message: '',
                messageType: '',
                iconValue: 'warning circle',
            };
        }
        default:
            return state;
    }
}

function NotificationProvider({ children }) {
    const [state, dispatch] = React.useReducer(
        notificationReducer,
        INITIAL_STATE
    );

    return (
        <NotificationStateContext.Provider value={state}>
            <NotificationDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationDispatchContext.Provider>
        </NotificationStateContext.Provider>
    );
}

function useNotificationState() {
    const context = React.useContext(NotificationStateContext);

    if (context === undefined) {
        throw new Error(
            'useNotificationState must be used within NotificationProvider'
        );
    }

    return context;
}

function useNotificationDispatch() {
    const context = React.useContext(NotificationDispatchContext);

    if (context === undefined) {
        throw new Error(
            'useNotificationDispatch must be used within NotificationProvider'
        );
    }

    return context;
}

export { NotificationProvider, useNotificationState, useNotificationDispatch };
