import React, { useCallback, useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownProps,
  DropdownItemProps,
  DropdownMenuProps,
  DropdownDividerProps,
  DropdownHeaderProps,
  DropdownSearchInputProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import { restrictAngularBracketSymbols } from '../../utils/security';
import styles from './ATMDropdown.module.scss';
import { ATMPopover } from '../ATMPopover/ATMPopover.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';

export type customSize = 'mini' | 'tiny' | 'small' | 'medium' | 'large';

export type IATDropdownProps = DropdownProps & {
  size?: customSize;
  customMultiSelect?: boolean;
  customMultiSelectViewSelected?: boolean;
  customPlaceholder?: string;
  customLabel?: string;
  asButton?: boolean;
  restrictAngularBrackets?: boolean;
  displayItem?: number;
};

export interface IATMDropdown extends React.FC<DropdownProps> {
  Menu: typeof Dropdown.Menu;
  Item: typeof Dropdown.Item;
  Divider: typeof Dropdown.Divider;
  Header: typeof Dropdown.Header;
  SearchInput: typeof Dropdown.SearchInput;
}

const ATMDropdown: IATMDropdown = ({
  children,
  size = 'small',
  restrictAngularBrackets = true,
  displayItem = 0,
  ...props
}) => {
  const { onChange, defaultValue, value: originalValue } = props;
  const [count, setCount] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDataArrayOfObj, setSelectedDataArrayOfObj] = useState([]);
  // eslint-disable-next-line no-spaced-func
  const [value, setValue] = useState<any>(defaultValue || originalValue);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setValue(originalValue);
  }, [setValue, originalValue]);

  const handleInputChange = useCallback(
    async (event, data) => {
      event.persist();

      const dataValue = data.value;

      let newVal = dataValue;

      if (onChange) {
        newVal = await onChange(event, {
          ...data,
          value: dataValue,
        });

        // If onChange doesn't return anything, we'll use the current value
        if (newVal === undefined) {
          newVal = dataValue;
        }
      }

      setValue(newVal);

      return newVal;
    },
    [setValue, onChange]
  );

  const handleSelect = (e, data) => {
    let selectedDataArray = [] as any;
    let selectedDataArrayOfObject = [] as any;

    setCount(data.value.length);
    if (props.search) {
      setValue(data.value);
    } else if (restrictAngularBrackets) {
      setValue(restrictAngularBracketSymbols(data.value));
    } else {
      setValue(data.value);
    }
    if (data.value.length && data.options.length) {
      data.options.map((optionObject: any) => {
        data.value.map((optionValue: string) => {
          if (optionObject.value === optionValue) {
            selectedDataArray.push(optionObject.text);
            selectedDataArrayOfObject.push({
              key: optionObject.key,
              text: optionObject.text,
              value: optionObject.value,
            });
          }
        });
      });
      selectedDataArray = [...selectedDataArray];
      selectedDataArrayOfObject = [...selectedDataArrayOfObject];
    }
    setSelectedData(selectedDataArray);
    setSelectedDataArrayOfObj(selectedDataArrayOfObject);
    setSearchValue('');
  };

  const renderItemContent = (label) => {
    return {
      content: <>{label.text}</>,
    };
  };

  const handleOptions = (deletedValue: string) => {
    let selectedDataArray = [] as any;

    selectedData.map((selectedValue) => {
      if (selectedValue === deletedValue) {
        const selectedValueIndex = selectedData.indexOf(selectedValue);
        selectedData.splice(selectedValueIndex, 1);
      }
    });

    const list = Array.isArray(value) ? [...value] : [];

    list.map((selectedValue) => {
      selectedDataArrayOfObj.map((obj: any) => {
        if (obj.text === deletedValue) {
          if (selectedValue === obj.value) {
            const selectedValueIndex = list.indexOf(selectedValue);
            list.splice(selectedValueIndex, 1);
          }
        }
      });
    });

    selectedDataArray = [...selectedData];
    setValue(list);
    setCount(selectedDataArray.length);
    setSelectedData(selectedDataArray);
  };

  const handleSearchChange = (e, data) => {
    let dataValue = data.searchQuery;
    if (restrictAngularBrackets) {
      dataValue = restrictAngularBracketSymbols(data.searchQuery);
    }
    setSearchValue(dataValue);
  };

  if (props.customMultiSelectViewSelected) {
    return (
      <div>
        {props.customLabel ? (
          <div className={styles.dropdownLabel}>{props.customLabel}</div>
        ) : (
          <></>
        )}
        <div>
          <Dropdown
            size={props.size ? props.size : 'small'}
            className={classNames(props.className, {
              [styles.mini]: size === 'mini',
              [styles.tiny]: size === 'tiny',
              [styles.small]: size === 'small',
              [styles.large]: size === 'large',
            })}
            onSearchChange={handleSearchChange}
            searchQuery={searchValue}
            onChange={handleSelect}
            value={value}
            placeholder={
              count === 0 ? props.customPlaceholder : `${count} Items Selected`
            }
            renderLabel={
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              displayItem >= count || count === 1 ? renderItemContent : () => {}
            }
            multiple={props.customMultiSelectViewSelected}
            {...props}
          >
            {children}
          </Dropdown>
          {count > 0 && (
            <ATMPopover
              className={styles.listMenuPopup}
              size="small"
              position="bottom left"
              on="click"
              trigger={
                <a className={styles.viewSelected}>
                  {count > 0 ? ' View Selected' : ''}
                </a>
              }
            >
              {count > 0 && (
                <div className={styles.menuOptions}>
                  {selectedData.map((text, index) => (
                    <li key={index} className={styles.menuListBorder}>
                      <div className={styles.menuList}>
                        <ATMPopover
                          className={styles.delay}
                          trigger={
                            <div className={styles.selectedText}>{text}</div>
                          }
                          content={text}
                          size="mini"
                        />
                        <ATMIcon
                          color="grey"
                          name="delete"
                          data-testid='deleteViewSelected'
                          onClick={() => handleOptions(text)}
                          borderless
                        />
                      </div>
                    </li>
                  ))}
                </div>
              )}
            </ATMPopover>
          )}
        </div>
      </div>
    );
  }

  if (props.customMultiSelect) {
    return (
      <div>
        {props.customLabel ? (
          <div className={styles.dropdownLabel}>{props.customLabel}</div>
        ) : (
          <></>
        )}
        <div className={styles.multiSelectContainer}>
          <Dropdown
            size={props.size ? props.size : 'small'}
            className={classNames(props.className, {
              [styles.tiny]: size === 'tiny',
              [styles.small]: size === 'small',
              [styles.large]: size === 'large',
            })}
            onSearchChange={handleSearchChange}
            searchQuery={searchValue}
            onChange={handleSelect}
            value={value}
            placeholder={
              count === 0 ? props.customPlaceholder : `${count} Items Selected`
            }
            renderLabel={
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              displayItem >= count || count === 1 ? renderItemContent : () => {}
            }
            multiple={props.customMultiSelect}
            {...props}
          >
            {children}
          </Dropdown>
          <div>
            {count > 1 && (
              <ATMPopover
                className={classNames(
                  styles.listMenuPopup,
                  styles.listMenuPopPosition
                )}
                size="small"
                position="bottom left"
                on="click"
                trigger={
                  count > 0 ? (
                    <ATMIcon
                      name="eye"
                      className={styles.eyeIcon}
                      color="grey"
                    />
                  ) : (
                    ''
                  )
                }
              >
                {count > 0 && (
                  <div className={styles.menuOptions}>
                    {selectedData.map((text, index) => (
                      <li key={index} className={styles.menuListBorder}>
                        <div className={styles.menuList}>
                          <ATMPopover
                            className={styles.delay}
                            trigger={
                              <div className={styles.selectedText}>{text}</div>
                            }
                            content={text}
                            size="mini"
                          />
                          <ATMIcon
                            color="grey"
                            name="delete"
                            onClick={() => handleOptions(text)}
                            borderless
                          />
                        </div>
                      </li>
                    ))}
                  </div>
                )}
              </ATMPopover>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (props.asButton) {
    return (
      <Dropdown
        size={props.size ? props.size : 'small'}
        className={classNames(props.className, styles.buttonDropdown, {
          [styles.tiny]: size === 'tiny',
          [styles.small]: size === 'small',
          [styles.large]: size === 'large',
        })}
        onSearchChange={handleSearchChange}
        searchQuery={searchValue}
        {...props}
      >
        {children}
      </Dropdown>
    );
  }

  return (
    <Dropdown
      className={classNames(props.className, {
        [styles.tiny]: size === 'tiny',
        [styles.small]: size === 'small',
        [styles.large]: size === 'large',
      })}
      {...{
        ...props,
        onChange: handleInputChange,
        value: value || '',
      }}
    >
      {children}
    </Dropdown>
  );
};

ATMDropdown.Menu = Dropdown.Menu;
ATMDropdown.Item = Dropdown.Item;
ATMDropdown.Divider = Dropdown.Divider;
ATMDropdown.Header = Dropdown.Header;
ATMDropdown.SearchInput = Dropdown.SearchInput;

export type IATMDropdownProps = DropdownProps;
export type IATMDropdownItemProps = DropdownItemProps;
export type IATMDropdownMenuProps = DropdownMenuProps;
export type IATMDropdownDividerProps = DropdownDividerProps;
export type IATMDropdownHeaderProps = DropdownHeaderProps;
export type IATMDropdownSearchInputProps = DropdownSearchInputProps;

export { ATMDropdown };
