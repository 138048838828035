export default {
    TTL_APPLICATION: 'LCFS Fuel Card Incentive Program',
    TTL_GET_STARTED: 'Get Started Now',
    TTL_FORM_ERROR: 'There was some errors with your submission',
    TTL_VERIFY_EMAIL: 'Verify your Email Address!',
    TTL_CANCEL: 'Cancel',
    TTL_WELCOME: 'Welcome!',
    TTL_FORGOT_PASSWORD: 'Forgot Password?',
    TTL_FP_SUB_TITLE: "No worries! We'll send you the reset instructions.",
    TTL_SET_NEW_PASS: 'Set new Password',
    TTL_RP_SUB_TITLE: 'Password criteria are mentioned below:',
    TTL_LANDING_FCPP: 'Fuel Card Provision Policy',
    TTL_LANDING_ASP: 'Active Solicitation Program',
    MSG_NO_ACTIVE_PROGRAM:'No Active Solicitation Program found.',
    TTL_LANDING_PAA: 'Previously Applied Application',
    TTL_PF_CI: 'Company Information',
    TTL_PF_TI: 'Truck Information',
    TTL_PF_TI_SUB_ONE: 'Number of Trucks Currently in Fleet',
    TTL_PF_TI_SUB_TWO: 'Number of Trucks to be Purchased',
    TTL_PF_TI_SUB_THREE: 'Time Frame',
    TTL_PF_TOS_ONE: 'Terms of Service',
    TTL_PF_TOS_TWO: 'Program Rules and Terms & Conditions',
    TTL_PF_PR: 'Program Rules',
    TTL_PF_TAC: 'Terms & Conditions',
    TTL_PF_CS: 'Customer Signature',
    TTL_PF_FS: 'Final Summary',
    TTL_SS: 'Steps Summary',
    TTL_CONFIRM: 'E-Signature and Confirmation',
    TTL_MODAL_VIEW_APP: 'Application',
    TTL_SAVE: 'Save',
    TTL_FUNDING_ACCEPTANCE: 'Funding Acceptance',
    TTL_FA_ACCEPT: 'Accept Funding',
    TTL_FA_DECLINE: 'Decline Funding',
};
