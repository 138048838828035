import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Ref } from 'semantic-ui-react';
import { TableAction } from '../../../constants/table.constant';

import {
  IORGDataTableQueryState,
  IORGDataTableOnUpdate,
  IORGDataTableColumn,
} from '../ORGDataTable.component';
import styles from './MOLTableToolBar.module.scss';
import MOLTableFilter, {
  FilterItem,
  IMOLTableFilter,
  IMOLTableFilterItemProps,
} from '../MOLTable-filter/MOLTable-filter.component';
import { ATMResponsive } from '../../../atoms/ATMResponsive/ATMResponsive.component';
import { ATMButton } from '../../../atoms/ATMButton/ATMButton.component';
import { ATMLabel } from '../../../atoms/ATMLabel/ATMLabel.component';
import { ATMIcon } from '../../../atoms/ATMIcon/ATMIcon.component';
import { ATMGrid } from '../../../atoms/ATMGrid/ATMGrid.component';
import { MOLRightPanel } from '../../../molecules/MOLRightPanel/MOLRightPanel.component';
import { ATMTable } from '../../../atoms/ATMTable/ATMTable.component';
import { ATMToggle } from '../../../atoms/ATMToggle/ATMToggle.component';
import { MOLInfoText } from '../../../molecules/MOLInfoText/MOLInfoText.component';

export type IMOLTableToolbar = () => React.ReactNode | React.ReactNode;
export type IMOLTableToolbarFilteredBy =
  | boolean
  | ((values: IORGDataTableQueryState['filters']) => string[]);

export type IMOLTableToolbarProps = {
  state: IORGDataTableQueryState;
  counter: boolean;
  total: number;
  count: number;
  toolbars?: IMOLTableToolbar[] | IMOLTableFilter;
  filters?: IMOLTableFilter;
  handleChange: IORGDataTableOnUpdate;
  filteredBy?: IMOLTableToolbarFilteredBy;
  tableLabel?: any;
  customFilter?: boolean;
  customFilterContent?: React.ReactNode;
  customFilterBtn?: React.ReactNode;
  removeCancelApplyinfilters?: boolean;
  columnFilter?: boolean;
  columns: IORGDataTableColumn<any>[];
  handleColumnFilterApply?: (columns: IORGDataTableColumn<any>[]) => void;
  dragDropIcon?: React.ReactNode;
  filterCollapsed?: boolean;
  columnSettingsHeader?: string;
  buttonSize?: string;
  toggleSize?: string;
  getFromToData?: (any) => void;
};

const FILTER_DISPLAY_LIMIT = 2;

const reorder = <T extends Record<string, any>>(
  list: T[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  ...draggableStyle,
  userSelect: isDragging && 'none',
  position: isDragging && 'static',
  background: isDragging && 'rgba(237, 240, 247, 0.86)',
  boxShadow: isDragging && '0 0 3px #009bda',
});

export const MOLTableToolBar: React.FC<IMOLTableToolbarProps> = ({
  state,
  counter,
  total = 0,
  count = 0,
  toolbars = [],
  filters,
  handleChange,
  filteredBy,
  tableLabel,
  customFilter = false,
  customFilterContent,
  customFilterBtn,
  removeCancelApplyinfilters = false,
  columnFilter = false,
  columns,
  handleColumnFilterApply,
  dragDropIcon,
  filterCollapsed = false,
  columnSettingsHeader = 'Column Settings',
  buttonSize = 'tiny',
  toggleSize,
  getFromToData,
}) => {
  const [isFilterToggle, setIsFilterToggle] = useState(filterCollapsed);
  const [isOpenColumnFilter, setIsOpenColumnFilter] = useState(false);
  const [columnsData, setColumnsData] = useState(columns);

  const [fromData, setFromData] = useState(
    count + (state.page - 1) * state.limit - (count - 1)
  );
  const [toData, setToData] = useState(count + (state.page - 1) * state.limit);

  useEffect(() => {
    setColumnsData(columns);
  }, [columns, setColumnsData]);

  useEffect(() => {
    setFromData(count + (state.page - 1) * state.limit - (count - 1));
    setToData(count + (state.page - 1) * state.limit);
  }, [state, count]);

  useEffect(() => {
    getFromToData?.({ fromData, toData });
  }, [fromData, toData]);

  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleError = useCallback(
    (field, error: any) => {
      setErrors((values) => {
        return Object.entries({
          ...values,
          [field]: error,
        }).reduce((items: Record<string, any>, [name, err]) => {
          const list = { ...items };

          if (err) {
            list[String(name)] = error;
          }

          return list;
        }, {}) as any;
      });
    },
    [setErrors]
  );

  const handleToggleClick = useCallback(
    (column: IORGDataTableColumn<Record<string, any>>) => {
      setColumnsData((values) => {
        return values.reduce((items: any, item) => {
          if (column.index === item.index) {
            return [
              ...items,
              {
                ...item,
                isVisible:
                  item.isVisible === undefined ? false : !item.isVisible,
              },
            ];
          }

          return [...items, item];
        }, []);
      });
    },
    [setColumnsData]
  );

  const handleFilterChange = useCallback(
    (name, value) => {
      const data = (state.filters || []).find((v) => v.name === name);
      const list = (state.filters || []).filter((val) => val.name !== name);

      handleChange(
        {
          ...state,
          filters: [...list, { ...(data || {}), name, value }].filter(
            (item) => {
              // Filter out empty values
              if (
                (typeof item.value === 'string' && !item.value.trim().length) ||
                (Array.isArray(item.value) && !item.value.length) ||
                item.value === null ||
                item.value === undefined
              ) {
                return false;
              }

              return true;
            }
          ),
        },
        {
          action: TableAction.FILTER,
        }
      );
    },
    [state, handleChange]
  );

  const handleOperatorChange = useCallback(
    (name, op: string) => {
      const data = (state.filters || []).find((v) => v.name === name);
      const list = (state.filters || []).filter((val) => val.name !== name);

      handleChange(
        {
          ...state,
          filters: [
            ...list,
            {
              ...(data || {
                name,
                value: undefined,
              }),
              name,
              op,
            },
          ].filter((item) => {
            // Filter out empty values
            if (
              (typeof item.value === 'string' && !item.value.trim().length) ||
              (Array.isArray(item.value) && !item.value.length) ||
              item.value === null ||
              item.value === undefined
            ) {
              return false;
            }

            return true;
          }),
        },
        {
          action: TableAction.FILTER,
        }
      );
    },
    [state, handleChange]
  );

  const handleApply = useCallback(
    (cols: IORGDataTableColumn<any>[]) => {
      setIsOpenColumnFilter(false);

      if (handleColumnFilterApply) {
        handleColumnFilterApply(cols);
      }
    },
    [handleColumnFilterApply]
  );

  if (
    !counter &&
    !filters &&
    (!toolbars || (Array.isArray(toolbars) && !toolbars.length))
  ) {
    return null;
  }

  let filterTextList =
    filteredBy !== false ? (state.filters ?? []).map((val) => val.name) : [];

  if (typeof filteredBy === 'function') {
    filterTextList = filteredBy(state.filters ?? []);
  }

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const columnsArray = reorder(
      columnsData,
      result.source.index,
      result.destination.index
    );

    setColumnsData(columnsArray);
  };

  const handleColumnFilterOpenClose = (flag: boolean) => {
    setIsOpenColumnFilter(flag);
  };

  const handleCancel = () => {
    setIsOpenColumnFilter(false);
    setColumnsData(columns);
  };

  const filterCount = (state.filters || []).filter(
    (value) => value.name
  ).length;

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.tableInfo}>
          {counter && total > 0 && (
            <span className={styles.tableHeader}>{`Showing ${
              count + (state.page - 1) * state.limit - (count - 1)
            } - ${count + (state.page - 1) * state.limit} of ${total}`}</span>
          )}

          {counter && total === 0 && (
            <span className={styles.tableHeader}>No records</span>
          )}
          {tableLabel !== undefined && (
            <span
              className={counter ? styles.tableCustomLabel : styles.tableLabel}
            >
              {tableLabel}
            </span>
          )}
          {filters && filterTextList.length > 0 && (
            <ATMResponsive greaterThan="mobile">
              <div
                className={classNames({
                  [styles.filteredBy]: true,
                  [styles.filterDot]: counter,
                })}
              >
                <span className={styles.tableHeader}>Filtered By:</span>
                <ul>
                  {[...filterTextList]
                    .slice(0, FILTER_DISPLAY_LIMIT)
                    .map((val, key) => (
                      <li key={`filter-label-${key}`}>
                        <span
                          onClick={() => setIsFilterToggle((v) => !v)}
                          role="button"
                        >
                          <strong>{val}</strong>
                        </span>
                      </li>
                    ))}
                  {filterTextList.length > FILTER_DISPLAY_LIMIT && (
                    <li key="filter-label-more">
                      <span
                        onClick={() => setIsFilterToggle((v) => !v)}
                        role="button"
                      >
                        {`+ ${filterTextList.length - FILTER_DISPLAY_LIMIT}`}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </ATMResponsive>
          )}
        </div>

        <ul className={styles.list}>
          {Array.isArray(toolbars)
            ? toolbars.map((item, key) => (
                <li key={`toolbar-${key}`}>
                  {typeof item === 'function' ? item() : item}
                </li>
              ))
            : Object.entries(toolbars)
                .map(([name, filter]: [string, IMOLTableFilterItemProps]) => ({
                  name,
                  filter,
                }))
                .map((itemProps, key) => (
                  <li key={`toolbar-${key}`}>
                    <FilterItem
                      {...itemProps}
                      value={
                        state.filters?.find(
                          (val) => val.name === itemProps.name
                        )?.value || null
                      }
                      operator={
                        state.filters?.find(
                          (val) => val.name === itemProps.name
                        )?.op
                      }
                      handleChange={handleFilterChange}
                      handleOperator={handleOperatorChange}
                      errors={errors}
                      handleError={handleError}
                      state={state}
                      setState={handleChange}
                    />
                  </li>
                ))}
          {filters && (
            <li key="toolbar-filter">
              <ATMResponsive media="mobile">
                <ATMButton
                  secondary
                  onClick={() => setIsFilterToggle((value) => !value)}
                  className={styles.filter}
                  icon
                >
                  {filterCount > 0 && (
                    <ATMLabel
                      color="blue"
                      floating
                      className={styles.filterCount}
                    >
                      {filterCount}
                    </ATMLabel>
                  )}
                  <ATMIcon name="filter" />
                </ATMButton>
              </ATMResponsive>

              <ATMResponsive greaterThan="mobile">
                <ATMButton
                  secondary
                  size={(buttonSize as any) || 'tiny'}
                  onClick={() => setIsFilterToggle((value) => !value)}
                  className={styles.filter}
                >
                  {filterCount > 0 && (
                    <ATMLabel
                      color="blue"
                      floating
                      className={styles.filterCount}
                    >
                      {filterCount}
                    </ATMLabel>
                  )}
                  <ATMIcon name="filter" />
                  Filters
                </ATMButton>
              </ATMResponsive>
            </li>
          )}
          {customFilter && customFilterBtn}

          {columnFilter && (
            <li>
              <ATMButton
                icon="setting"
                size={(buttonSize as any) || 'tiny'}
                secondary
                data-testid="setting"
                onClick={() => handleColumnFilterOpenClose(true)}
              />
            </li>
          )}
        </ul>
      </div>

      {isOpenColumnFilter && (
        <MOLRightPanel
          isOpen={isOpenColumnFilter}
          header={columnSettingsHeader}
          panelContent={
            <>
              <MOLInfoText
                transparent
                content="Drag and drop to change the column order"
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <ATMGrid className={styles.tableContainer}>
                  <ATMTable className={styles.tableContainer}>
                    <ATMTable.Header>
                      <ATMTable.Row>
                        <ATMTable.HeaderCell
                          className={styles.dragIconColumn}
                        />
                        <ATMTable.HeaderCell>Seq</ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>Column</ATMTable.HeaderCell>
                        <ATMTable.HeaderCell>Visibility</ATMTable.HeaderCell>
                      </ATMTable.Row>
                    </ATMTable.Header>

                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <Ref innerRef={provided.innerRef}>
                          <ATMTable.Body {...provided.droppableProps}>
                            {columnsData.map((column, index) => (
                              <Draggable
                                key={column.index}
                                draggableId={column.index}
                                index={index}
                              >
                                {(val, snapshot) => (
                                  <Ref innerRef={val.innerRef}>
                                    <ATMTable.Row
                                      id={column.title}
                                      {...val.draggableProps}
                                      {...val.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        val.draggableProps.style
                                      )}
                                      key={column.index}
                                      className={
                                        !column.visibilityToggle ||
                                        column.visibilityToggle === undefined
                                          ? styles.notAllowed
                                          : styles.dragRow
                                      }
                                    >
                                      <ATMTable.Cell
                                        className={styles.dragIcon}
                                      >
                                        {dragDropIcon ? (
                                          <div style={{ paddingTop: '8px' }}>
                                            {dragDropIcon}
                                          </div>
                                        ) : (
                                          <div style={{ paddingTop: '8px' }}>
                                            <ATMIcon name="arrows alternate" />
                                          </div>
                                        )}
                                      </ATMTable.Cell>
                                      <ATMTable.Cell>{index + 1}</ATMTable.Cell>
                                      <ATMTable.Cell>
                                        {column.settingColumn
                                          ? column.settingColumn
                                          : column.title}
                                      </ATMTable.Cell>
                                      <ATMTable.Cell>
                                        <ATMToggle
                                          checked={column.isVisible !== false}
                                          onChange={() =>
                                            handleToggleClick(column)
                                          }
                                          size={(toggleSize as any) || null}
                                          disabled={
                                            !(
                                              column.visibilityToggle ===
                                                undefined ||
                                              column.visibilityToggle
                                            )
                                          }
                                        />
                                      </ATMTable.Cell>
                                    </ATMTable.Row>
                                  </Ref>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </ATMTable.Body>
                        </Ref>
                      )}
                    </Droppable>
                  </ATMTable>
                </ATMGrid>
              </DragDropContext>
            </>
          }
          onClose={() => handleCancel()}
          panelFooter={
            <div className={styles.panelFooter}>
              <div className={styles.flex}>
                <ATMButton
                  secondary
                  size="small"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </ATMButton>
                <ATMButton
                  primary
                  size="small"
                  onClick={() => handleApply(columnsData)}
                >
                  Save
                </ATMButton>
              </div>
            </div>
          }
        />
      )}

      {customFilterContent && <div>{customFilterContent}</div>}
      {filters && isFilterToggle && (
        <MOLTableFilter
          handleChange={handleChange}
          filterCollapsed={filterCollapsed}
          state={state}
          filters={filters}
          handleToggle={() => setIsFilterToggle((value) => !value)}
          errors={errors}
          handleError={handleError}
          removeCancelApplyinfilters={removeCancelApplyinfilters}
        />
      )}
    </>
  );
};
