export default {
    LBL_FIRST_NAME: 'First Name',
    LBL_LAST_NAME: 'Last Name',
    LBL_EMAIL_ADDRESS: 'Email Address',
    LBL_PASSWORD: 'Password',
    LBL_CONFIRM_PASSWORD: 'Confirm Password',
    LBL_PLC_FIRST_NAME: 'E.g. John',
    LBL_PLC_LAST_NAME: 'E.g. Doe',
    LBL_PLC_EMAIL_ADDRESS: 'E.g. john.doe@example.com',
    LBL_PLC_PHONE_NUMBER: 'E.g. 9876543210',
    LBL_TERMS_AND_CONDITION: [
        'By signing up, you agree to our ',
        'Terms of Service ',
        'and ',
        'Privacy Policy',
    ],
    LBL_BTN_SIGNUP: 'Sign Up',
    LBL_SIGNIN_HERE: ['Already have an account? ', 'Sign in here'],
    LBL_SIGNIN_AFTER_EMAIL_VERIFICATION: ['After Email verification, ', 'Sign in here'],
    LBL_BTN_VERIFY: 'Verify Email',
    LBL_BTN_RESEND: 'Resend Email',
    LBL_BTN_BACK_TO_SIGNUP: 'Back to Sign Up',
    LBL_PSA_TITLE: 'Password must have:',
    LBL_PSA_RULE_ONE: 'At least 14 characters',
    LBL_PSA_RULE_TWO: 'A numerical digit',
    LBL_PSA_RULE_THREE: 'A special character',
    LBL_PSA_RULE_FOUR: 'Both upper and lower case letters',
    LBL_ERR_FIRST_NAME: 'First name is required',
    LBL_ERR_LAST_NAME: 'Last name is required',
    LBL_ERR_EMAIL: 'Email address is required',
    LBL_ERR_EMAIL_INVALID: 'Invalid email address',
    LBL_ERR_PASSWORD: 'Password is required',
    LBL_ERR_CONFIRM_PASSWORD: 'Confirm password is required',
    LBL_ERR_CONFIRM_PASSWORD_INVALID: 'Passwords must match',
    LBL_ERR_TERMS_AND_CONDITIONS:
        'You must accept the terms of service and privacy policy',
    LBL_BTN_SIGNIN: 'Sign In',
    LBL_SIGNUP_HERE: ["Don't have an account? ", 'Sign up here'],
    LBL_FORGOT_PASSWORD: 'Forgot Password?',
    LBL_BTN_CONTINUE: 'Continue',
    LBL_BTN_BACK_TO_SIGNIN: 'Back to Sign In',
    LBL_OTP_MESSAGE:
        'Please fill in the one-time password that was just shared with your email address by us.',
    LBL_BTN_SUBMIT: 'Submit',
    LBL_BTN_BACK: 'Back',
    LBL_LNK_RESEND: 'Resend OTP',
    LBL_BTN_RESET_PASS: 'Reset Password',
    LBL_MENU_ITEM_ONE: ['Hi', 'Welcome'],
    LBL_HELP: 'Help',
    LBL_MENU_ITEM_THREE: 'Logout',
    LBL_BTN_APPLY: 'Apply for Program',
    LBL_VIEW_APPLICATION: 'View Application',
    LBL_EDIT_APPLICATION: 'Edit Application',
    LBL_CANCEL_APPLICATION: 'Cancel Application',
    LBL_CONFIRM_APPLICATION: 'Confirm Application',
    LBL_BTN_CANCEL: 'Cancel',
    LBL_BTN_PREVIOUS: 'Previous',
    LBL_BTN_NEXT: 'Next',
    LBL_ERR_LBN: 'Legal business name is required',
    LBL_ERR_CONTACT_NAME: 'Contact name is required',
    LBL_ERR_PHONE_NUMBER_MATCH: 'Phone number must be 10 digits',
    LBL_ERR_PHONE_NUMBER: 'Phone number is required',
    LBL_ERR_ADDRESS: 'Address is required',
    LBL_ERR_CITY: 'City is required',
    LBL_ERR_STATE: 'State is required',
    LBL_ERR_ZIP_CODE: 'Zip Code is required',
    LBL_LBN: 'Legal Business Name',
    LBL_ADDRESS: 'Address ',
    LBL_CITY: 'City',
    LBL_STATE: 'State',
    LBL_ZIP_CODE: 'Zip Code',
    LBL_PLC_ENTER: 'Enter',
    LBL_PLC_SELECT: 'Select',
    LBL_PLC_SEARCH: 'Search',
    LBL_PLC_ENTER_ZIP_TO_SEARCH: 'Enter Zip Code to Search',
    LBL_ZIP_CODE_HELPER: 'Search Zip Code to find corresponding City and State',
    LBL_DATE_HELPER:
        'Choose a date within 120 days from the Launch Date',
    LBL_NATURAL_GAS: 'Natural Gas (RNG)',
    LBL_HYDROGEN: 'Hydrogen',
    LBL_ESTIMATED_DATE_OF_PURCHASE: 'Estimated Date of Purchase',
    LBL_ESTIMATED_DATE_OF_TRUCK_DELIVERY: "Estimated Date of Truck's Delivery",
    LBL_ERR_NOTCINF_ONE: '9 or Less',
    LBL_ERR_NOTCINF_TWO: '10 or More',
    LBL_ERR_NOTCINF: 'Number of trucks currently in fleet is required',
    LBL_ERR_FIELD: 'This field is required',
    LBL_ERR_NATURAL_GAS: 'Natural gas is required',
    LBL_ERR_HYDROGEN: 'Hydrogen is required',
    LBL_ERR_ESTIMATED_DATE_OF_PURCHASE:
        'Estimated date of purchase is required',
    LBL_ERR_ESTIMATED_DATE_OF_TRUCK_DELIVERY:
        "Estimated date of truck's delivery is required",
    LBL_ACCEPT_TERMS:
        'I have read and agree to the Program Rules and Terms & Conditions',
    LBL_ERR_ACCEPT_TERMS:
        'You must accept the program rules and terms & conditions',
    LBL_ERR_TITLE: 'Title is required',
    LBL_ERR_NAME: 'Name is required',
    LBL_NAME: 'Name',
    LBL_TITLE: 'Title',
    LBL_BTN_MODIFY: 'MODIFY',
    LBL_BTN_MODIFY2: 'Modify',
    LBL_STEP_ONE: 'Step 1',
    LBL_STEP_TWO: 'Step 2',
    LBL_STEP_THREE: 'Step 3',
    LBL_STEP_FOUR: 'Step 4',
    LBL_SS_TRUCKS: 'Trucks',
    LBL_SS_NGTRUCKS: 'Natural Gas Trucks',
    LBL_SS_HTRUCKS: 'Hydrogen Trucks',
    LBL_SS_DOD: 'DOD',
    LBL_SS_DOP: 'DOP',
    LBL_YES: 'Yes',
    LBL_APPROVE_FUNDING: 'Approve Funding',
    LBL_ACCEPT_FUNDING: 'Accept Funding',
    LBL_BTN_ACCEPT: 'Accept',
    LBL_BTN_DECLINE: 'Decline',
    LBL_BTN_MODIFY_ACCEPT: 'Modify & Accept',
    LBL_MODIFY_ACCEPT: 'Modify & Accept Funding',

    LBL_ADD_INFO: 'Additional Information',
    LBL_RANKING: 'Ranking',
    LBL_STATUS: 'Status',
    LBL_SUBMISSION_DATE: 'Submission Date',
    LBL_VERIFICATION_DATE: 'Verification Date',
    LBL_COMPNAY_INFO: 'Company Information',
    LBL_BUSINESS_NAME: 'Legal Business Name ',
    LBL_CONTACT_NAME: 'Contact Name ',
    LBL_EMAIL: 'Contact Email ',
    LBL_PHONE_NUMBER: 'Phone Number ',
    LBL_TRUCK_INFO: 'Truck Information',
    LBL_TRUCK_IN_FLEET: 'Trucks  Currently in Fleet',
    LBL_HYDROGEN_TRUCKS: 'Hydrogen',
    LBL_RNG_TRUCKS: 'Natural Gas (RNG)',
    LBL_ESTMD_PURCHASE_DATE: 'Estimated Date Of Purchase',
    LBL_ESTMD_DEL_DATE: 'Estimated Date Of Delivery',
    LBL_CUSTOMER_SIGNATURE: 'Customer Signature',
    LBL_INFO_MSG: 'You can only reduce the number of trucks in this application.',
    LBL_INFO_MSG_SECOND:
        'If you wish to increase the number of trucks or change the fuel type, please submit a new application form.',

    LBL_DESIGNATION: 'Designation',
    LBL_MODIFY: 'Modify Application',
    LBL_APP: 'Application',
    LBL_NO: 'No',
    LBL_YES_CANCEL: 'Yes, Cancel',
    LBL_REASON_FOR_CANCELATION: 'Reason for Cancelation',
    MSG_DELETE_WARNING:
        "You won't be able to revert this action after cancelling this application",
    MSG_CANCEL_WARNING: 'Are you sure want to cancel  ',
    MSG_CANCEL_WARNING_SECOND:
        ' from this solicitation program ,if yes then please mention a reason cancellation below',
    LBL_FUEL_CARD_REQUEST: 'Fuel Card Request ($)',
    LBL_APPROVED_FUND: 'Approved Fund',
    LBL_REASON_FOR_CANCELLATION: 'Reason for Cancellation',
    LBL_ENTER: 'Enter',
    LBL_APPLICATION: 'Application',
    LBL_CANCEL: 'Cancel',
    LBL_SAVE: 'Save',
    LBL_MEDIUM_AND_HEAVY_DUTY_ZERO_EMISSION_VEHICLES: 'Medium and Heavy-Duty Zero-Emission Vehicles in California',
};
