import React, { FC } from 'react';
import { Modal, Button, Dimmer } from 'semantic-ui-react';

import { ModalType } from '../../../types/common.types';
import Language from '../../../libraries/language';
import { ATMLoader } from '../CommonUI/atoms/ATMLoader/ATMLoader.component';
import Lang from '../../../libraries/language';

const AcceptOrDeclineModal: FC<ModalType> = ({
    header,
    content,
    open,
    setOpen,
    onConfirm,
    isLoading
}) => {
    return (
        <Modal
            size="tiny"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                {isLoading ? (
                    <Dimmer active={isLoading} inverted>
                        <ATMLoader size="large">{Lang.MSG_LOADING}</ATMLoader>
                    </Dimmer>
                ) : null}
                <Modal.Description>{content}</Modal.Description>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    className="sempraOutlineButton"
                    content={Language.LBL_NO}
                    onClick={() => setOpen(false)}
                />

                <Button
                    className="sempraButton"
                    content={Language.LBL_YES}
                    onClick={onConfirm}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default AcceptOrDeclineModal;
