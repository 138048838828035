/* istanbul ignore file */
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { cognitoUserPool } from 'src/utils/awsCognitoConfig';

export const getSession = async () => {
    try {
        return await new Promise((resolve, reject) => {
            const user = cognitoUserPool.getCurrentUser();
            if (user) {
                user.getSession((err, session: CognitoUserSession) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });
    } catch (err) {
        console.log(err);
        return;
    }
};

export const regenerateToken = (
    session: CognitoUserSession,
    errorCalback: () => void
) => {
    if (!session) {
      errorCalback();
      return;
    }
    const refresh_token = session.getRefreshToken();
    const cognitoUser = cognitoUserPool.getCurrentUser() as any;
    console.log('regenerating Token');
    cognitoUser.refreshSession(refresh_token, (err) => {
        if (err) {
            console.log(err);
            errorCalback();
        }
    });
};