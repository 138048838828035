import AWS from 'aws-sdk';
import { curry, defaultTo } from "ramda";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
const cognitoService = new AWS.CognitoIdentityServiceProvider();

const DEFAULT_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
const CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
const USER_POOL_ID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID;

const orDefaultRegion = defaultTo(DEFAULT_REGION);

const createClientForRegion = curry(
    (region, ClientConstructor) =>
        new ClientConstructor({ region: orDefaultRegion(region) })
);

const createClientForDefaultRegion = createClientForRegion(null);

const cognitoUserPool = new CognitoUserPool({
    UserPoolId: USER_POOL_ID || '',
    ClientId: CLIENT_ID || '',
});

export {
    DEFAULT_REGION,
    CLIENT_ID,
    USER_POOL_ID,
    cognitoUserPool,
    cognitoService,
    createClientForDefaultRegion,
    createClientForRegion,
    orDefaultRegion,
};
