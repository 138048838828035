import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Card, Form, Grid, Label, Icon } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import DatePicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import {
    ProgramFormStepTwoType,
    ProgramStepTwoFormDataType,
} from '../../../../../types/program.types';
import { programStepTwoFormDataSchema } from '../../../../../schemas/program.schemas';
import useCustomResolver from '../../../../../hooks/useCustomResolver';
import Language from '../../../../../libraries/language';
import styles from '../../Private.module.scss';
import moment from 'moment';
import Lang from '../../../../../libraries/language';

const TruckInformation: FC<ProgramFormStepTwoType> = forwardRef(
    ({ formRef, onSubmitNextHandler, formValues }, _) => {
        const [dop, setDOP] = useState<string | undefined>(
            formValues.estimatedDateOfPurchase
                ? moment(formValues.estimatedDateOfPurchase).format(
                      'YYYY-MM-DD'
                  )
                : undefined
        );

        const {
            handleSubmit,
            control,
            formState: { errors },
        } = useForm<ProgramStepTwoFormDataType>({
            resolver: useCustomResolver(programStepTwoFormDataSchema),
            defaultValues: formValues,
        });

        const queryClient = useQueryClient();
        let activeProgram = queryClient.getQueryData('activeProgram') as Record<
            string,
            unknown
        >;
        activeProgram = activeProgram
            ? (activeProgram.data as Record<string, string | number>)
            : {};
        const solicitationStartDate = new Date(moment(activeProgram.startDate as string).format("YYYY-MM-DD HH:mm"));

        const DOPEndDate = new Date(
            moment(activeProgram.startDate as string)
                .add(120, 'days')
                .format('YYYY-MM-DD HH:mm')
        );

        const onSubmitFormHandler = (data: ProgramStepTwoFormDataType) => {
            onSubmitNextHandler(data);
        };

        useEffect(() => {
            formRef.current = handleSubmit(onSubmitFormHandler);
        }, [formRef]);

        const validateNumberField = (val) => {
            if (!val) return val;
            const pattern = /^\d+\.?\d*$/;
            return pattern.test(val) ? Number(val) : validateNumberField(val.substring(0, val.length - 1));
        };

        return (
            <Card fluid className={styles.sectionCard}>
                <Card.Content className={styles.sectionFormContent}>
                    <h2>{Language.TTL_PF_TI}</h2>

                    <Form className={styles.privateProgramForm}>
                        <Grid>
                            <h3 className={styles.privateFormSubTitle}>
                                {Language.TTL_PF_TI_SUB_ONE}
                            </h3>
                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column width={16}>
                                    <Form.Field
                                        required
                                        {...(errors.numberOfTrucksCurrentlyInFleet && {
                                            error: errors.numberOfTrucksCurrentlyInFleet,
                                        })}
                                    >
                                        <Controller
                                            name="numberOfTrucksCurrentlyInFleet"
                                            control={control}
                                            render={({ field }) => (
                                                <Form.Group inline>
                                                    <Form.Radio
                                                        {...field}
                                                        {...(errors.numberOfTrucksCurrentlyInFleet && {
                                                            error: !!errors.numberOfTrucksCurrentlyInFleet,
                                                        })}
                                                        className={
                                                            styles.privateFormRadio
                                                        }
                                                        label={
                                                            Language.LBL_ERR_NOTCINF_ONE
                                                        }
                                                        checked={
                                                            field.value ===
                                                            'nineOrLess'
                                                        }
                                                        onChange={() =>
                                                            field.onChange(
                                                                'nineOrLess'
                                                            )
                                                        }
                                                    />

                                                    <Form.Radio
                                                        {...field}
                                                        {...(errors.numberOfTrucksCurrentlyInFleet && {
                                                            error: errors.numberOfTrucksCurrentlyInFleet,
                                                        })}
                                                        label={
                                                            Language.LBL_ERR_NOTCINF_TWO
                                                        }
                                                        checked={
                                                            field.value ===
                                                            'tenOrMore'
                                                        }
                                                        onChange={() =>
                                                            field.onChange(
                                                                'tenOrMore'
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                            <h3 className={styles.privateFormSubTitle}>
                                {Language.TTL_PF_TI_SUB_TWO}
                            </h3>
                            <Grid.Row className={styles.privateFormRow}>
                                {['rng', 'both'].includes(
                                    activeProgram?.fuelType as string
                                ) ? (
                                    <Grid.Column width={6} mobile={8}>
                                        <Form.Field
                                            required
                                            {...(errors.naturalGas && {
                                                error: errors.naturalGas,
                                            })}
                                        >
                                            <label
                                                htmlFor="naturalGas"
                                                className={styles.privateLabel}
                                            >
                                                {Language.LBL_NATURAL_GAS}
                                            </label>
                                            <Controller
                                                name="naturalGas"
                                                control={control}
                                                render={({ field }) => {
                                                    field.value =
                                                        validateNumberField(
                                                            field.value
                                                        );
                                                    return (
                                                        <Form.Input
                                                            {...field}
                                                            maxLength="3"
                                                            {...(errors.naturalGas && {
                                                                error: errors.naturalGas,
                                                            })}
                                                            type="text"
                                                            placeholder={
                                                                Language.LBL_PLC_ENTER
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                ) : null}

                                {['hydrogen', 'both'].includes(
                                    activeProgram?.fuelType as string
                                ) ? (
                                    <Grid.Column width={6} mobile={8}>
                                        <Form.Field
                                            required
                                            {...(errors.hydrogen && {
                                                error: errors.hydrogen,
                                            })}
                                        >
                                            <label
                                                htmlFor="hydrogen"
                                                className={styles.privateLabel}
                                            >
                                                {Language.LBL_HYDROGEN}
                                            </label>
                                            <Controller
                                                name="hydrogen"
                                                control={control}
                                                render={({ field }) => {
                                                    field.value =
                                                        validateNumberField(
                                                            field.value
                                                        );
                                                    return (
                                                        <Form.Input
                                                            {...field}
                                                            maxLength="3"
                                                            {...(errors.hydrogen && {
                                                                error: errors.hydrogen,
                                                            })}
                                                            type="text"
                                                            placeholder={
                                                                Language.LBL_PLC_ENTER
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                ) : null}
                            </Grid.Row>
                            {activeProgram.fuelType === 'both' ? (
                                <Grid.Row className="noTopPadding">
                                    <Grid.Column>
                                        <small
                                            className={
                                                styles['lineHeight'] +
                                                ' ' +
                                                styles['privateFormHelper']
                                            }
                                        >
                                            <div>{Lang.NT_NOTE}</div>
                                            <ul className="sempraNoMarginTop sempraExtraLargePaddingLeft">
                                                <li>
                                                    {
                                                        Lang.NT_BOTH_FUEL_TYPES_NOTE1
                                                    }
                                                </li>
                                                <li>
                                                    {
                                                        Lang.NT_BOTH_FUEL_TYPES_NOTE2
                                                    }
                                                </li>
                                            </ul>
                                        </small>
                                    </Grid.Column>
                                </Grid.Row>
                            ) : null}

                            <h3 className={styles.privateFormSubTitle}>
                                {Language.TTL_PF_TI_SUB_THREE}
                            </h3>
                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.estimatedDateOfPurchase && {
                                            error: errors.estimatedDateOfPurchase,
                                        })}
                                        className={styles.privateFormDatePicker}
                                    >
                                        <label
                                            htmlFor="estimatedDateOfPurchase"
                                            className={styles.privateLabel}
                                        >
                                            {
                                                Language.LBL_ESTIMATED_DATE_OF_PURCHASE
                                            }
                                        </label>
                                        <Controller
                                            name="estimatedDateOfPurchase"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        icon="calendar alternate outline"
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={(_, data) => {
                                                            let DOPVal:
                                                                | string
                                                                | undefined =
                                                                undefined;
                                                            if (data?.value) {
                                                                DOPVal = moment(
                                                                    new Date(
                                                                        data.value as any
                                                                    )
                                                                ).format(
                                                                    'YYYY-MM-DD'
                                                                );
                                                            }
                                                            setDOP(DOPVal);

                                                            field.onChange(
                                                                data.value
                                                            );
                                                        }}
                                                        onBlur={field.onBlur}
                                                        clearOnSameDateClick
                                                        format="MM-DD-YYYY"
                                                        placeholder={
                                                            Language.LBL_PLC_SELECT
                                                        }
                                                        minDate={
                                                            solicitationStartDate
                                                        }
                                                        maxDate={DOPEndDate}
                                                        filterDate={(date) => {
                                                            if (DOPEndDate) {
                                                                return (
                                                                    date <=
                                                                    DOPEndDate
                                                                );
                                                            }
                                                            return true;
                                                        }}
                                                    />
                                                    {errors.estimatedDateOfPurchase && (
                                                        <Label
                                                            basic
                                                            pointing
                                                            className={
                                                                styles.privateFormLabel
                                                            }
                                                        >
                                                            {
                                                                errors.estimatedDateOfPurchase as any
                                                            }
                                                        </Label>
                                                    )}
                                                    <small
                                                        className={
                                                            styles.privateFormHelper
                                                        }
                                                    >
                                                        <Icon name="info circle" />
                                                        {
                                                            Language.LBL_DATE_HELPER
                                                        }
                                                    </small>
                                                </>
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.estimatedDateOfTruckDelivery && {
                                            error: errors.estimatedDateOfTruckDelivery,
                                        })}
                                        className={styles.privateFormDatePicker}
                                    >
                                        <label
                                            htmlFor="estimatedDateOfTruckDelivery"
                                            className={styles.privateLabel}
                                        >
                                            {
                                                Language.LBL_ESTIMATED_DATE_OF_TRUCK_DELIVERY
                                            }
                                        </label>
                                        <Controller
                                            name="estimatedDateOfTruckDelivery"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        icon="calendar alternate outline"
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={(_, data) => {
                                                            field.onChange(
                                                                data.value
                                                            );
                                                        }}
                                                        onBlur={field.onBlur}
                                                        clearOnSameDateClick
                                                        format="MM-DD-YYYY"
                                                        placeholder={
                                                            Language.LBL_PLC_SELECT
                                                        }
                                                        filterDate={(date) => {
                                                            const selectedDate =
                                                                moment(
                                                                    date
                                                                ).format(
                                                                    'YYYY-MM-DD'
                                                                );
                                                            const todayDate =
                                                                moment().format(
                                                                    'YYYY-MM-DD'
                                                                );
                                                            return dop
                                                                ? selectedDate >
                                                                      dop &&
                                                                      selectedDate >
                                                                          todayDate
                                                                : selectedDate >=
                                                                      todayDate;
                                                        }}
                                                        disabled={!dop}
                                                    />
                                                    {errors.estimatedDateOfTruckDelivery && (
                                                        <Label
                                                            basic
                                                            pointing
                                                            className={
                                                                styles.privateFormLabel
                                                            }
                                                        >
                                                            {
                                                                errors.estimatedDateOfTruckDelivery as any
                                                            }
                                                        </Label>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Card.Content>
            </Card>
        );
    }
);

export default TruckInformation;
