import * as yup from 'yup';

const useCustomResolver = <T extends object>(
    validationSchema: yup.Schema<T>
) => {
    const resolver = async (data: T) => {
        try {
            const values = await validationSchema.validate(data, {
                abortEarly: false,
            });
            return { values, errors: {} };
        } catch (error: any) {
            const errors = error.inner.reduce((acc: any, current: any) => {
                acc[current.path] = current.message;
                return acc;
            }, {});
            return { values: {}, errors };
        }
    };
    return resolver;
};

export default useCustomResolver;
