import React, { FC } from 'react';
import {
    Grid,
    Header,
    Form,
    Input,
    Button,
    Icon,
    Message,
} from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';

import useCustomResolver from '../../../../../hooks/useCustomResolver';
import PasswordStrengthAnalyzer from '../../../../common/PasswordStrengthAnalyzer/PasswordStrengthAnalyzer.component';
import {
    FPResetPasswordFormType,
    FPResetPasswordFormDataType,
} from '../../../../../types/forgotPassword.types';
import { FPResetPasswordFormDataSchema } from '../../../../../schemas/forgotPassword.schemas';
import { unwantedPasswordErrors } from '../../../../../constants/password.constants';
import Language from '../../../../../libraries/language';
import styles from '../../Public.module.scss';

const FPResetPasswordForm: FC<FPResetPasswordFormType> = ({
    onSubmitResetPasswordHandler,
    navigatePageHandler,
    isLoading,
    hasError,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FPResetPasswordFormDataType>({
        resolver: useCustomResolver(FPResetPasswordFormDataSchema),
    });

    const onSubmitFormHandler = (data: FPResetPasswordFormDataType) => {
        onSubmitResetPasswordHandler(data.password);
    };

    return (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_SET_NEW_PASS}
                </Header>
                <Header as="h5" className={styles.publicSubTitle}>
                    {Language.TTL_RP_SUB_TITLE}
                </Header>

                <Form onSubmit={handleSubmit(onSubmitFormHandler)}>
                    {!_.isEmpty(errors) && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message negative>
                                    <Message.Header>
                                        {Language.TTL_FORM_ERROR}
                                    </Message.Header>
                                    <Message.List>
                                        {Object.values(errors).map(
                                            (err, idx) => {
                                                if (
                                                    !unwantedPasswordErrors.includes(
                                                        err as any
                                                    )
                                                ) {
                                                    return (
                                                        <Message.Item key={idx}>
                                                            {err as any}
                                                        </Message.Item>
                                                    );
                                                }
                                                return null;
                                            }
                                        )}
                                    </Message.List>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {!!hasError && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message negative>
                                    <Message.Content>
                                        {hasError}
                                    </Message.Content>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    <Grid.Row className={styles.publicFormRow}>
                        <Grid.Column>
                            <Form.Field
                                required
                                {...(errors.password && { error: true })}
                            >
                                <label
                                    htmlFor="password"
                                    className={styles.publicLabel}
                                >
                                    {Language.LBL_PASSWORD}
                                </label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Input
                                                data-testid="password"
                                                {...field}
                                                type="password"
                                            />

                                            <PasswordStrengthAnalyzer
                                                password={field.value}
                                            />
                                        </>
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className={styles.publicFormRow}>
                        <Grid.Column>
                            <Form.Field
                                required
                                {...(errors.confirmPassword && {
                                    error: true,
                                })}
                            >
                                <label
                                    htmlFor="confirmPassword"
                                    className={styles.publicLabel}
                                >
                                    {Language.LBL_CONFIRM_PASSWORD}
                                </label>
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            data-testid="confirmPassword"
                                            {...field}
                                            type="password"
                                        />
                                    )}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className={styles.publicFormRow}>
                        <Grid.Column>
                            <Button
                                fluid
                                type="submit"
                                className={styles.sempraButton}
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                {Language.LBL_BTN_RESET_PASS}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Form>

                <Grid.Row className={styles.publicFormRow}>
                    <Grid.Column>
                        <Button
                            fluid
                            className={styles.sempraOutlineButton}
                            onClick={() => navigatePageHandler('previous')}
                        >
                            <Icon name="arrow left" />
                            <span className={styles.publicBold}>
                                {Language.LBL_BTN_BACK}
                            </span>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};

export default FPResetPasswordForm;
