import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';

import {
    modifyApplication,
    cancelApplication,
    getAllUserApplications,
    confirmApplication,
    getAllZipCodes,
    createApplication,
    declineApplication,
    acceptFundApplication,
    modifyAcceptFundingApplication,
} from '../services/application.services';

import {
    EditApplicationDataType,
    CancelApplicationDataType,
    DecineApplicationDataType,
    ConfirmApplicationDataType,
    CreateApplicationRequestBodyType,
    AcceptFundApplicationDataType,
} from '../types/editApplication.types';

export const useEditApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, EditApplicationDataType>(
        modifyApplication,
        {
            onSuccess: () => cache.refetchQueries('applications'),
        }
    );
};

export const useCancelApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, CancelApplicationDataType>(
        cancelApplication,
        {
            onSuccess: () => cache.refetchQueries('applications'),
        }
    );
};

export const useDeclineApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, DecineApplicationDataType>(
        declineApplication,
        {
            onSuccess: () => cache.refetchQueries('applications'),
        }
    );
};

export const useAcceptFundApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, AcceptFundApplicationDataType>(
        acceptFundApplication,
        {
            onSuccess: () => cache.refetchQueries('applications'),
        }
    );
};

export const useModifyAcceptFundingApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, EditApplicationDataType>(
        modifyAcceptFundingApplication,
        {
            onSuccess: () => cache.refetchQueries('applications'),
        }
    );
};

export const useGetAllZipCodes = () => {
    return useQuery<AxiosResponse, AxiosError>(
        ['getAllZipCodes'],
        getAllZipCodes,
        {
            retry: 1,
            refetchOnWindowFocus: false,
        }
    );
};

export const useCreateApplication = () => {
    const cache = useQueryClient();
    return useMutation<
        AxiosResponse,
        AxiosError,
        CreateApplicationRequestBodyType
    >(createApplication, {
        onSuccess: () => cache.invalidateQueries('applications'),
    });
};

export const useFetchAllUserApplications = (
    enabled: boolean
) => {
    return useQuery<AxiosResponse, AxiosError>({
        queryKey: ['applications'],
        queryFn: () => getAllUserApplications(),
        enabled: enabled,
        retry: 1,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data.data) {
                data.data = data.data.map((app) => {
                    if (
                        [
                            'Submitted',
                            'Full Funding Queue',
                            'Partial Funding Queue',
                            'Waiting List',
                        ].includes(app.applicationStatus)
                    ) {
                        app.applicationStatus = 'In Process';
                    }
                    return app;
                });
            }
        },
    });
};

export const useConfirmApplication = () => {
    const cache = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, ConfirmApplicationDataType>(
        confirmApplication,
        {
            onSuccess: () => cache.invalidateQueries('applications'),
        }
    );
};
