import React, { FC, forwardRef, useEffect } from 'react';
import { Card, Form, Grid } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';

import {
    ProgramFormStepFourType,
    ProgramStepFourFormDataType,
} from '../../../../../types/program.types';
import { programStepFourFormDataSchema } from '../../../../../schemas/program.schemas';
import useCustomResolver from '../../../../../hooks/useCustomResolver';
import Language from '../../../../../libraries/language';
import styles from '../../Private.module.scss';
import { validateAlphabets } from 'src/utils/common.utils';

const CustomerSignature: FC<ProgramFormStepFourType> = forwardRef(
    ({ formRef, onSubmitNextHandler, formValues }, _) => {
        const {
            handleSubmit,
            control,
            formState: { errors },
        } = useForm<ProgramStepFourFormDataType>({
            resolver: useCustomResolver(programStepFourFormDataSchema),
            defaultValues: formValues,
        });

        const onSubmitFormHandler = (data: ProgramStepFourFormDataType) => {
            onSubmitNextHandler(data);
        };

        useEffect(() => {
            formRef.current = handleSubmit(onSubmitFormHandler);
        }, [formRef]);

        return (
            <Card fluid className={styles.sectionCard}>
                <Card.Content className={styles.sectionFormContent}>
                    <h2>{Language.TTL_PF_CS}</h2>

                    <Form className={styles.privateProgramForm}>
                        <Grid>
                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.name && {
                                            error: errors.name,
                                        })}
                                    >
                                        <label
                                            htmlFor="name"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_NAME}
                                        </label>
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => {
                                                field.value = validateAlphabets(
                                                    field.value
                                                );
                                                return (
                                                    <Form.Input
                                                        {...field}
                                                        maxLength="100"
                                                        {...(errors.name && {
                                                            error: errors.name,
                                                        })}
                                                        type="text"
                                                        placeholder={
                                                            Language.LBL_PLC_ENTER
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={6} mobile={8}>
                                    <Form.Field
                                        required
                                        {...(errors.title && {
                                            error: errors.title,
                                        })}
                                    >
                                        <label
                                            htmlFor="title"
                                            className={styles.privateLabel}
                                        >
                                            {Language.LBL_TITLE}
                                        </label>
                                        <Controller
                                            name="title"
                                            control={control}
                                            render={({ field }) => {
                                                field.value = validateAlphabets(
                                                    field.value
                                                );
                                                return (
                                                    <Form.Input
                                                        {...field}
                                                        maxLength="100"
                                                        {...(errors.title && {
                                                            error: errors.title,
                                                        })}
                                                        type="text"
                                                        placeholder={
                                                            Language.LBL_PLC_ENTER
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Card.Content>
            </Card>
        );
    }
);

export default CustomerSignature;
