import React from 'react';
import { decrypt, encrypt } from 'src/utils/common.utils';

type State = {
    isAuthenticated: boolean;
    authenticatedUserEmail: string;
    userName: string;
};

type Action = {
    type: 'CHANGE_AUTHENTICATION_STATE';
    payload: {
        isAuthenticated: boolean;
        authenticatedUserEmail: string;
        userName: string;
    };
};

type Dispatch = (action: Action) => void;

const AuthenticationStateContext = React.createContext<State | undefined>(
    undefined
);
const AuthenticationDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const isUserAuthenticated = localStorage.getItem('isLCFSUserAuthenticated');
const authenticatedUserEmail = localStorage.getItem(
    'authenticatedLCFSUserEmail'
);
const encriptedUserName = localStorage.getItem('encriptedUserName');
const userName = encriptedUserName ? decrypt(encriptedUserName) : '';
const INITIAL_STATE = {
    isAuthenticated: isUserAuthenticated
        ? JSON.parse(isUserAuthenticated)
        : false,
    authenticatedUserEmail: authenticatedUserEmail
        ? authenticatedUserEmail
        : '',
    userName: userName ? userName : "",
};

function authenticationReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_AUTHENTICATION_STATE':
            localStorage.setItem(
                'isLCFSUserAuthenticated',
                action.payload.isAuthenticated
            );
            localStorage.setItem(
                'authenticatedLCFSUserEmail',
                action.payload.authenticatedUserEmail
            );
            const encriptedUserName = encrypt(action.payload.userName);
            localStorage.setItem('encriptedUserName', encriptedUserName);
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                authenticatedUserEmail: action.payload.authenticatedUserEmail,
                userName: action.payload.userName
            };
        default:
            return state;
    }
}

function AuthenticationProvider({ children }) {
    const [state, dispatch] = React.useReducer(
        authenticationReducer,
        INITIAL_STATE
    );

    return (
        <AuthenticationStateContext.Provider value={state}>
            <AuthenticationDispatchContext.Provider value={dispatch}>
                {children}
            </AuthenticationDispatchContext.Provider>
        </AuthenticationStateContext.Provider>
    );
}

function useAuthenticationState() {
    const context = React.useContext(AuthenticationStateContext);

    if (context === undefined) {
        throw new Error(
            'useAuthenticationState must be used within AuthenticationProvider'
        );
    }

    return context;
}

function useAuthenticationDispatch() {
    const context = React.useContext(AuthenticationDispatchContext);

    if (context === undefined) {
        throw new Error(
            'useAuthenticationDispatch must be used within AuthenticationProvider'
        );
    }

    return context;
}

export {
    AuthenticationProvider,
    useAuthenticationState,
    useAuthenticationDispatch,
};
