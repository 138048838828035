import React, { FC } from 'react';
import { Segment, Icon, List } from 'semantic-ui-react';
import { ATMGrid } from 'src/components/common/CommonUI/atoms/ATMGrid/ATMGrid.component';
import { ATMModal } from 'src/components/common/CommonUI/atoms/ATMModal/ATMModal.component';
import { ViewOrEditApplicationType } from '../../../../../src/types/ViewOrEdit.types';
import { ATMButton } from 'src/components/common/CommonUI/atoms/ATMButton/ATMButton.component';
import { formatPhoneNumber } from 'src/components/common/CommonUI/atoms/ATMInput/ATMInput.component';
import { actionsPermissions } from '../../../../constants/landing.constants';
import Lang from 'src/libraries/language';
import styles from './Application.module.scss';
import { USDollar } from 'src/utils/common.utils';


const ViewOrEditApplication: FC<ViewOrEditApplicationType> = ({ application,
    setViewOrEditApplicationPopup,
    mainHandler, activeProgram
}) => {

    const onEditApplicationHandler = () => {
        mainHandler()
    };

    return (

        <div>
            <ATMModal
                closeIcon
                onClose={() => setViewOrEditApplicationPopup(false)}
                open={true}
            >

                <ATMModal.Header>
                    {Lang.TTL_MODAL_VIEW_APP}:{' '}
                    <span className={styles['font-weight']}>{application.id}</span>
                </ATMModal.Header>
                <ATMModal.Content >

                    <Segment  >
                        <ATMGrid className={styles.modalbackground}>
                            <ATMGrid.Row  >
                                <ATMGrid.Column>
                                    <ATMModal.Description>
                                        <strong className={styles['font-size']}>{Lang.LBL_ADD_INFO}</strong>

                                    </ATMModal.Description>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className='sempraNoPaddingTop'>
                                <ATMGrid.Column computer={4}>
                                    <span>
                                        <ATMModal.Description className={styles['font-weight']}>
                                            {Lang.LBL_STATUS}
                                        </ATMModal.Description>
                                    </span>
                                    <span>
                                        {application.status}
                                    </span>
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        <span>
                                            {Lang.LBL_SUBMISSION_DATE} <br />
                                        </span>
                                    </ATMModal.Description>
                                    <span>
                                        {application.submissionDate}
                                    </span>
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        <span>
                                            {Lang.LBL_VERIFICATION_DATE} <br />
                                        </span>
                                    </ATMModal.Description>
                                    <span> {application.verificationDate ? application.verificationDate : '-'} </span>
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_FUEL_CARD_REQUEST}
                                    </ATMModal.Description>
                                    <span>
                                        {USDollar.format(Number(application.estimatedFund))}
                                    </span>
                                </ATMGrid.Column>
                                </ATMGrid.Row>
                                
                                {(application.status == 'Funding Accepted' || application.status == 'Funding Approved') &&<ATMGrid.Row>
                                    <ATMGrid.Column computer={4}></ATMGrid.Column>
                                    <ATMGrid.Column computer={4}></ATMGrid.Column>
                                    <ATMGrid.Column computer={4}></ATMGrid.Column>
                                    <ATMGrid.Column  computer={4}>
                                        <ATMModal.Description className={styles['font-weight']}>
                                            {Lang.LBL_APPROVED_FUND}
                                        </ATMModal.Description>
                                        <span>
                                            {USDollar.format(Number(application.appovedFund))}
                                        </span>
                                    </ATMGrid.Column>    
                                    </ATMGrid.Row>}
                                   
                        
                        </ATMGrid>
                    </Segment>

                    <Segment>
                        <ATMGrid className={styles.modalbackground}>
                            <ATMGrid.Row >
                                <ATMGrid.Column>
                                    <ATMModal.Description >
                                        <strong className={styles['font-size']}>{Lang.LBL_COMPNAY_INFO}</strong>

                                    </ATMModal.Description>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className='sempraNoPaddingTop'>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>

                                        {Lang.LBL_BUSINESS_NAME}<br />

                                    </ATMModal.Description>
                                    <ATMModal.Description>
                                        {application.legalBusinessName}
                                    </ATMModal.Description>
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>

                                        {Lang.LBL_CONTACT_NAME}<br />
                                    </ATMModal.Description>
                                    <span>
                                        {application.contactName}
                                    </span>

                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_EMAIL}
                                    </ATMModal.Description>
                                    <span className={styles['email']}>
                                        {application.contactEmail}
                                    </span>

                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>

                                        {Lang.LBL_PHONE_NUMBER}

                                    </ATMModal.Description>
                                    <span>
                                        {formatPhoneNumber(application.phoneNumber as string, '', false)}
                                    </span>

                                </ATMGrid.Column>
                                <br />
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_ADDRESS}<br />
                                    </ATMModal.Description>
                                    {application.address}

                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_CITY}<br />
                                    </ATMModal.Description>
                                    {application.city}
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_STATE}
                                    </ATMModal.Description>
                                    {application.state}
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_ZIP_CODE}<br />
                                    </ATMModal.Description>
                                    {application.zipCode}
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                        </ATMGrid>
                    </Segment>

                    <Segment>
                        <ATMGrid className={styles.modalbackground}>
                            <ATMGrid.Row >
                                <ATMGrid.Column>
                                    <ATMModal.Description>
                                        <span> <strong className={styles['font-size']}>{Lang.LBL_TRUCK_INFO}</strong></span>

                                        {actionsPermissions.editAction.includes(application.applicationStatusId as string) && 
                                        activeProgram && activeProgram.id ===
                                        application.solicitationMasterId && (
                                            <ATMButton className={styles['span-right']}
                                                secondary
                                                onClick={onEditApplicationHandler}
                                                size='small'
                                                content={Lang.LBL_BTN_MODIFY2}
                                                icon='edit outline'
                                            />
                                        )}
                                        
                                    </ATMModal.Description>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className='sempraNoPaddingTop'>
                                <ATMGrid.Column computer={5}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_TRUCK_IN_FLEET}<br />
                                    </ATMModal.Description>
                                    {application.truckNumber}
                                </ATMGrid.Column>
                                {application.hydrogenTrucks > 0 ? <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_HYDROGEN_TRUCKS}<br />
                                    </ATMModal.Description>   {application.hydrogenTrucks}
                                </ATMGrid.Column> : null}
                                {application.rngTrucks > 0 ? <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_RNG_TRUCKS}<br />
                                    </ATMModal.Description>
                                    {application.rngTrucks}
                                </ATMGrid.Column> : null}
                                <br />
                            </ATMGrid.Row>
                            <ATMGrid.Row>
                                <ATMGrid.Column computer={5}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_ESTMD_PURCHASE_DATE}<br />
                                    </ATMModal.Description> {application.estPurchaseDate}
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_ESTMD_DEL_DATE}<br />
                                    </ATMModal.Description>
                                    {application.estDeliveryDate}
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    </Segment>

                    <Segment>
                        <ATMGrid className={styles.modalbackground}>
                            <ATMGrid.Row>
                                <ATMGrid.Column>
                                    <ATMModal.Description>
                                        <strong className={styles['font-size']}>{Lang.LBL_CUSTOMER_SIGNATURE}</strong>
                                    </ATMModal.Description>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row className='sempraNoPaddingTop'>
                                <ATMGrid.Column computer={5}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_NAME}<br />
                                    </ATMModal.Description>
                                    {application.contactName}
                                </ATMGrid.Column>
                                <ATMGrid.Column computer={4}>
                                    <ATMModal.Description className={styles['font-weight']}>
                                        {Lang.LBL_DESIGNATION}<br />
                                    </ATMModal.Description>
                                    {application.designation}
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                        </ATMGrid>
                    </Segment>
                </ATMModal.Content>
                <ATMModal.Actions>
                    <ATMGrid className={styles['default-margin']}>
                        <ATMGrid.Row>
                            <span>
                                <Icon className={styles['span-left']} name='info circle' />
                            </span>
                            <span className={styles['span-left-two']}>
                                <List bulleted className={styles['span-left-one']}>
                                    <List.Item className={styles['span-left']} >{Lang.LBL_INFO_MSG} </List.Item><br />
                                    <List.Item className={styles['span-left']} >{Lang.LBL_INFO_MSG_SECOND}</List.Item><br />
                                </List>

                            </span>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMModal.Actions>
            </ATMModal>
        </div>
    );

};

export default ViewOrEditApplication;
