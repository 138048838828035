import React, { FC } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';

import { ApproveModalType } from '../../../types/common.types';
import Language from '../../../libraries/language';
import styles from './ApproveModal.module.scss';

const ApproveModal: FC<ApproveModalType> = ({
    open,
    setOpen,
    onAccept,
    onDecline,
    onModify,
}) => {
    return (
        <Modal
            size="large"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>{Language.TTL_FUNDING_ACCEPTANCE}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p className={styles.modalDescription}>
                        {Language.NT_APPROVE_FUNDING}
                    </p>

                    <Grid className={styles.modalGrid}>
                        <Grid.Row>
                            <Grid.Column
                                width={6}
                                className={styles.modalOptions}
                            >
                                {Language.NT_AF_APPROVE}
                            </Grid.Column>

                            <Grid.Column width={10}>
                                <Button
                                    className="sempraOutlineButton"
                                    content={Language.LBL_BTN_ACCEPT}
                                    onClick={onAccept}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column
                                width={6}
                                className={styles.modalOptions}
                            >
                                {Language.NT_AF_DECLINE}
                            </Grid.Column>

                            <Grid.Column width={10}>
                                <Button
                                    className="sempraOutlineButton"
                                    content={Language.LBL_BTN_DECLINE}
                                    onClick={onDecline}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column
                                width={6}
                                className={styles.modalOptions}
                            >
                                {Language.NT_AF_MODIFY}
                            </Grid.Column>

                            <Grid.Column width={10}>
                                <Button
                                    className="sempraOutlineButton"
                                    content={Language.LBL_BTN_MODIFY_ACCEPT}
                                    onClick={onModify}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
};

export default ApproveModal;
