import * as yup from 'yup';

import Language from '../libraries/language';

export const signInFormDataSchema = yup.object().shape({
    email: yup
        .string()
        .email(Language.LBL_ERR_EMAIL_INVALID)
        .required(Language.LBL_ERR_EMAIL),
    password: yup.string().required(Language.LBL_ERR_PASSWORD),
});
