import React, { FC, useState, useRef, useEffect } from 'react';
import { Step, Icon, Grid, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import CompanyInformation from './ProgramForms/CompanyInformation.component';
import TruckInformation from './ProgramForms/TruckInformation.component';
import TermsOfService from './ProgramForms/TermsOfService.component';
import CustomerSignature from './ProgramForms/CustomerSignature.component';
import FinalSummary from './ProgramForms/FinalSummary.component';
import StepSummary from './StepsSummary.component';
import { useNotificationDispatch } from '../../../../contexts/notification.context';
import { stepItems as defaultStepItems } from '../../../../constants/program.constants';
import {
    ProgramZipCodeOptionsType,
    ProgramFormData,
} from '../../../../types/program.types';
import Language from '../../../../libraries/language';
import styles from '../Private.module.scss';
import {
    useCreateApplication,
    useGetAllZipCodes,
} from 'src/hooks/useApplicationHooks';
import Lang from '../../../../libraries/language';
import { CreateApplicationRequestBodyType } from 'src/types/editApplication.types';
import moment from 'moment';
import { useAuthenticationState } from 'src/contexts/authentication.context';
import { useFetchActiveProgramSummary } from 'src/hooks/useProgramHooks';
import { getAPIError, getHeaderBase64Image, getSoCalGasBase64Logo, validateAlphabets } from 'src/utils/common.utils';
import { jsPDF } from "jspdf";
import ProgramPDF from './ProgramPDF.component';

const Program: FC = () => {
    const [step, setStep] = useState<number>(1);
    const [formData, setFormData] = useState<ProgramFormData>({
        step1: {},
        step2: {naturalGas: 0, hydrogen: 0},
        step3: {},
        step4: {},
    });
    const [stepItems, setStepItems] = useState(_.cloneDeep(defaultStepItems));
    const [renderPDFComponent, setRenderPDFComponent] = useState(false);
    const [zipCodeOptions, setZipCodeOptions] =
        useState<ProgramZipCodeOptionsType>([]);
    const notificationDispatch = useNotificationDispatch();
    const { authenticatedUserEmail } = useAuthenticationState();
    const navigate = useNavigate();
    const nextBtn = useRef() as any;
    const [loading, setLoading] = useState(false);
    const applicationData = useRef({} as CreateApplicationRequestBodyType);

    const {
      data: activeProgram,
      error: activeProgramSummaryError,
      isFetching: activeProgramSummaryLoading,
      isSuccess: isSuccessActiveProgram
  } = useFetchActiveProgramSummary(true);

    useEffect(() => {
        if (!activeProgram && isSuccessActiveProgram) navigate('/');
       
        if(activeProgram){
          formData.step2.fuelType = activeProgram.data.fuelType;
          setFormData({...formData});
        }
    }, [isSuccessActiveProgram, activeProgram]);

    const { isLoading, error: getZipError, data } = useGetAllZipCodes();

    const {
        mutate: createApplicationService,
        isSuccess,
        error: createAppError,
    } = useCreateApplication();

    useEffect(() => {
        if (activeProgramSummaryError) {
            notificationDispatch({
                type: 'ERROR',
                payload: activeProgramSummaryError.message,
            });
        }
    }, [activeProgramSummaryError]);

    useEffect(() => {
        if (isSuccess) {
            setRenderPDFComponent(false);
            setLoading(false);
            notificationDispatch({
                type: 'SUCCESS',
                payload: Language.MSG_APPLICATION_SUBMISSION,
            });
            navigate('/');
        }
        if (createAppError) {
            setRenderPDFComponent(false);
            setLoading(false);
            notificationDispatch({
                type: 'ERROR',
                payload: getAPIError(createAppError),
            });
        }
    }, [isSuccess, createAppError]);

    useEffect(() => {
        if (getZipError) {
            notificationDispatch({
                type: 'ERROR',
                payload: getZipError.message,
            });
        }
    }, [getZipError]);

    useEffect(() => {
        if (data) {
            const zipCodeCityStateOptions = data?.data.map((item) => ({
                key: item.zip_code,
                text: `${item.zip_code} - ${item.po_name} - ${item.state}`,
                value: item.zip_code,
                correspondingValues: {
                    city: item.po_name,
                    state: item.state,
                },
            }));
            setZipCodeOptions(zipCodeCityStateOptions);
        }
    }, [data]);

    const onClickNavigateStepHandler = (direction: 'next' | 'previous') => {
        setStep((prevStep) =>
            direction === 'next' ? prevStep + 1 : prevStep - 1
        );
    };

    const onClickModifyStepHandler = (stepNumber: number) => {
        setStep(stepNumber);
    };

    // validateAlphabetsWithSomeSpecialChars like ._-
    const validateAlphabetsWithSomeSpecialChars = (val) => {
        if (!val) return val;
        const pattern = /^[a-zA-Z\s._-]+$/;
        return pattern.test(val)
            ? val
            : validateAlphabetsWithSomeSpecialChars(
                  val.substring(0, val.length - 1)
              );
    };

    const onClickNextHandler = (data) => {
        if (
            step == 2 &&
            activeProgram?.data.fuelType === 'both' &&
            data.hydrogen == 0 &&
            data.naturalGas == 0
        ) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_RNG_AND_HYDROGEN_CANNOT_ZERO,
            });
            return;
        }
        const updatedStepItems: {
            id: number;
            stepTitle: string;
            stepDescription: string;
            isCompleted: boolean;
        }[] = [];
        stepItems.forEach((item, idx) => {
            if (step === idx + 1) item.isCompleted = true;
            updatedStepItems.push(item);
        });
        setStepItems(updatedStepItems);
        data.legalBusinessName = validateAlphabetsWithSomeSpecialChars(
            data.legalBusinessName
        );
        data.contactName = validateAlphabets(data.contactName);
        setFormData((prevData) => ({
            ...prevData,
            [`step${step}`]: data,
        }));

        onClickNavigateStepHandler('next');
    };

    const createRNGAppPDF = async (requestBody) => {
        const hydrogenTrucks = document.querySelector('#hydrogenTrucks')as any;
        hydrogenTrucks.style.display = 'none';
        
        const doc = new jsPDF('p', 'pt', 'a3') as any;
        doc.addImage(getSoCalGasBase64Logo(), 'jpg', 50, 20, 120, 70);
        doc.addImage(getHeaderBase64Image(), 'jpg', 470, 10, 340, 75);
        const html = document.querySelector('#content-id');
        await doc.html(html, {
            callback: function (doc) {
                //Do nothing
            },
            x: 0, // x position on the PDF document in jsPDF units.
            y: 80,
        });
        let str = doc.output('datauri');
        str = str.replace(
            'data:application/pdf;filename=generated.pdf;base64,',
            ''
        );
        requestBody.rngAppPDF = str;
    };

    const createHydrogenAppPDF = async (requestBody) => {
        const hydrogenTrucks = document.querySelector('#hydrogenTrucks')as any;
        hydrogenTrucks.style.display = 'grid';
        const rngTrucks = document.querySelector('#rngTrucks')as any;
        rngTrucks.style.display = 'none';
        
        const doc = new jsPDF('p', 'pt', 'a3') as any;
        doc.addImage(getSoCalGasBase64Logo(), 'jpg', 50, 20, 120, 70);
        doc.addImage(getHeaderBase64Image(), 'jpg', 470, 10, 340, 75);
        const html = document.querySelector('#content-id');
        await doc.html(html, {
            callback: function (doc) {
                //Do nothing
            },
            x: 0, // x position on the PDF document in jsPDF units.
            y: 80,
        });

        let str = doc.output('datauri');
        str = str.replace(
            'data:application/pdf;filename=generated.pdf;base64,',
            ''
        );
        requestBody.hydrogenAppPDF = str;
    };

    const onClickSubmitHandler = () => {
        setLoading(true);
        setRenderPDFComponent(true);
        const requestBody = {
            solicitationMasterId: activeProgram?.data.id,
            createdBy: authenticatedUserEmail,
            //step1
            businessName: formData.step1.legalBusinessName as string,
            contactPersonName: formData.step1.contactName as string,
            contactPersonEmail: formData.step1.email as string,
            phoneNumber: formData.step1.phoneNumber as string,
            address: formData.step1.address as string,
            zip: formData.step1.zipCode as string,
            city: formData.step1.city as string,
            state: formData.step1.state as string,
            //step2
            currentNumberOfTrucksInFleet:
                formData.step2.numberOfTrucksCurrentlyInFleet === 'nineOrLess'
                    ? 9
                    : 10,
            numberOfRngTrucks: Number(formData.step2.naturalGas),
            numberOfHydrogenTrucks: Number(formData.step2.hydrogen),
            estimatedDateOfPurchase: moment(
                formData.step2.estimatedDateOfPurchase
            ).format('YYYY-MM-DD'),
            estimatedDateOfDelivery: moment(
                formData.step2.estimatedDateOfTruckDelivery
            ).format('YYYY-MM-DD'),
            //step4
            signatoryName: formData.step4.name,
            title: formData.step4.title as string,
        } as CreateApplicationRequestBodyType;
        applicationData.current = requestBody;

        //2 seconds delay to render pdf Component (ProgramPDF.Component)
        setTimeout(async () => {
            if (applicationData.current.numberOfRngTrucks) {
                await createRNGAppPDF(requestBody);
            }
            if (applicationData.current.numberOfHydrogenTrucks) {
                await createHydrogenAppPDF(requestBody);
            }
            createApplicationService(requestBody);
        }, 2000);
    };

    let currentFormContent: JSX.Element;
    switch (step) {
        case 1:
            currentFormContent = (
                <CompanyInformation
                    formRef={nextBtn}
                    onSubmitNextHandler={onClickNextHandler}
                    formValues={formData.step1}
                    zipCodeOptions={zipCodeOptions}
                    loadingZipCodes={isLoading}
                />
            );
            break;
        case 2:
            currentFormContent = (
                <TruckInformation
                    formRef={nextBtn}
                    onSubmitNextHandler={onClickNextHandler}
                    formValues={formData.step2}
                />
            );
            break;
        case 3:
            currentFormContent = (
                <TermsOfService
                    formRef={nextBtn}
                    onSubmitNextHandler={onClickNextHandler}
                    formValues={formData.step3}
                />
            );
            break;
        case 4:
            currentFormContent = (
                <CustomerSignature
                    formRef={nextBtn}
                    onSubmitNextHandler={onClickNextHandler}
                    formValues={formData.step4}
                />
            );
            break;
        case 5:
            currentFormContent = <FinalSummary formData={formData} />;
            break;
        default:
            currentFormContent = (
                <CompanyInformation
                    formRef={nextBtn}
                    onSubmitNextHandler={onClickNextHandler}
                    formValues={formData.step1}
                    zipCodeOptions={zipCodeOptions}
                    loadingZipCodes={isLoading}
                />
            );
            break;
    }

    return (
        <div className={styles.section}>
            <Dimmer active={activeProgramSummaryLoading || loading} inverted>
                <Loader size="large" className={styles['positionFixed']}>{Lang.MSG_LOADING}</Loader>
            </Dimmer>
            <Step.Group
                stackable="tablet"
                size="small"
                className={styles.sectionStepper}
            >
                {stepItems.map((item) => (
                    <Step key={item.id} active={item.id === step}>
                        <Icon
                            name={
                                item.id === step
                                    ? 'dot circle outline'
                                    : item.isCompleted
                                    ? 'check circle'
                                    : 'circle outline'
                            }
                        />
                        <Step.Content>
                            <Step.Title>{item.stepTitle}</Step.Title>
                            <Step.Description>
                                {item.stepDescription}
                            </Step.Description>
                        </Step.Content>
                    </Step>
                ))}
            </Step.Group>

            <Grid className={styles.sectionForm}>
                <Grid.Row>
                    <Grid.Column computer={10} mobile={16}>{currentFormContent}</Grid.Column>

                    <Grid.Column computer={6} mobile={16}> 
                        <StepSummary
                            formData={formData}
                            onClickModifyStepHandler={onClickModifyStepHandler}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="programFormButtonGroup">
                <Button
                    className={styles.sempraNoBorderBtn}
                    onClick={() => navigate('/')}
                >
                    {Language.LBL_BTN_CANCEL}
                </Button>
                <Button
                    className={`${styles.sempraOutlineButton} ${styles.buttonSpace}`}
                    onClick={() => onClickNavigateStepHandler('previous')}
                    disabled={step === 1}
                >
                    {Language.LBL_BTN_PREVIOUS}
                </Button>
                {step === 5 ? (
                    <Button
                        className={styles.sempraButton}
                        onClick={onClickSubmitHandler}
                    >
                        {Language.LBL_BTN_SUBMIT}
                    </Button>
                ) : (
                    <Button
                        className={styles.sempraButton}
                        onClick={() => nextBtn.current()}
                    >
                        {Language.LBL_BTN_NEXT}
                    </Button>
                )}
            </div>
            {renderPDFComponent ? (
                <div id="content-id" className="content-id">
                    <div
                        style={{
                            width: '850px',
                            padding: '20px 40px 40px',
                        }}
                    >
                        <ProgramPDF
                            application={applicationData.current}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Program;
