// eslint-disable-next-line consistent-return
export const restrictAngularBracketSymbols = (value) => {
    const newValue = value?.toString().replace(/[<>]/g, '');
    return newValue;
};

export const restrictDecimalSymbol = (value) => {
    const newValue = value?.toString().replace(/[.]/g, '');
    return newValue;
};

export const restrictNegativeSignSymbol = (value) => {
    const newValue = value?.toString().replace(/[-]/g, '');
    return newValue;
};

export const restrictAlphabetsAndSpclChar = (value) => {
    const newValue = value
        ?.toString()
        .replace(/[a-zA-Z&,!@#$%^&*()_+=:;""?/{}|]/g, '');
    return newValue;
};
