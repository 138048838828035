export const stepItems: {
    id: number;
    stepTitle: string;
    stepDescription: string;
    isCompleted: boolean;
}[] = [
    {
        id: 1,
        stepTitle: 'Step 1',
        stepDescription: 'Company Information',
        isCompleted: false,
    },
    {
        id: 2,
        stepTitle: 'Step 2',
        stepDescription: 'Truck Information',
        isCompleted: false,
    },
    {
        id: 3,
        stepTitle: 'Step 3',
        stepDescription: 'Terms of Service',
        isCompleted: false,
    },
    {
        id: 4,
        stepTitle: 'Step 4',
        stepDescription: 'Customer Signature',
        isCompleted: false,
    },
    {
        id: 5,
        stepTitle: 'Step 5',
        stepDescription: 'Final Summary',
        isCompleted: false,
    },
];
