import React, { FC, useState } from 'react';
import {
    Grid,
    Header,
    Form,
    Message,
    Input,
    Button,
    Icon,
} from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { SignInFormDataType } from '../../../../types/signIn.types';
import { signInFormDataSchema } from '../../../../schemas/signIn.schemas';
import useCustomResolver from '../../../../hooks/useCustomResolver';
import { useAuthenticationDispatch } from '../../../../contexts/authentication.context';
import Language from '../../../../libraries/language';
import styles from '../Public.module.scss';
import { signIn } from 'src/services/authentication.services';
import Lang from '../../../../libraries/language';

let timer;

const SignIn: FC = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<SignInFormDataType>({
        resolver: useCustomResolver(signInFormDataSchema),
    });
    const authDispatch = useAuthenticationDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<string>('');

    const onSignInSuccess = (session) => {
        if (session) {
            const token = {
                payLoad: session.getIdToken().payload,
                accessToken: session.getAccessToken().getJwtToken(),
                idToken: session.getIdToken().getJwtToken(),
                refreshToken: session.getRefreshToken().getToken(),
            };
            setIsLoading(false);
            authDispatch({
                type: 'CHANGE_AUTHENTICATION_STATE',
                payload: {
                    isAuthenticated: true,
                    authenticatedUserEmail: token.payLoad.email,
                    userName: token.payLoad.given_name,
                },
            });
        }
    };

    const onSignInError = (err) => {
        console.log(err);
        setIsLoading(false);
        let mess = err.message;
        if (err.message === 'Unable to login because of security reasons.')
            mess = Lang.MSG_FOR_SECURITY_REASONS_YOUR_ACCOUNT_IS_LOCKED;
        setDisplayError(mess);

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            setDisplayError('');
        }, 5000);
    };

    const onSubmitFormHandler = async (data: SignInFormDataType) => {
        setIsLoading(true);
        signIn(data, onSignInSuccess, onSignInError);
    };

    return (
        <Grid>
            <Grid.Column width={12}>
                <Header as="h1" className={styles.publicPageTitle}>
                    {Language.TTL_WELCOME}
                </Header>
                <Header as="h5" className={styles.publicSubTitle}>
                    {Language.TTL_APPLICATION}
                </Header>
                <Form onSubmit={handleSubmit(onSubmitFormHandler)}>
                    <Grid>
                        {displayError && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Message negative>
                                        <Message.Content>
                                            {displayError}
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {!_.isEmpty(errors) && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Message negative>
                                        <Message.Header>
                                            {Language.TTL_FORM_ERROR}
                                        </Message.Header>
                                        <Message.List>
                                            {Object.values(errors).map(
                                                (err, idx) => (
                                                    <Message.Item key={idx}>
                                                        {err as any}
                                                    </Message.Item>
                                                )
                                            )}
                                        </Message.List>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        )}

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.email && { error: true })}
                                >
                                    <label
                                        htmlFor="email"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_EMAIL_ADDRESS}
                                    </label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="email"
                                                placeholder={
                                                    Language.LBL_PLC_EMAIL_ADDRESS
                                                }
                                            />
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Form.Field
                                    required
                                    {...(errors.password && { error: true })}
                                >
                                    <label
                                        htmlFor="password"
                                        className={styles.publicLabel}
                                    >
                                        {Language.LBL_PASSWORD}
                                    </label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Input
                                                    {...field}
                                                    type="password"
                                                    data-testid="password"
                                                />
                                                <small>
                                                    <Link to="/forgot-password">
                                                        <Icon name="unlock" />
                                                        {
                                                            Language.LBL_FORGOT_PASSWORD
                                                        }
                                                    </Link>
                                                </small>
                                            </>
                                        )}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <Button
                                    type="submit"
                                    className={styles.sempraButton}
                                    fluid
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    {Language.LBL_BTN_SIGNIN}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row className={styles.publicFormRow}>
                            <Grid.Column>
                                <p>
                                    {Language.LBL_SIGNUP_HERE[0]}
                                    <Link to="/sign-up">
                                        {Language.LBL_SIGNUP_HERE[1]}
                                    </Link>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Grid.Column>
        </Grid>
    );
};

export default SignIn;
