import { CreateApplicationRequestBodyType } from 'src/types/editApplication.types';
import { serviceInstance } from 'src/libraries/service.library';
const service = serviceInstance();

const BASE_URL = process.env.REACT_APP_EXTERNAL_BASE_URL;

export const modifyApplication = async (data) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?modifyApplication=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const cancelApplication = async (data) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?cancelApplication=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const declineApplication = async (data) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?cancelApplication=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const acceptFundApplication = async (data) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?fundingAccepted=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const modifyAcceptFundingApplication = async (data) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?modifyAcceptApplication=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllZipCodes = async () => {
    try {
        const response = await service.get(
            `${BASE_URL}/clientoperations?GetAllZipCodes=true`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getAllUserApplications = async () => {
    try {
        const response = await service.get(
            `${BASE_URL}/clientoperations/getAllUserApplicationDetails?fetchApplications`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const confirmApplication = async (data: {
    applicationNumber: string | number;
}) => {
    try {
        const response = await service.put(
            `${BASE_URL}/clientoperations?confirmApplication=true`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createApplication = async (
    data: CreateApplicationRequestBodyType
) => {
    try {
        const response = await service.post(`${BASE_URL}/clientoperations?createApplication=true`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
