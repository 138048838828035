import React, { FC, ReactNode } from 'react';
import { Menu, Image, Grid, Segment } from 'semantic-ui-react';

import {
    useAuthenticationState,
    useAuthenticationDispatch,
} from '../../../contexts/authentication.context';
import socalGasLogo from '../../../assets/images/socal_gas_logo.svg';
import lcfsProgramCover from '../../../assets/images/lcfs_program_cover.jpg';
import Language from '../../../libraries/language';
import styles from './Layout.module.scss';
import { cognitoUserPool } from 'src/utils/awsCognitoConfig';
import { getBase64UserGuidePDF } from 'src/utils/common.utils';

interface LayoutPropTypes {
    children: ReactNode;
}

const Layout: FC<LayoutPropTypes> = ({ children }) => {
    const { isAuthenticated, userName } = useAuthenticationState();
    const authDispatch = useAuthenticationDispatch();

    const onClickLogoutHandler = () => {
        const user = cognitoUserPool.getCurrentUser();
        user?.signOut();
        authDispatch({
            type: 'CHANGE_AUTHENTICATION_STATE',
            payload: {
                isAuthenticated: false,
                authenticatedUserEmail: '',
                userName: '',
            },
        });
    };

    const base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const openUserGuide = async () => {
        const base64Data = getBase64UserGuidePDF();
        const pdfBlob = new Blob([base64ToArrayBuffer(base64Data)], {
            type: 'application/pdf;charset=utf-8;',
        });
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(pdfBlob);
        // Create a link element
        window.open(blobUrl);
    };

    return (
        <>
            <div className={styles.navbarWrapper}>
                <Menu secondary className={styles.navbar}>
                    <div className={styles.navbarLogo}>
                        <Image src={socalGasLogo} />
                    </div>
                    {/* Private Content */}
                    {isAuthenticated && (
                        <div className={styles.menuItemsWrapper}>
                            <Menu.Item className={styles.menuItem}>
                                {`${Language.LBL_MENU_ITEM_ONE[0]} ${userName},`}
                                <span className={styles.menuItemSpanText}>
                                    &nbsp;{Language.LBL_MENU_ITEM_ONE[1]}
                                </span>
                            </Menu.Item>
                            <Menu.Item
                                className={styles.menuItem}
                                onClick={openUserGuide}
                            >
                                {Language.LBL_HELP}
                                
                            </Menu.Item>
                            <Menu.Item
                                className={styles.menuItem}
                                onClick={onClickLogoutHandler}
                            >
                                {Language.LBL_MENU_ITEM_THREE}
                            </Menu.Item>
                        </div>
                    )}
                </Menu>
            </div>
            {isAuthenticated ? (
                // Private Content
                <div
                    className={`${styles.layoutSpace} ${styles.layoutSpacePrivate}`}
                >
                    {children}
                </div>
            ) : (
                // Public Content
                <div
                    className={`${styles.layoutSpace} ${styles.layoutSpacePublic}`}
                >
                    <Grid>
                        <Grid.Column mobile={16} computer={7}>
                            {children}
                        </Grid.Column>
                        <Grid.Column width={9} only="computer">
                            <Segment className={styles.segmentContainer}>
                                <Image src={lcfsProgramCover} />
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default Layout;
