
/* istanbul ignore file */
import axios, { AxiosInstance, AxiosResponse, 
} from 'axios';
import { getSession, regenerateToken } from './auth.library';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export class BaseService {
    public readonly instance: AxiosInstance;

    public constructor(baseURL: string) {
        this.instance = axios.create({ baseURL });
        this.initRequestInterceptor();
        this.initResponseInterceptor();
    }

    private initRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            this.handleRequest
            // this.handleError
        );
    };

    private handleRequest = async (config) => {
        const session = (await getSession()) as CognitoUserSession;
        if (session) {
            //Check user refresh token is revoked or not
            regenerateToken(session, () => {
                location.reload();
            });
            if (config && config.headers) {
                config.headers.Authorization = session
                    .getAccessToken()
                    .getJwtToken();
            }
        } else {
            location.reload();
        }
        return config;
    };

    private initResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this.handleResponse
            // this.handleError
        );
    };

    private handleResponse = (data: AxiosResponse) => {
        return data;
    };

    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    protected handleError = async (error) => {
        const { status, data, config } = error.response;

        if (status === 401) {
            console.log('The incoming token has expired');
        }
        console.log(
            `Error: status: ${status}, data: ${data}, config:${config}`
        );
        return Promise.reject(error);
    };
}



export const serviceInstance  = () => { // by default retry is set to 0
    return new BaseService('').instance;
};
