import React, { FC } from 'react';
import { Card, Grid, Container, Button } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

import { StepsSummaryType } from '../../../../types/program.types';
import { formatPhoneNumber } from '../../../common/CommonUI/atoms/ATMInput/ATMInput.component';
import Language from '../../../../libraries/language';
import styles from '../Private.module.scss';
import { useQueryClient } from 'react-query';

const StepsSummary: FC<StepsSummaryType> = ({
    formData,
    onClickModifyStepHandler,
}) => {
    const queryClient = useQueryClient();
    let activeProgram = queryClient.getQueryData('activeProgram') as Record<
        string,
        unknown
    >;
    activeProgram = activeProgram
        ? (activeProgram.data as Record<string, string | number>)
        : {};

    return (
        <Card fluid>
            <Card.Content className={styles.sectionFormContent}>
                <h2>{Language.TTL_SS}</h2>

                {!_.isEmpty(formData.step1) && (
                    <Container className={styles.privateInfoContainer}>
                        <Grid>
                            <Grid.Row
                                equal
                                className={styles.privateRemainingRowPadding}
                            >
                                <Grid columns={3}>
                                    <Grid.Column
                                        width={3}
                                        className={
                                            styles.privateFirstColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_STEP_ONE}:
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column
                                        width={10}
                                        className={
                                            styles.privateRemainingColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.TTL_PF_CI}
                                        </div>
                                        <div
                                            className={styles.privateInfoValue}
                                        >
                                            {Object.values(formData.step1).map(
                                                (item, idx, items) => {
                                                    return `${
                                                        idx === 2
                                                            ? formatPhoneNumber(
                                                                  item as string,
                                                                  '',
                                                                  false
                                                              )
                                                            : item
                                                    }${
                                                        items.length - 1 !== idx
                                                            ? ','
                                                            : ''
                                                    } `;
                                                }
                                            )}
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Button
                                            className={
                                                styles.privateNoBorderBtn
                                            }
                                            onClick={() =>
                                                onClickModifyStepHandler(1)
                                            }
                                        >
                                            {Language.LBL_BTN_MODIFY}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )}

                {formData.step2.numberOfTrucksCurrentlyInFleet && (
                    <Container className={styles.privateInfoContainer}>
                        <Grid>
                            <Grid.Row
                                equal
                                className={styles.privateRemainingRowPadding}
                            >
                                <Grid columns={3}>
                                    <Grid.Column
                                        width={3}
                                        className={
                                            styles.privateFirstColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_STEP_TWO}:
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column
                                        width={10}
                                        className={
                                            styles.privateRemainingColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.TTL_PF_TI}
                                        </div>
                                        <div
                                            className={styles.privateInfoValue}
                                        >
                                            <p
                                                className={
                                                    styles.privateRemoveParagraphMargin
                                                }
                                            >
                                                {`${Language.LBL_SS_TRUCKS}: 
                                                ${
                                                    formData.step2
                                                        .numberOfTrucksCurrentlyInFleet ===
                                                    'nineOrLess'
                                                        ? Language.LBL_ERR_NOTCINF_ONE
                                                        : Language.LBL_ERR_NOTCINF_TWO
                                                }`}
                                            </p>
                                            {['rng', 'both'].includes(
                                                activeProgram?.fuelType as string
                                            ) ? (
                                                <p
                                                    className={
                                                        styles.privateRemoveParagraphMargin
                                                    }
                                                >{`${Language.LBL_SS_NGTRUCKS}: ${formData.step2.naturalGas}`}</p>
                                            ) : null}
                                            {['hydrogen', 'both'].includes(
                                                activeProgram?.fuelType as string
                                            ) ? (
                                                <p
                                                    className={
                                                        styles.privateRemoveParagraphMargin
                                                    }
                                                >{`${Language.LBL_SS_HTRUCKS}: ${formData.step2.hydrogen}`}</p>
                                            ) : null}
                                            <p
                                                className={
                                                    styles.privateRemoveParagraphMargin
                                                }
                                            >{`${Language.LBL_SS_DOP}: ${moment(
                                                formData.step2
                                                    .estimatedDateOfPurchase
                                            )
                                                .format('MMMM D, YYYY')
                                                .toString()}`}</p>
                                            <p
                                                className={
                                                    styles.privateRemoveParagraphMargin
                                                }
                                            >{`${
                                                Language.LBL_SS_DOD
                                            }: ${moment(
                                                formData.step2
                                                    .estimatedDateOfTruckDelivery
                                            )
                                                .format('MMMM D, YYYY')
                                                .toString()}`}</p>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Button
                                            className={
                                                styles.privateNoBorderBtn
                                            }
                                            onClick={() =>
                                                onClickModifyStepHandler(2)
                                            }
                                        >
                                            {Language.LBL_BTN_MODIFY}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )}

                {!_.isEmpty(formData.step3) && (
                    <Container className={styles.privateInfoContainer}>
                        <Grid>
                            <Grid.Row
                                equal
                                className={styles.privateRemainingRowPadding}
                            >
                                <Grid columns={3}>
                                    <Grid.Column
                                        width={3}
                                        className={
                                            styles.privateFirstColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_STEP_THREE}:
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column
                                        width={10}
                                        className={
                                            styles.privateRemainingColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.TTL_PF_TOS_ONE}
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Button
                                            className={
                                                styles.privateNoBorderBtn
                                            }
                                            onClick={() =>
                                                onClickModifyStepHandler(3)
                                            }
                                        >
                                            {Language.LBL_BTN_MODIFY}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )}

                {!_.isEmpty(formData.step4) && (
                    <Container className={styles.privateInfoContainer}>
                        <Grid>
                            <Grid.Row
                                equal
                                className={styles.privateRemainingRowPadding}
                            >
                                <Grid columns={3}>
                                    <Grid.Column
                                        width={3}
                                        className={
                                            styles.privateFirstColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_STEP_FOUR}:
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column
                                        width={10}
                                        className={
                                            styles.privateRemainingColumnPadding
                                        }
                                    >
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.TTL_PF_CS}
                                        </div>

                                        <div
                                            className={styles.privateInfoValue}
                                        >
                                            <p
                                                className={
                                                    styles.privateRemoveParagraphMargin
                                                }
                                            >{`${Language.LBL_NAME}: ${formData.step4.name}`}</p>
                                            <p
                                                className={
                                                    styles.privateRemoveParagraphMargin
                                                }
                                            >{`${Language.LBL_TITLE}: ${formData.step4.title}`}</p>
                                        </div>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <Button
                                            className={
                                                styles.privateNoBorderBtn
                                            }
                                            onClick={() =>
                                                onClickModifyStepHandler(4)
                                            }
                                        >
                                            {Language.LBL_BTN_MODIFY}
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Row>
                        </Grid>
                    </Container>
                )}
            </Card.Content>
        </Card>
    );
};

export default StepsSummary;
