export default {
    MSG_ERROR_REQUIRED: 'This field is required',
    MSG_ERROR_500:
        'Our servers are currently busy. Please try again after sometime.',
    MSG_VERIFICATION_SUCCESS:
        'Your verification link has been sent successfully!',
    MSG_USER_ALREADY_EXISTS: 'User already exists, If you have not received the verification link, please resend Email.',
    MSG_APPLICATION_SUBMISSION:
        'Congratulations! Your application has been successfully submitted. To confirm, please review and e-sign your application details in the table below.',
    MSG_APPLICATION_CONFIRMATION: [
        'Application',
        'E-signature has been completed successfully!',
    ],
    MSG_ACCEPT_APPLICATION: ['Application', ' Fund has been accepted successfully!'],
    MSG_DECLINE_APPLICATION: ['Application', 'has been declined successfully!'],
    MSG_MODIFY_AND_ACCEPT_APPLICATION: [
        'Modified application',
        'Fund has been accepted successfully!',
    ],
    MSG_LOADING: 'Loading',
    MSG_APP_SAVE_SUCESS: 'Application: ${appId} Updated successfully!',

    MSG_CANCEL_APPLICATION_WARNING: "You won't be able to reverse this action once you've canceled this application.",
    MSG_CANCEL_APPLICATION_MESSAGE: "Are you sure you want to Cancel [Application: ${appId}] from this",
    MSG_TRUCKS_QUANTITY_ERROR: 'You can only reduce the number of trucks',

    MSG_CANCEL_APPLICATION_MESSAGE_TWO: "Are you sure you want to cancel Application (${appId}) from this Solicitation Program? If yes, please provide a reason for the cancellation below.",
    MSG_APP_Cancel_SUCESS: 'Application: ${appId} Cancelled successfully!',
    MSG_NO_RESULTS_FOUND: 'No results found.',
    MSG_LOGIN_AGAIN_ERROR: 'Something went wrong please Login again.',
    MSG_COMMON_ERROR: 'Something went wrong please try again',
    MSG_PASSWORD_UPDATED_SUCCESSFULLY: 'Password updated successfully',
    MSG_RNG_AND_HYDROGEN_CANNOT_EMPTY: 'RNG & Hydrogen both can not be empty.',
    MSG_RNG_AND_HYDROGEN_CANNOT_ZERO: 'RNG or Hydrogen trucks count should be greater than zero.',
    MSG_NO_FURTHER_APPLICATIONS_ARE_ACCEPTING: "No further applications are being accepted for the current program.",
    MSG_FOR_SECURITY_REASONS_YOUR_ACCOUNT_IS_LOCKED: "For security reasons, your account is locked. Please connect with a support team or reset your password to login again."
};
