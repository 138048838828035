import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Card, Form, Grid, Checkbox, Label } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';

import {
    ProgramFormStepThreeType,
    ProgramStepThreeFormDataType,
} from '../../../../../types/program.types';
import { programStepThreeFormDataSchema } from '../../../../../schemas/program.schemas';
import useCustomResolver from '../../../../../hooks/useCustomResolver';
import Language from '../../../../../libraries/language';
import styles from '../../Private.module.scss';
import Lang from '../../../../../libraries/language';

const TermsOfService: FC<ProgramFormStepThreeType> = forwardRef(
    ({ formRef, onSubmitNextHandler, formValues }, _) => {
        const [enableButton, setEnableButton] = useState(false);
        const {
            handleSubmit,
            control,
            formState: { errors },
        } = useForm<ProgramStepThreeFormDataType>({
            resolver: useCustomResolver(programStepThreeFormDataSchema),
            defaultValues: formValues,
        });

        const onSubmitFormHandler = (data: ProgramStepThreeFormDataType) => {
            onSubmitNextHandler(data);
        };

        useEffect(() => {
            formRef.current = handleSubmit(onSubmitFormHandler);
        }, [formRef]);

        const handleScroll = (e) => {
            const bottom =
                Math.abs(
                    e.target.scrollHeight -
                        e.target.clientHeight -
                        e.target.scrollTop
                ) < 5;
            if (!enableButton && bottom) {
                setEnableButton(bottom);
            }
        };

        return (
            <Card fluid className={styles.sectionCard}>
                <Card.Content className={styles.sectionFormContent}>
                    <h2>{Language.TTL_PF_TOS_ONE}</h2>

                    <Form className={styles.privateProgramForm}>
                        <Grid>
                            <h3 className={styles.privateFormSubTitle}>
                                {Language.TTL_PF_TOS_TWO}
                            </h3>
                            <Grid.Row className={styles.privateTermsFormRow}>
                                <Grid.Column width={16}>
                                    <div
                                        className={styles.privateTermsContainer + " termsScroll"}
                                        onScroll={handleScroll}
                                    >
                                        <div
                                            className={
                                                styles.privateTermsContent
                                            }
                                        >
                                            <p>
                                                {
                                                    Lang.NT_LANDING_PROV_POLICY_PART1.split(
                                                        '<sup>1</sup>'
                                                    )[0]
                                                }
                                                <sup>1 </sup>
                                                {
                                                    Lang.NT_LANDING_PROV_POLICY_PART2.split(
                                                        '<sup>2</sup>'
                                                    )[0]
                                                }
                                                <sup>2 </sup>
                                                {
                                                    Lang.NT_LANDING_PROV_POLICY_PART2.split(
                                                        '<sup>2</sup>'
                                                    )[1]
                                                }
                                            </p>

                                            <h5>{Language.TTL_PF_PR}</h5>
                                            <p
                                                className={
                                                    styles.privateProgramRules
                                                }
                                            >
                                                {Language[
                                                    'NT_PROGRAM_RULES'
                                                ].map((rule, index) => (
                                                    <div
                                                        key={index}
                                                        className={
                                                            styles.privateRuleItem
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.ruleNumber
                                                            }
                                                        >
                                                            {`${index + 1}.`}
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.ruleContent
                                                            }
                                                        >
                                                            {rule}
                                                        </div>
                                                    </div>
                                                ))}
                                            </p>

                                            <div
                                                className={
                                                    styles.privateThinLine
                                                }
                                            />

                                            <p>
                                                <sup
                                                    className={
                                                        styles.privateSuperScript
                                                    }
                                                >
                                                    1
                                                </sup>
                                                {Language.NT_PR_FOOTER[0]}
                                            </p>
                                            <p>
                                                <sup
                                                    className={
                                                        styles.privateSuperScript
                                                    }
                                                >
                                                    2
                                                </sup>
                                                {Language.NT_PR_FOOTER[1]}
                                                <a
                                                    href={Lang.NT_PR_FOOTER[2]}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {
                                                        Lang.LBL_MEDIUM_AND_HEAVY_DUTY_ZERO_EMISSION_VEHICLES
                                                    }
                                                </a>
                                            </p>

                                            <div
                                                className={
                                                    styles.privateThinLine
                                                }
                                            />

                                            <h5>{Language.TTL_PF_TAC}</h5>
                                            <p>{Language.NT_TAC}</p>
                                            <p
                                                className={
                                                    styles.privateProgramRules
                                                }
                                            >
                                                {Language['NT_TAC_POINTS'].map(
                                                    (rule, index) => (
                                                        <div
                                                            key={index}
                                                            className={`${styles.privateRuleItem}
                                                                ${styles.privateRuleTOCItem}`}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.ruleNumber
                                                                }
                                                            >
                                                                {`${
                                                                    index + 1
                                                                }.`}
                                                            </div>

                                                            <div
                                                                className={
                                                                    styles.ruleContent
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.ruleHeading
                                                                    }
                                                                >
                                                                    {rule[0]}
                                                                </span>
                                                                {Array.isArray(
                                                                    rule[1]
                                                                ) ? (
                                                                    <p
                                                                        className={
                                                                            styles.privateProgramRules
                                                                        }
                                                                    >
                                                                        {rule[1].map(
                                                                            (
                                                                                subRule
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        subRule[0]
                                                                                    }
                                                                                    className={
                                                                                        styles.privateRuleItem
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            styles.ruleNumber
                                                                                        }
                                                                                    >
                                                                                        {`${subRule[0]}.`}
                                                                                    </div>

                                                                                    <div
                                                                                        className={
                                                                                            styles.ruleContent
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            subRule[1]
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    <p>
                                                                        {
                                                                            rule[1]
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row className={styles.privateFormRow}>
                                <Grid.Column width={16}>
                                    <Form.Field
                                        required
                                        {...(errors.acceptTerms && {
                                            error: errors.acceptTerms,
                                        })}
                                    >
                                        <Controller
                                            name="acceptTerms"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Checkbox
                                                        checked={field.value}
                                                        onChange={(_, data) =>
                                                            field.onChange(
                                                                data.checked
                                                            )
                                                        }
                                                        disabled={!enableButton}
                                                        label={
                                                            <label
                                                                className={
                                                                    styles.privateCheckboxLabel
                                                                }
                                                            >
                                                                {
                                                                    Language.LBL_ACCEPT_TERMS
                                                                }
                                                            </label>
                                                        }
                                                        className="sempraCheckbox"
                                                    />
                                                </>
                                            )}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                {errors.acceptTerms && (
                                    <Label
                                        basic
                                        pointing
                                        className={`${styles.privateFormLabel} ${styles.privateFormLabelSpacing}`}
                                    >
                                        {errors.acceptTerms as any}
                                    </Label>
                                )}
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Card.Content>
            </Card>
        );
    }
);

export default TermsOfService;
