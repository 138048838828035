import * as yup from 'yup';

import { unwantedPasswordErrors } from '../constants/password.constants';
import Language from '../libraries/language';

export const forgotPasswordFormDataSchema = yup.object().shape({
    email: yup
        .string()
        .email(Language.LBL_ERR_EMAIL_INVALID)
        .required(Language.LBL_ERR_EMAIL),
});

export const FPResetPasswordFormDataSchema = yup.object().shape({
    password: yup
        .string()
        .required(Language.LBL_ERR_PASSWORD)
        .test(
            'atLeast14Characters',
            unwantedPasswordErrors[0],
            (value) => !!value && value.length >= 14
        )
        .test(
            'hasNumericalDigit',
            unwantedPasswordErrors[1],
            (value) => !!value && /(?=.*\d)/.test(value)
        )
        .test(
            'hasSpecialCharacter',
            unwantedPasswordErrors[2],
            (value) => !!value && /(?=.*[!@#$%^&*])/.test(value)
        )
        .test(
            'hasUpperAndLowerCase',
            unwantedPasswordErrors[3],
            (value) => !!value && /(?=.*[a-z])(?=.*[A-Z])/.test(value)
        ),
    confirmPassword: yup
        .string()
        .oneOf(
            [yup.ref('password'), null as any],
            Language.LBL_ERR_CONFIRM_PASSWORD_INVALID
        )
        .required(Language.LBL_ERR_CONFIRM_PASSWORD),
});
