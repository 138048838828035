import React, { FC } from 'react';
import { Card, Grid, Container } from 'semantic-ui-react';
import moment from 'moment';

import { ProgramFormStepFiveType } from '../../../../../types/program.types';
import { formatPhoneNumber } from '../../../../common/CommonUI/atoms/ATMInput/ATMInput.component';
import Language from '../../../../../libraries/language';
import styles from '../../Private.module.scss';
import { useQueryClient } from 'react-query';

const FinalSummary: FC<ProgramFormStepFiveType> = ({ formData }) => {
    const queryClient = useQueryClient();
    let activeProgram = queryClient.getQueryData('activeProgram') as Record<
        string,
        unknown
    >;
    activeProgram = activeProgram
        ? (activeProgram.data as Record<string, string | number>)
        : {};

    return (
        <Card fluid className={styles.sectionCard}>
            <Card.Content className={styles.sectionFormContent}>
                <h2>{Language.TTL_PF_FS}</h2>

                <Container className={styles.privateInfoContainer}>
                    <Grid>
                        <Grid.Row
                        // equal
                        // className={styles.privateFirstRowPadding}
                        >
                            <Grid.Column computer={16} mobile={16}>
                                <h3 className={styles.privateFormSubTitle}>
                                    {Language.TTL_PF_CI}
                                </h3>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row
                        // equal
                        // className={styles.privateFirstRowPadding}
                        >
                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_LBN}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.legalBusinessName}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_CONTACT_NAME}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.contactName}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_EMAIL_ADDRESS}:
                                </div>
                                <div
                                    className={
                                        styles.privateInfoValue +
                                        ' ' +
                                        styles.summaryEmail
                                    }
                                >
                                    {formData.step1.email}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_PHONE_NUMBER}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formatPhoneNumber(
                                        formData.step1.phoneNumber,
                                        ''
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row
                        // className={styles.privateRemainingRowPadding}
                        >
                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_ADDRESS}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.address}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_CITY}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.city}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_STATE}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.state}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={8}>
                                <div className={styles.privateInfoLabel}>
                                    {Language.LBL_ZIP_CODE}:
                                </div>
                                <div className={styles.privateInfoValue}>
                                    {formData.step1.zipCode}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>

                <Container className={styles.privateInfoContainer}>
                    <h3 className={styles.privateFormSubTitle}>
                        {Language.TTL_PF_TI}
                    </h3>

                    <Grid>
                        <Grid.Row
                            equal
                            className={styles.privateFirstRowPadding}
                        >
                            <Grid columns={4}>
                                <Grid.Column computer={6}>
                                    <div className={styles.privateInfoLabel}>
                                        {Language.TTL_PF_TI_SUB_ONE}:
                                    </div>
                                    <div className={styles.privateInfoValue}>
                                        {formData.step2
                                            .numberOfTrucksCurrentlyInFleet ===
                                        'nineOrLess'
                                            ? Language.LBL_ERR_NOTCINF_ONE
                                            : Language.LBL_ERR_NOTCINF_TWO}
                                    </div>
                                </Grid.Column>

                                {['rng', 'both'].includes(
                                    activeProgram?.fuelType as string
                                ) ? (
                                    <Grid.Column computer={6}>
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_NATURAL_GAS}:
                                        </div>
                                        <div
                                            className={styles.privateInfoValue}
                                        >
                                            {formData.step2.naturalGas}
                                        </div>
                                    </Grid.Column>
                                ) : null}

                                {['hydrogen', 'both'].includes(
                                    activeProgram?.fuelType as string
                                ) ? (
                                    <Grid.Column computer={4}>
                                        <div
                                            className={styles.privateInfoLabel}
                                        >
                                            {Language.LBL_HYDROGEN}:
                                        </div>
                                        <div
                                            className={styles.privateInfoValue}
                                        >
                                            {formData.step2.hydrogen}
                                        </div>
                                    </Grid.Column>
                                ) : null}
                            </Grid>
                        </Grid.Row>

                        <Grid.Row
                            equal
                            className={styles.privateRemainingRowPadding}
                        >
                            <Grid columns={2}>
                                <Grid.Column computer={6}>
                                    <div className={styles.privateInfoLabel}>
                                        {
                                            Language.LBL_ESTIMATED_DATE_OF_PURCHASE
                                        }
                                        :
                                    </div>
                                    <div className={styles.privateInfoValue}>
                                        {moment(
                                            formData.step2
                                                .estimatedDateOfPurchase
                                        )
                                            .format('MMMM D, YYYY')
                                            .toString()}
                                    </div>
                                </Grid.Column>

                                <Grid.Column computer={10}>
                                    <div className={styles.privateInfoLabel}>
                                        {
                                            Language.LBL_ESTIMATED_DATE_OF_TRUCK_DELIVERY
                                        }
                                        :
                                    </div>
                                    <div className={styles.privateInfoValue}>
                                        {moment(
                                            formData.step2
                                                .estimatedDateOfTruckDelivery
                                        )
                                            .format('MMMM D, YYYY')
                                            .toString()}
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                    </Grid>
                </Container>

                <Container className={styles.privateInfoContainer}>
                    <h3 className={styles.privateFormSubTitle}>
                        {Language.TTL_PF_CS}
                    </h3>

                    <Grid>
                        <Grid.Row
                            equal
                            className={styles.privateFirstRowPadding}
                        >
                            <Grid columns={2}>
                                <Grid.Column computer={6}>
                                    <div className={styles.privateInfoLabel}>
                                        {Language.LBL_NAME}:
                                    </div>
                                    <div className={styles.privateInfoValue}>
                                        {formData.step4.name}
                                    </div>
                                </Grid.Column>

                                <Grid.Column computer={6}>
                                    <div className={styles.privateInfoLabel}>
                                        {Language.LBL_TITLE}:
                                    </div>
                                    <div className={styles.privateInfoValue}>
                                        {formData.step4.title}
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Card.Content>
        </Card>
    );
};

export default FinalSummary;
