import { serviceInstance } from 'src/libraries/service.library';
const service = serviceInstance();

const BASE_URL = process.env.REACT_APP_EXTERNAL_BASE_URL;

export const getActiveProgramSummary = async () => {
    try {
        const response = await service.get(
            `${BASE_URL}/clientoperations/getActiveProgramSummary?getActiveProgramSummary`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
