import React, { useState, useEffect } from 'react';
import {
    Message,
    MessageProps,
    MessageContentProps,
    MessageHeaderProps,
    MessageItemProps,
    MessageListProps,
} from 'semantic-ui-react';
import styles from './ATMToastMessage.module.scss';

export type IATMToastMessageProps = MessageProps & {
    timeout?: number;
    onDismiss: () => void;
    position?: string;
};

interface IATMToastMessage extends React.FC<IATMToastMessageProps> {
    Content: typeof Message.Content;
    Header: typeof Message.Header;
    Item: typeof Message.Item;
    List: typeof Message.List;
}

const ATMToastMessage: IATMToastMessage = ({
    children,
    timeout = 4,
    position = 'right',
    ...props
}) => {
    const [visible, setVisible] = useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTimeout(() => {
            props.onDismiss();
            setVisible(false);
        }, timeout * 1000);
    }, []);

    return (
        <div>
            {visible && (
                <div
                    className={
                        position === 'right'
                            ? styles.positionRight
                            : styles.position
                    }
                >
                    <Message size="tiny" {...props}>
                        {children}
                    </Message>
                </div>
            )}
        </div>
    );
};

ATMToastMessage.Content = Message.Content;
ATMToastMessage.Header = Message.Header;
ATMToastMessage.Item = Message.Item;
ATMToastMessage.List = Message.List;

export type IATMToastMessageContentProps = MessageContentProps;
export type IATMToastMessageHeaderProps = MessageHeaderProps;
export type IATMToastMessageItemProps = MessageItemProps;
export type IATMToastMessageListProps = MessageListProps;

export { ATMToastMessage };
