import React from 'react';
import { Icon } from 'semantic-ui-react';

import { PasswordStrengthAnalyzerType } from '../../../types/common.types';
import Language from '../../../libraries/language';
import styles from './PasswordStrengthAnalyzer.module.scss';

const PasswordStrengthAnalyzer: React.FC<PasswordStrengthAnalyzerType> = ({
    password,
}) => {
    const isAtLeast14Characters = password && password.length >= 14;
    const hasNumericalDigit = password && /(?=.*\d)/.test(password);
    const hasSpecialCharacter = password && /(?=.*[!@#$%^&*])/.test(password);
    const hasUpperAndLowerCase =
        password && /(?=.*[a-z])(?=.*[A-Z])/.test(password);

    return (
        <div className={styles.passwordStrengthAnalyzer}>
            <p className={styles.psaTitle}>{Language.LBL_PSA_TITLE}</p>
            <ul>
                <li>
                    <Icon
                        name="check circle"
                        className={
                            isAtLeast14Characters
                                ? styles.iconEnabled
                                : styles.iconDisabled
                        }
                    />
                    {Language.LBL_PSA_RULE_ONE}
                </li>
                <li>
                    <Icon
                        name="check circle"
                        className={
                            hasNumericalDigit
                                ? styles.iconEnabled
                                : styles.iconDisabled
                        }
                    />
                    {Language.LBL_PSA_RULE_TWO}
                </li>
                <li>
                    <Icon
                        name="check circle"
                        className={
                            hasSpecialCharacter
                                ? styles.iconEnabled
                                : styles.iconDisabled
                        }
                    />
                    {Language.LBL_PSA_RULE_THREE}
                </li>
                <li>
                    <Icon
                        name="check circle"
                        className={
                            hasUpperAndLowerCase
                                ? styles.iconEnabled
                                : styles.iconDisabled
                        }
                    />
                    {Language.LBL_PSA_RULE_FOUR}
                </li>
            </ul>
        </div>
    );
};

export default PasswordStrengthAnalyzer;
